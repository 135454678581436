import { days, months } from '../constants/date';

const parseDateString = (dateString) => {
  if (!dateString) return '';
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth();
  const date = `${dateObject.getDate()}`.padStart(2, '0');
  const day = dateObject.getDay();
  return `${days[day]} ${date} ${months[month]}, ${year}`;
}

export default parseDateString;
