import LoginPage from '../pages/LoginForm/LoginPage';
import GenerateSMS from '../pages/GenerateSMSForm/GenerateSMS';
import ChangePassword from '../pages/ChangePassword/ChangePasswordForm';
import SendCode from '../pages/ForgotPassword/SendCodeForm';
import ResetPassword from '../pages/ForgotPassword/ForgotPasswordForm';
import HomePage from '../pages/HomePage/HomePage';
import InfoHub from '../pages/InfoHub/InfoHub';
import UserManagement from '../pages/UserManagement/UserManagement';
import InfoHubArticle from '../pages/InfoHub/InfoHubArticle';
import InfoHubNotices from '../pages/InfoHub/InfoHubNotices';
import InfoHubFAQs from '../pages/InfoHub/InfoHubFAQs';
import InfoHubTrainingMaterial from '../pages/InfoHub/InfoHubTrainingMaterial';
import InfoHubResources from '../pages/InfoHub/InfoHubResources';
import { getInfoHubTitle, getInfoHubHeader, getInfoHubRoleName, getInfoHubIcons } from '../utils/infoHubRoutesProperties';
import roleNames from './roleNames';
import SMSType from '../pages/SMSType/SMSType';
import CreateSMSType from '../pages/CreateSMSType/CreateSMSType';
import iconNames from './iconNames';
import InfoHubs from '../pages/Infohubs/Infohubs';
import EmailType from '../pages/EmailType/EmailType';
import CreateEmailType from '../pages/CreateEmailType/CreateEmailType';
import GenerateEmail from '../pages/GenerateEmailForm/GenerateEmailForm';
import NotesBuilder from '../pages/NotesBuilder/NotesBuilder';
import NotesTool from '../pages/NotesTool/notesTool';

const routes = [
  {
    path: '/',
    Component: LoginPage,
    isProtected: false,
    title: (params = null) => 'Login',
    header: (params = null) => null,
    iconName: () => '',
    role: false,
    roleName: (params = null) => '',
    showSidebar: false,
  },
  {
    path: '/change-password',
    Component: ChangePassword,
    isProtected: false,
    title: (params = null) => 'Change Temp Password',
    header: (params = null) => null,
    iconName: () => '',
    role: false,
    roleName: (params = null) => '',
    showSidebar: false,
  },
  {
    path: '/send-code',
    Component: SendCode,
    isProtected: false,
    title: (params = null) => 'Send Forgot Password Code',
    header: (params = null) => null,
    iconName: () => '',
    role: false,
    roleName: (params = null) => '',
    showSidebar: false,
  },
  {
    path: '/reset-password',
    Component: ResetPassword,
    isProtected: false,
    title: (params = null) => 'Reset Password',
    header: (params = null) => null,
    iconName: () => '',
    role: false,
    roleName: (params = null) => '',
    showSidebar: false,
  },
  {
    path: '/dashboard',
    Component: HomePage,
    isProtected: true,
    title: (params = null) => 'My Apps',
    header: (params = null) => 'My Apps',
    iconName: () => iconNames.Dashboard,
    role: false,
    roleName: (params = null) => '',
    showSidebar: false,
  },
  {
    path: '/sms-platform',
    Component: GenerateSMS,
    isProtected: true,
    title: (params = null) => 'Generate and Send SMS',
    header: (params = null) => 'SMS Platform',
    iconName: () => iconNames.SMS,
    role: true,
    roleName: (params = null) => roleNames.SMS,
    showSidebar: true,
  },
  {
    path: '/email-platform',
    Component: GenerateEmail,
    isProtected: true,
    title: (params = null) => 'Generate and Send Emails',
    header: (params = null) => 'Email Platform',
    iconName: () => iconNames.Email,
    role: true,
    roleName: (params = null) => roleNames.EMAIL,
    showSidebar: true,
  },
  {
    path: '/notes-tool',
    Component: NotesTool,
    isProtected: true,
    title: (params = null) => 'Clear Notes',
    header: (params = null) => 'Clear Notes',
    iconName: () => iconNames.Notes,
    role: true,
    roleName: (params = null) => roleNames.NOTES,
    showSidebar: true,
  },
  {
    path: '/user-management',
    Component: UserManagement,
    isProtected: true,
    title: (params = null) => 'User Management',
    header: (params = null) => 'User Management',
    iconName: () => iconNames.ManageAccount,
    role: true,
    roleName: (params = null) => roleNames.ADMIN,
    showSidebar: true,
  },
  {
    path: '/sms-type',
    Component: SMSType,
    isProtected: true,
    title: (params = null) => 'SMS Type',
    header: (params = null) => 'SMS Type',
    iconName: () => iconNames.SMSManagement,
    role: true,
    roleName: (params = null) => roleNames.ADMIN,
    showSidebar: true,
  },
  {
    path: '/sms-type/build',
    Component: CreateSMSType,
    isProtected: true,
    title: (params = null) => 'SMS Type Builder',
    header: (params = null) => 'SMS Type Builder',
    iconName: () => iconNames.SMSManagement,
    role: true,
    roleName: (params = null) => roleNames.ADMIN,
    showSidebar: true,
  },
  {
    path: '/email-type',
    Component: EmailType,
    isProtected: true,
    title: (params = null) => 'Email Type',
    header: (params = null) => 'Email Type',
    iconName: () => iconNames.SMSManagement,
    role: true,
    roleName: (params = null) => roleNames.ADMIN,
    showSidebar: true,
  },
  {
    path: '/email-type/build',
    Component: CreateEmailType,
    isProtected: true,
    title: (params = null) => 'Email Type Builder',
    header: (params = null) => 'Email Type Builder',
    iconName: () => iconNames.SMSManagement,
    role: true,
    roleName: (params = null) => roleNames.ADMIN,
    showSidebar: true,
  },
  {
    path: '/notes',
    Component: NotesBuilder,
    isProtected: true,
    title: (params = null) => 'Clear Notes Template Builder',
    header: (params = null) => 'Clear Notes Template Builder',
    iconName: () => iconNames.Notes,
    role: true,
    roleName: (params = null) => roleNames.ADMIN,
    showSidebar: true,
  },
  {
    path: '/infohub',
    Component: InfoHubs,
    isProtected: true,
    title: (params = null) => 'Info Hub Management',
    header: (params = null) => 'Info Hub Management',
    iconName: () => iconNames.TextBoxMultiple,
    role: true,
    roleName: (params = null) => roleNames.ADMIN,
    showSidebar: true,
  },
  {
    path: '/:infoHub',
    Component: InfoHub,
    isProtected: true,
    title: getInfoHubTitle,
    header: getInfoHubHeader,
    iconName: getInfoHubIcons,
    role: true,
    roleName: getInfoHubRoleName,
    showSidebar: true,
  },
  {
    path: '/:infoHub/notices',
    Component: InfoHubNotices,
    isProtected: true,
    title: (params) => `All Notices - ${getInfoHubTitle(params)}`,
    header: getInfoHubHeader,
    iconName: getInfoHubIcons,
    role: true,
    roleName: getInfoHubRoleName,
    showSidebar: true,
  },
  {
    path: '/:infoHub/faqs',
    Component: InfoHubFAQs,
    isProtected: true,
    title: (params) => `FAQs - ${getInfoHubTitle(params)}`,
    header: getInfoHubHeader,
    iconName: getInfoHubIcons,
    role: true,
    roleName: getInfoHubRoleName,
    showSidebar: true,
  },
  {
    path: '/:infoHub/training-material',
    Component: InfoHubTrainingMaterial,
    isProtected: true,
    title: (params) => `Training Material - ${getInfoHubTitle(params)}`,
    header: getInfoHubHeader,
    iconName: getInfoHubIcons,
    role: true,
    roleName: getInfoHubRoleName,
    showSidebar: true,
  },
  {
    path: '/:infoHub/resources',
    Component: InfoHubResources,
    isProtected: true,
    title: (params) => `Resources - ${getInfoHubTitle(params)}`,
    header: getInfoHubHeader,
    iconName: getInfoHubIcons,
    role: true,
    roleName: getInfoHubRoleName,
    showSidebar: true,
  },
  {
    path: '/:infoHub/:articleType/:articleId',
    Component: InfoHubArticle,
    isProtected: true,
    title: getInfoHubTitle,
    header: getInfoHubHeader,
    iconName: getInfoHubIcons,
    role: true,
    roleName: getInfoHubRoleName,
    showSidebar: true,
  },
]

export default routes;