import infoHubService from '../services/infoHubService';
import parseDateString from './parseDateString';

const parseInfoHubListtoArray = (list = '') => {
  if (!list) return [];

  const element = document.createElement('body');
  element.innerHTML = list;
  const data = [...element.querySelectorAll('li > a')].map(el => ({ title: el.innerText, link: el.href }));

  return data;
}

const sortInfoHubData = (a, b) => new Date(b.review_date) - new Date(a.review_date);

const parseInfoHubData = async (infoHub, infoHubData) => {
  const faqs = infoHubData.filter(el => el?.friendly_url.indexOf(`${infoHub}/faqs/`) === 0).sort(sortInfoHubData);
  const notices = infoHubData.filter(el => el?.friendly_url.indexOf(`${infoHub}/notices/`) === 0).sort(sortInfoHubData);
  const trainingMaterial = infoHubData.filter(el => el?.friendly_url.indexOf(`${infoHub}/training-material`) === 0);
  const resources = infoHubData.filter(el => el?.friendly_url.indexOf(`${infoHub}/resources`) === 0);
  const fetchedNotices = await Promise.all(notices.map(el => infoHubService.fetchContentById({ id: el.friendly_url })));
  const fetchedResources = await Promise.all(resources.map(el => infoHubService.fetchContentById({ id: el.friendly_url })));
  const fetchedTrainingMaterial = await Promise.all(trainingMaterial.map(el => infoHubService.fetchContentById({ id: el.friendly_url })));

  return {
    isLoading: false,
    isDataFetched: true,
    faqs: faqs.map(el => ({
      friendly_url: el.friendly_url,
      review_date: el.review_date,
      title: el.title_en,
      isLoading: true,
      body: ''
    })),
    notices,
    fetchedNotices: fetchedNotices.map((el, index) => ({
      body: el?.items[0]?.fields.body,
      title: el?.items[0]?.fields.title,
      review_date: parseDateString(notices[index].review_date)
    })),
    fetchedResources: fetchedResources.map(el => parseInfoHubListtoArray(el?.items[0].fields.body))[0],
    fetchedTrainingMaterial: fetchedTrainingMaterial.map(el => parseInfoHubListtoArray(el?.items[0].fields.body))[0],
    trainingMaterial,
    resources
  }
}

export default parseInfoHubData;
