import request from "../utils/request";

const notesTemplateEndPoint = `${process.env.REACT_APP_BASE_URL}/notes`;

export const fetchNotesTemplate = async () => {
  try {
    const response = await request({
      url: notesTemplateEndPoint,
      method: 'GET'
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message,
      data: response.data?.data
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}

export const updateNotesTemplate = async (payload) => {
  try {
    const response = await request({
      url: notesTemplateEndPoint,
      method: 'POST',
      data: payload
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}