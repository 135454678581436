import React, { useCallback, useState } from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import styling from '../SMSForm/styling';
import MessageTemplateItems from '../MessageTemplateItems/MessageTemplateItems';
import validateTypes from '../../utils/validateTypes';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import ExpandAllButton from '../SMSForm/ExpandAllButton';


const useStyles = makeStyles(theme => ({
  ...styling(theme),
  formContainer: {
    width: '100%',
    padding: '10px 20px',
  },
  deleteIcon: {
    color: theme.palette.error.main
  },
  deleteButton: {
    height: 30,
  },
  descInput: {
    flex: 1
  },
  nameInput: {
    marginRight: 20
  }
}));

function SMSTypeForm({ onSmsTypeChange, editSmsType, updateError, setTags }) {
  const [smsTypeNameError, updateSmsTypeNameError] = useState('');
  const [expandAll, setExpandAll] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const updateSmsTypes = useCallback(
    (types) => onSmsTypeChange?.(prev => ({ ...prev, types })),
    [onSmsTypeChange]
  );

  const updateValues = useCallback((value, field, index) => {
    const newValues = editSmsType?.types?.map((type, i) => index === i ? { ...type, [field]: value } : type)
    updateSmsTypes(newValues);
  }, [editSmsType?.types, updateSmsTypes]);

  const handleDelete = useCallback((index) => {
    const deletedType = editSmsType?.types?.find((_, i) => i === index);
    if (deletedType?.dependant?.length > 0) {
      return dispatch(openSnackbar({ message: 'Please delete all the dependencies first.', type: SNACKBAR_TYPES.ERROR }));
    }
    const updatedTypes = [...editSmsType?.types?.filter((_, i) => i !== index)]
      .map(type => deletedType?.parent === type.id ? { ...type, dependant: type?.dependant?.filter(item => item.child !== deletedType.id) } : type);
      updateSmsTypes(updatedTypes);
    setTags(updatedTypes);
    updateError(!validateTypes(updatedTypes));
  }, [dispatch, editSmsType?.types, setTags, updateError, updateSmsTypes]);

  const validateName = useCallback(() => {
    const isSmsTypeNameValid = !!editSmsType?.name;
    updateSmsTypeNameError(isSmsTypeNameValid ? '' : 'Type Name is required');
    if (updateError) {
      updateError(!isSmsTypeNameValid);
    }
    return isSmsTypeNameValid;
  }, [editSmsType?.name, updateError])

  const handleNameChange = ({ target: { value } }) => {
    onSmsTypeChange?.(type => ({ ...type, name: value }));
    if (value?.trim()?.length && smsTypeNameError !== '') {
      updateSmsTypeNameError('');
    }
  }

  const handleDescriptionChange = ({ target: { value } }) => {
    onSmsTypeChange?.(type => ({ ...type, description: value }));
  }

  const toggleExpandAll = () => setExpandAll(prev => !prev);

  return <Grid item container spacing={1} className={classes.formContainer}>
    <Grid item container>
      <TextField
        label="Name"
        variant="standard"
        size="small"
        value={editSmsType?.name || ''}
        onChange={handleNameChange}
        onBlur={validateName}
        error={!!smsTypeNameError}
        helperText={smsTypeNameError}
        className={classes.nameInput}
      />
      <TextField
        label="Description"
        variant="standard"
        size="small"
        fullWidth
        value={editSmsType?.description || ''}
        onChange={handleDescriptionChange}
        className={classes.descInput}
      />
    </Grid>
    {!!editSmsType?.types?.length && <Grid item container justifyContent="flex-end">
      <ExpandAllButton expandAll={expandAll} toggleExpandAll={toggleExpandAll} />
    </Grid>}
    <MessageTemplateItems
      updateTypes={updateSmsTypes}
      types={editSmsType?.types || []}
      updateValues={updateValues}
      handleDelete={handleDelete}
      updateError={updateError}
      setTags={setTags}
      expandAll={expandAll}
    />
  </Grid>
}

export default SMSTypeForm;