import React from 'react';
import { Breadcrumbs, Typography, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { styling } from './styling';
import SvgIcon from '../SvgIcons';
import { TYPE_ICON_MAPPER } from '../../constants/messageTypes';

const useStyles = makeStyles(theme => styling(theme));

function BreadCrumpsTitle({ types, type, parent, title }) {
  const classes = useStyles();
  const parentType = types.find(type => type.id === parent);
  return <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
    <Typography className={classes.heading} variant="subtitle1" color="textPrimary">
      {parentType?.reference} <SvgIcon iconName={TYPE_ICON_MAPPER[parentType?.type]} />
    </Typography>
    <Typography className={classes.heading} variant="subtitle1" color="textPrimary">
      {title}  <SvgIcon iconName={TYPE_ICON_MAPPER[type]} />
    </Typography>
  </Breadcrumbs>
}

export default BreadCrumpsTitle;
