export const clone = (object) => {
  if (!object) return object;
  try {
    if (window.structuredClone) {
      return window.structuredClone(object);
    } else {
      return JSON.parse(JSON.stringify(object));
    }
  } catch (error) {
    return { ...object };
  }
}