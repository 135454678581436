import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Button,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import SvgIcon from "../../components/SvgIcons";
import iconNames from '../../constants/iconNames';
import { useViewStyles } from "../../styles/view.styles";
import { getInfohubs, createInfohub, deleteInfohub, updateInfohub } from "../../store/infohubManagement";
import CreateInfohubDialog from './CreateInfohubDialog';
import UpdateInfohubDialog from './UpdateInfohubDialog';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import DialogBox from "../../components/ConfirmationDialogBox";

const useStyles = makeStyles(theme => ({
  card: {
    height: "100%",
    width: "100%",
  },
  headerAction: {
    "& > .MuiCardHeader-action": {
      margin: "0px",
      alignSelf: "center",
    },
  },
  tableHeight: {
    maxHeight: '100%'
  },
  cardContent: {
    padding: '0px',
    height: 'calc(100% - 65px)',
    '& .MuiGrid-container': {
      height: '100%'
    }
  },
  notFound: {
    color: theme.palette.textColor[600],
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
  editIcon: {
    color: theme.palette.warning.main,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  downloadReport: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainerWrapper:{
    width:'24px'
  }
}));

function Infohubs() {
  const dispatch = useDispatch();
  const infohubs = useSelector((state) => state.infohubsManagement.infohubs);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [updateItem, setUpdateItem] = useState({});
  const classes = useStyles();
  const viewStyles = useViewStyles();

  const handleRefresh = async () => {
    setIsLoading(true);
    await getInfohubs(dispatch);
    setIsLoading(false);
  }

  const handleCreateInfohub = async (data) => {
    const res = await createInfohub(data);
    dispatch(openSnackbar({ message: res.message, type: res.status ? SNACKBAR_TYPES.SUCCESS : SNACKBAR_TYPES.ERROR }));
    res.status && handleRefresh();
    return res.status;
  }

  const handleUpdateInfohub = async (data) => {
    const res = await updateInfohub(data);
    dispatch(openSnackbar({ message: res.message, type: res.status ? SNACKBAR_TYPES.SUCCESS : SNACKBAR_TYPES.ERROR }));
    res.status && handleRefresh();
    return res.status;
  }

  const handleDeleteInfohub = async (data) => {
    setIsLoading(true);
    setIsDeleteDialogOpen(false);
    const res = await deleteInfohub(deleteItem?.role);
    dispatch(openSnackbar({ message: res.message, type: res.status ? SNACKBAR_TYPES.SUCCESS : SNACKBAR_TYPES.ERROR }));
    handleRefresh();
    return res.status;
  }

  useEffect(() => {
    if (!infohubs.length) {
      (async () => {
        await getInfohubs(dispatch);
        setIsLoading(false);
      })()
    } else {
      setIsLoading(false);
    }
  }, [dispatch, infohubs?.length]);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={viewStyles.container}
    >
      <Card className={classes.card}>
        <CardHeader
          title="Info Hubs"
          disableTypography
          className={classes.headerAction}
          action={
            <div>
              <Tooltip title="Refresh">
                <span>
                  <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: 10 }}
                    disabled={isLoading}
                    onClick={handleRefresh}
                  >
                    <SvgIcon iconName={iconNames.Refresh} />
                  </IconButton>
                </span>
              </Tooltip>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setIsDialogOpen(true)}
              >
                Create Info Hub
              </Button>
            </div>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          {
            isLoading && <Grid container alignItems="center" justifyContent="center" className={classes.loading}>
              <CircularProgress size={48} color="primary" />
            </Grid>
          }
          {
            !isLoading && !infohubs.length && <Grid container alignItems="center" justifyContent="center" className={classes.loading}>
              <Typography variant="h5" className={classes.notFound}>
                No Info hubs Found
              </Typography>
            </Grid>
          }
          {
            !isLoading && !!infohubs.length && <Grid container>
              <TableContainer className={classes.tableHeight}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '5%' }}></TableCell>
                      <TableCell style={{ width: '30%' }}>Name</TableCell>
                      <TableCell style={{ width: '45%' }}>Description</TableCell>
                      <TableCell style={{ width: '20%' }}>Friendly URL</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {infohubs.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {
                            row?.icon
                              ? <img src={row.icon} draggable={false} alt="icon" className={classes.imageContainerWrapper} />
                              : <SvgIcon iconName={iconNames.Book} />
                          }
                        </TableCell>
                        <TableCell>{row?.name || ""}</TableCell>
                        <TableCell>{row?.description || ""}</TableCell>
                        <TableCell>{row?.link || ""}</TableCell>
                        <TableCell>
                          <Grid item className={classes.actionContainer}>
                            <IconButton onClick={() => {
                              setUpdateItem(row);
                              setIsUpdateDialogOpen(true);
                            }}>
                              <SvgIcon iconName={iconNames.Edit} className={classes.editIcon} />
                            </IconButton>
                            <IconButton onClick={() => {
                              setDeleteItem(row);
                              setIsDeleteDialogOpen(true);
                            }}>
                              <SvgIcon iconName={iconNames.DeleteForever} className={classes.deleteIcon} />
                            </IconButton>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          }
        </CardContent>
      </Card>
      <CreateInfohubDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        handleCreateInfohub={handleCreateInfohub}
      />
      <UpdateInfohubDialog
        open={isUpdateDialogOpen}
        handleClose={() => setIsUpdateDialogOpen(false)}
        handleUpdateInfohub={handleUpdateInfohub}
        infohub={updateItem}
      />
      <DialogBox
        open={isDeleteDialogOpen}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onDone={handleDeleteInfohub}
        title="Delete"
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Delete"
        message={`Are you sure you want to delete "${deleteItem?.name}"?`}
      />
    </Grid>
  );
}

export default Infohubs;
