import { SvgIcon } from '@material-ui/core';

const icons = (props) => ({
  default: <SvgIcon {...props}>
    <path id="Path_3157" fill="none" d="M0 0h24v24H0z" />
    <path id="Path_3158" d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8z" fill="currentColor" />
    <circle id="Ellipse_12" cx="1" cy="1" r="1" fill="currentColor" transform="translate(11 15)" />
    <path id="Path_3159" d="M11 7h2v7h-2z" fill="currentColor" />
  </SvgIcon>,
  Alphabets: <SvgIcon {...props}>
    <path fill="currentColor" d="M3 7A2 2 0 0 0 1 9V17H3V13H5V17H7V9A2 2 0 0 0 5 7H3M3 9H5V11H3M15 10.5V9A2 2 0 0 0 13 7H9V17H13A2 2 0 0 0 15 15V13.5A1.54 1.54 0 0 0 13.5 12A1.54 1.54 0 0 0 15 10.5M13 15H11V13H13V15M13 11H11V9H13M19 7A2 2 0 0 0 17 9V15A2 2 0 0 0 19 17H21A2 2 0 0 0 23 15V14H21V15H19V9H21V10H23V9A2 2 0 0 0 21 7Z" />
  </SvgIcon>,
  ArrowDown: <SvgIcon {...props}>
    <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4M7,10L12,15L17,10H7Z" />
  </SvgIcon>,
  ArrowRight: <SvgIcon {...props}>
    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
  </SvgIcon>,
  Assignment: <SvgIcon {...props}>
    <path fill="currentColor" d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H9.18C9.6,1.84 10.7,1 12,1C13.3,1 14.4,1.84 14.82,3H19M12,3A1,1 0 0,0 11,4A1,1 0 0,0 12,5A1,1 0 0,0 13,4A1,1 0 0,0 12,3M7,7V5H5V19H19V5H17V7H7M12,9A2,2 0 0,1 14,11A2,2 0 0,1 12,13A2,2 0 0,1 10,11A2,2 0 0,1 12,9M8,17V16C8,14.9 9.79,14 12,14C14.21,14 16,14.9 16,16V17H8Z" />
  </SvgIcon>,
  Book: <SvgIcon {...props}>
    <path fill="currentColor" d="M19 1L14 6V17L19 12.5V1M21 5V18.5C19.9 18.15 18.7 18 17.5 18C15.8 18 13.35 18.65 12 19.5V6C10.55 4.9 8.45 4.5 6.5 4.5C4.55 4.5 2.45 4.9 1 6V20.65C1 20.9 1.25 21.15 1.5 21.15C1.6 21.15 1.65 21.1 1.75 21.1C3.1 20.45 5.05 20 6.5 20C8.45 20 10.55 20.4 12 21.5C13.35 20.65 15.8 20 17.5 20C19.15 20 20.85 20.3 22.25 21.05C22.35 21.1 22.4 21.1 22.5 21.1C22.75 21.1 23 20.85 23 20.6V6C22.4 5.55 21.75 5.25 21 5M10 18.41C8.75 18.09 7.5 18 6.5 18C5.44 18 4.18 18.19 3 18.5V7.13C3.91 6.73 5.14 6.5 6.5 6.5C7.86 6.5 9.09 6.73 10 7.13V18.41Z" />
  </SvgIcon>,
  ChevronDown: <SvgIcon {...props}>
    <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
  </SvgIcon>,
  Close: <SvgIcon {...props}>
    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
  </SvgIcon>,
  Collapse: <SvgIcon {...props}>
    <path fill="currentColor" d="M3.4 22 2 20.6 8.6 14H4v-2h8v8h-2v-4.6zM12 12V4h2v4.6L20.6 2 22 3.4 15.4 10H20v2z" transform="translate(-2 -2)" />
  </SvgIcon>,
  Copy: <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="currentColor" d="M16 1H4a2.006 2.006 0 0 0-2 2v14h2V3h12zm-1 4H8a2 2 0 0 0-1.99 2L6 21a2 2 0 0 0 1.99 2H19a2.006 2.006 0 0 0 2-2V11zM8 21V7h6v5h5v9z" />
  </SvgIcon>,
  Dashboard: <SvgIcon {...props}>
    <path fill="currentColor" d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z" />
  </SvgIcon>,
  DeleteForever: <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="currentColor" d="M14.12 10.47L12 12.59l-2.13-2.12-1.41 1.41L10.59 14l-2.12 2.12 1.41 1.41L12 15.41l2.12 2.12 1.41-1.41L13.41 14l2.12-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19a2.006 2.006 0 0 0 2 2h8a2.006 2.006 0 0 0 2-2V7H6zM8 9h8v10H8z" />
  </SvgIcon>,
  Drag: <SvgIcon {...props}>
    <path fill='currentColor' d="M12,22,7.75,17.75l1.425-1.425L11,18.15V13l-5.125-.025L7.7,14.8,6.25,16.25,2,12,6.225,7.775,7.65,9.2,5.85,11H11V5.85L9.175,7.675,7.75,6.25,12,2l4.25,4.25L14.825,7.675,13,5.85V11l5.125.025L16.3,9.2l1.45-1.45L22,12l-4.25,4.25-1.425-1.425L18.15,13H13l-.025,5.125L14.8,16.3l1.45,1.45Z" transform="translate(-2 -2)" />
  </SvgIcon>,
  Edit: <SvgIcon {...props}>
    <path fill="currentColor" d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" />
  </SvgIcon>,
  Email: <SvgIcon {...props}>
    <path fill="currentColor" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
  </SvgIcon>,
  Expand: <SvgIcon {...props}>
    <path fill='none' d="M0 0h24v24H0z" />
    <path fill='#282828' d="M21 11V3h-8l3.29 3.29-10 10L3 13v8h8l-3.29-3.29 10-10z" />
  </SvgIcon>,
  Info: <SvgIcon {...props}>
    <path fill="currentColor" d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
  </SvgIcon>,
  Location: <SvgIcon {...props}>
    <path fill="currentColor" d="M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z" />
  </SvgIcon>,
  ManageAccount: <SvgIcon {...props}>
    <path fill="currentColor" d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M17 12C16.87 12 16.76 12.09 16.74 12.21L16.55 13.53C16.25 13.66 15.96 13.82 15.7 14L14.46 13.5C14.35 13.5 14.22 13.5 14.15 13.63L13.15 15.36C13.09 15.47 13.11 15.6 13.21 15.68L14.27 16.5C14.25 16.67 14.24 16.83 14.24 17C14.24 17.17 14.25 17.33 14.27 17.5L13.21 18.32C13.12 18.4 13.09 18.53 13.15 18.64L14.15 20.37C14.21 20.5 14.34 20.5 14.46 20.5L15.7 20C15.96 20.18 16.24 20.35 16.55 20.47L16.74 21.79C16.76 21.91 16.86 22 17 22H19C19.11 22 19.22 21.91 19.24 21.79L19.43 20.47C19.73 20.34 20 20.18 20.27 20L21.5 20.5C21.63 20.5 21.76 20.5 21.83 20.37L22.83 18.64C22.89 18.53 22.86 18.4 22.77 18.32L21.7 17.5C21.72 17.33 21.74 17.17 21.74 17C21.74 16.83 21.73 16.67 21.7 16.5L22.76 15.68C22.85 15.6 22.88 15.47 22.82 15.36L21.82 13.63C21.76 13.5 21.63 13.5 21.5 13.5L20.27 14C20 13.82 19.73 13.65 19.42 13.53L19.23 12.21C19.22 12.09 19.11 12 19 12H17M10 14C5.58 14 2 15.79 2 18V20H11.68A7 7 0 0 1 11 17A7 7 0 0 1 11.64 14.09C11.11 14.03 10.56 14 10 14M18 15.5C18.83 15.5 19.5 16.17 19.5 17C19.5 17.83 18.83 18.5 18 18.5C17.16 18.5 16.5 17.83 16.5 17C16.5 16.17 17.17 15.5 18 15.5Z" />
  </SvgIcon>,
  Numbers: <SvgIcon {...props}>
    <path fill="currentColor" d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
  </SvgIcon>,
  Numeric1Circle: <SvgIcon {...props}>
    <path fill="currentColor" d="M10,7V9H12V17H14V7H10M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" />
  </SvgIcon>,
  Numeric2Circle: <SvgIcon {...props}>
    <path fill="currentColor" d="M9,7V9H13V11H11A2,2 0 0,0 9,13V17H11L15,17V15H11V13H13A2,2 0 0,0 15,11V9A2,2 0 0,0 13,7H9M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" />
  </SvgIcon>,
  Phone: <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="#1e4387" d="M6.54 5a12.312 12.312 0 0 0 .45 2.59l-1.2 1.2A14.826 14.826 0 0 1 5.03 5h1.51m9.86 12.02a12.753 12.753 0 0 0 2.6.45v1.49a15.426 15.426 0 0 1-3.8-.75l1.2-1.19M7.5 3H4a1 1 0 0 0-1 1 17 17 0 0 0 17 17 1 1 0 0 0 1-1v-3.49a1 1 0 0 0-1-1 11.407 11.407 0 0 1-3.57-.57.839.839 0 0 0-.31-.05 1.024 1.024 0 0 0-.71.29l-2.2 2.2a15.149 15.149 0 0 1-6.59-6.59l2.2-2.2a1 1 0 0 0 .25-1.02A11.36 11.36 0 0 1 8.5 4a1 1 0 0 0-1-1z" />
  </SvgIcon>,
  PlusCircle: <SvgIcon {...props}>
    <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" />
  </SvgIcon>,
  Refresh: <SvgIcon {...props}>
    <path fill="currentColor" d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
  </SvgIcon>,
  Send: <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="#fff" d="M4.01 6.03l7.51 3.22-7.52-1 .01-2.22m7.5 8.72L4 17.97v-2.22l7.51-1M2.01 3L2 10l15 2-15 2 .01 7L23 12z" />
  </SvgIcon>,
  SMS: <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="currentColor" d="M20 2H4a2.006 2.006 0 0 0-2 2v18l4-4h14a2.006 2.006 0 0 0 2-2V4a2.006 2.006 0 0 0-2-2zm0 14H5.17L4 17.17V4h16zM7 9h2v2H7zm8 0h2v2h-2zm-4 0h2v2h-2z" />
  </SvgIcon>,
  SMSManagement: <SvgIcon {...props}>
    <path fill="currentColor" d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M20 16H5.2L4 17.2V4H20V16M16.8 11.3L15.7 10.5V10 9.5L16.8 8.7C16.9 8.6 16.9 8.5 16.9 8.4L15.9 6.7C15.8 6.6 15.7 6.5 15.6 6.6L14.3 7C14 6.8 13.8 6.6 13.4 6.5L13.2 5.2C13.2 5.1 13.1 5 13 5H11C10.9 5 10.8 5.1 10.8 5.2L10.6 6.5C10.3 6.7 10 6.8 9.7 7L8.5 6.5C8.4 6.5 8.3 6.5 8.2 6.6L7.2 8.3C7.1 8.4 7.2 8.5 7.3 8.6L8.4 9.4V10.4L7.3 11.2C7.2 11.3 7.2 11.4 7.2 11.5L8.2 13.2C8.3 13.3 8.4 13.4 8.5 13.3L9.7 13C10 13.2 10.2 13.4 10.5 13.5L10.7 14.8C10.7 14.9 10.8 15 11 15H13C13.1 15 13.2 14.9 13.2 14.8L13.4 13.5C13.7 13.4 14 13.2 14.2 13L15.4 13.5C15.5 13.5 15.6 13.5 15.7 13.4L16.7 11.7C16.9 11.5 16.9 11.4 16.8 11.3M12 11.5C11.2 11.5 10.5 10.8 10.5 10S11.2 8.5 12 8.5 13.5 9.2 13.5 10 12.8 11.5 12 11.5Z" />
  </SvgIcon>,
  TextArea: <SvgIcon {...props}>
    <path fill="currentColor" d="M13 9H4V23H2V7H13M23 9V7H17V5A1 1 0 0 1 18 4H20V2H17.5C17 2 16 2.45 16 3C16 2.45 15.05 2 14.5 2H12V4H14A1 1 0 0 1 15 5V19A1 1 0 0 1 14 20H12V22H14.5C15.05 22 16 21.55 16 21C16 21.55 16.95 22 17.5 22H20V20H18A1 1 0 0 1 17 19V9Z" />
  </SvgIcon>,
  TextBoxMultiple: <SvgIcon {...props}>
    <path fill="currentColor" d="M16,15H9V13H16V15M19,11H9V9H19V11M19,7H9V5H19V7M3,5V21H19V23H3A2,2 0 0,1 1,21V5H3M21,1A2,2 0 0,1 23,3V17C23,18.11 22.11,19 21,19H7A2,2 0 0,1 5,17V3C5,1.89 5.89,1 7,1H21M7,3V17H21V3H7Z" />
  </SvgIcon>,
  TrayUpload: <SvgIcon {...props}>
    <path fill="currentColor" d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 2L6.46 7.46L7.88 8.88L11 5.75V15H13V5.75L16.13 8.88L17.55 7.45L12 2Z" />
  </SvgIcon>,
  Upload: <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="#fff" d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
  </SvgIcon>,
  User: <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="#fff" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78a7.925 7.925 0 0 1-9.86 0zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33a8 8 0 1 1 12.72 0zM12 6a3.5 3.5 0 1 0 3.5 3.5A3.491 3.491 0 0 0 12 6zm0 5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 11z" />
  </SvgIcon>,
  Notes: <SvgIcon {...props}>
    <path fill='none' d="M0 0h24v24H0z" />
    <path fill="currentColor" d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6a2.006 2.006 0 0 0-2 2v16a2 2 0 0 0 1.99 2H18a2.006 2.006 0 0 0 2-2V8zm4 18H6V4h7v5h5z" />
  </SvgIcon>,
  Plus: <SvgIcon {...props}>
    <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </SvgIcon>,
  filter: <SvgIcon {...props}>
    <path fill="currentColor"  d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z" />
    </SvgIcon>
})

export default icons;
