import request from "../utils/request";

const emailTypesEndpoint = `${process.env.REACT_APP_BASE_URL}/email-type`;

export const fetchEmailType = async () => {
  try {
    const response = await request({
      url: emailTypesEndpoint,
      method: 'GET'
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message,
      data: response.data?.data
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}

export const createEmailType = async (payload) => {
  try {
    const response = await request({
      url: emailTypesEndpoint,
      method: 'POST',
      data: payload
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}

export const updateEmailType = async (payload) => {
  try {
    const response = await request({
      url: emailTypesEndpoint,
      method: 'PUT',
      data: payload
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}

export const deleteEmailType = async (payload) => {
  try {
    const response = await request({
      url: emailTypesEndpoint,
      method: 'DELETE',
      data: payload
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}
