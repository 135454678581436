import React from "react";
import ChipInput from "material-ui-chip-input";

function EmailChipInput({
  value,
  onAdd,
  onDelete,
  label,
  onBlur,
  error,
  helperText,
  variant = 'standard'
}) {
  const handleOnAdd = (data = '') => {
    const separatedData = data.split(/[ ,]+/);
    onAdd(separatedData);
  }

  return <ChipInput
    fullWidth
    value={value}
    onAdd={handleOnAdd}
    onDelete={onDelete}
    label={label}
    onBlur={onBlur}
    error={error}
    helperText={helperText}
    style={{
      marginLeft: '5px',
      marginBottom: '20px'
    }}
    newChipKeys={['Enter', 'Tab']}
    variant={variant}
    alwaysShowPlaceholder
    blurBehavior="add"
  />
}

export default EmailChipInput;
