import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import authReducers from './authReducers';
import { resetInfoHub } from '../info-hub';
import { resetUserManagement } from '../userManagement';
import { resetSnackBar } from '../snackbar';
import { resetSmsTypes } from '../smsTypes';
import { resetEmailTypes } from '../emailTypes'
import { getInfohubs } from '../infohubManagement'
import { getNotes } from '../notes';
import { fetchLoggedInUserDetails } from '../../services/user';
import roleNames from '../../constants/roleNames';

export const initialState = {
  user: null,
  isLoggedIn: false,
  isBrowserRefreshing: true,
  forgotPasswordEmail: '',
  changePasswordEmail: ''
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: { ...authReducers },
});

export const { saveUser, logoutUser, forgotPasswordEmail, changePasswordEmail, setIsBrowserRefreshing } = authSlice.actions;

export const signIn = (userData) => async (dispatch) => {
  try {
    const { email, password } = userData;
    const cognitoUser = await Auth.signIn(email, password);
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch(changePasswordEmail(email));
      return { status: false, message: "Please change your temporary password before login.", redirect: "/change-password" };
    }
    const { data: user } = await fetchLoggedInUserDetails();

    await Promise.all([getInfohubs(dispatch), getNotes(dispatch)]);

    dispatch(saveUser({
      ...user, id: user._id, groups: [...(user?.roles || []), roleNames.NOTES]
    }));

    return { status: true, message: "Sign in successful" };
  } catch (error) {
    if (error?.code === 'PasswordResetRequiredException') {
      dispatch(forgotPasswordEmail(userData.email));
      return { status: false, message: "Please reset your password before login", redirect: "/reset-password" };
    }
    return { status: false, message: error.message };
  }
}

export const changeTempPassword = ({ email, password, newPassword }) => async (dispatch) => {
  try {
    const user = await Auth.signIn(email, password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await Auth.completeNewPassword(user, newPassword);
      await signIn({ email, password: newPassword })(dispatch);
      return { status: true, message: "Password changed successfully" };
    }
    return { status: false, message: "Temporary password already changed" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const logout = () => async (dispatch) => {
  try {
    await Auth.signOut();
    dispatch(logoutUser());
    dispatch(resetInfoHub());
    dispatch(resetUserManagement());
    dispatch(resetSnackBar());
    dispatch(resetSmsTypes());
    dispatch(resetEmailTypes());
    return { status: true, message: "Successfully logged out" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const sendCode = (email) => async (dispatch) => {
  try {
    await Auth.forgotPassword(email);
    dispatch(forgotPasswordEmail(email));
    return { status: true, message: "Forgot password code sent" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const forgotPassword = async ({ email, code, newPassword }) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    return { status: true, message: "Password changed successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const setStateOnMount = () => async (dispatch) => {
  try {
    const { data: user } = await fetchLoggedInUserDetails();
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
      dispatch(saveUser({
        ...user, id: user._id, groups: [...(user?.roles || []), roleNames.NOTES]
      }));
      await getInfohubs(dispatch);
      await getNotes(dispatch);
      dispatch(setIsBrowserRefreshing(false));
    })
  } catch (error) {
    dispatch(logoutUser());
    dispatch(setIsBrowserRefreshing(false));
  }
}

export default authSlice.reducer;
