import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, CardContent, Divider, Button, CircularProgress } from '@material-ui/core';
import TrayUpload from './TrayUploadIcon';
import { DropzoneArea } from 'material-ui-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import createPersistedState from "use-persisted-state";
import uploadUsers from '../../services/uploadUsers';
import getTaskByIdService from '../../services/getTaskById';
import SvgIcon from '../SvgIcons';
import iconNames from '../../constants/iconNames';
import { getUsers } from '../../store/userManagement';
import StatusDialog from './StatusDialog';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import downloadUploadUserTemplate from '../../services/downloadUploadUserTemplate';
const useUploadUsersTaskId = createPersistedState("uploadUsersTaskId");


const useStyles = makeStyles((theme) => ({
  card: {
    height: '80vh'
  },
  downloadButton: {
    background: theme.palette.secondaryButton[900],
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1c0035',
    },
  },
  headerAction: {
    '& > .MuiCardHeader-action': {
      margin: '0px',
      alignSelf: 'center'
    }
  },
  dropZone: {
    '& .MuiDropzoneArea-text.MuiTypography-root': {
      fontSize: '19px',
      fontWeight: 600,
      color: theme.palette.textColor[800]
    }
  },
  [theme.breakpoints.down('md')]: {
    visibleLg: {
      display: 'none'
    }
  },
  actionContainer: {
    flexDirection: 'column',
    flex: '0 0 auto',
    '& .statusLink': {
      color: theme.palette.secondaryButton[900],
      textDecoration: 'underline',
      fontSize: 'small',
      marginTop: '5px',
      cursor: 'pointer'
    }

  }
}));

function UploadUsersCard({ setIsUsersListLoading }) {
  const [file, setFile] = useState([]);
  const [key, setKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplateDownloading, setIsTemplateDownloading] = useState(false);
  const [response, setResponse] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [taskId, setTaskId] = useUploadUsersTaskId(null);
  const { page, limit, search, roles, serviceProvider } =
    useSelector((state) => state.userManagement);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (taskId) {
      getUploadStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFile = (file, event) => {
    setFile(file);
  }

  const handleDialogClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    setIsDialogOpen(false)
  };

  const startUpload = async () => {
    setIsLoading(true);
    const res = await uploadUsers(file[0]);
    if (res.status) {
      dispatch(openSnackbar({ message: 'Users create/update process has started!', type: SNACKBAR_TYPES.SUCCESS }));
      setTaskId(res?.data?.taskId)
      setIsUploadInProgress(true);
    } else {
      setResponse(res);
      setIsDialogOpen(true);
    }
    setIsLoading(false);
    setKey(key + 1);
  }

  const getUploadStatus = async () => {
    setIsUploadInProgress(true);
    const res = await getTaskByIdService(taskId);
    if (res.status && res.data?.task?.status === 'SUCCEEDED') {
      if (res.data.task.response.reduce((success, current) => success && current?.success, true)) {
        setTaskId(null)
        dispatch(openSnackbar({ message: 'Users created/updated successfully', type: SNACKBAR_TYPES.SUCCESS }));
      } else {
        setTaskId(null)
        setResponse({ data: res.data.task.response });
        setIsDialogOpen(true);
      }
    } else if (res.status && res.data?.task?.status === 'FAILED') {
      setTaskId(null)
      setResponse({ ...res, data: res.data.task.response });
      dispatch(openSnackbar({ message: 'Upload process failed. Please contact your system administrator.', type: SNACKBAR_TYPES.ERROR }));
    } else {
      dispatch(openSnackbar({ message: 'Users create/update still under processing.', type: SNACKBAR_TYPES.INFO }));
      return;
    }
    setIsUploadInProgress(false);
    setIsUsersListLoading(true);
    await getUsers({ page, limit, search, roles, serviceProvider })(dispatch);
    setIsUsersListLoading(false);
  }

  const clearDownloadStatus = () => {
    setIsUploadInProgress(false);
    setTaskId(null);
  }

  const handleTemplateDownload = async () => {
    setIsTemplateDownloading(true);
    const res = await downloadUploadUserTemplate();
    dispatch(openSnackbar({ message: res.message, type: res.status ? SNACKBAR_TYPES.SUCCESS : SNACKBAR_TYPES.ERROR }));
    setIsTemplateDownloading(false);
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Upload Users"
        disableTypography
        className={classes.headerAction}
        action={
          <Button
            variant="contained"
            size="small"
            className={classes.downloadButton}
            startIcon={isTemplateDownloading ? <CircularProgress size={18} color="inherit" /> : <GetApp />}
            disabled={isTemplateDownloading}
            onClick={handleTemplateDownload}
          >
            {!isTemplateDownloading && <div><span className={classes.visibleLg}>Download&nbsp;</span> Template</div>}
            {isTemplateDownloading && <div>Downloading<span className={classes.visibleLg}>&nbsp;Template</span></div>}
          </Button>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.dropZone}>
            <DropzoneArea
              key={key}
              dropzoneText="Tap to choose a file or drag it here"
              showPreviews={true}
              showPreviewsInDropzone={false}
              acceptedFiles={['.xlsx']}
              useChipsForPreview
              Icon={TrayUpload}
              showAlerts={false}
              previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
              previewChipProps={{ classes: { root: classes.previewChip } }}
              previewText="Selected files"
              onDrop={getFile}
              onChange={getFile}
              filesLimit={1}
            />
          </Grid>
          <Grid item container justifyContent="center" alignItems='center' className={classes.actionContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={startUpload}
              startIcon={(!isLoading && <SvgIcon iconName={iconNames.Upload} />)}
              disabled={!file.length || isLoading || isUploadInProgress}
            >
              {!isLoading && !isUploadInProgress && 'Upload'}
              {(isLoading || isUploadInProgress) && <CircularProgress size={24} color="inherit" />}
            </Button>
            {isUploadInProgress && <div>
              <Button color="primary" onClick={getUploadStatus}>Get Status</Button>
              |<Button onClick={clearDownloadStatus} color="primary" size="small" >Cancel</Button>
            </div>}
          </Grid>
        </Grid>
      </CardContent>
      <StatusDialog
        open={isDialogOpen}
        handleClose={handleDialogClose}
        response={response}
      />
    </Card>
  )
}

export default UploadUsersCard;
