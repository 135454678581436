import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

function ConfirmationDialogBox({ open, title, message, onDone, onCancel, cancelButtonLabel = 'Cancel', confirmButtonLabel = 'Delete' }) {
  const handleOnClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onCancel()
  }

  return <Dialog
    open={open}
    onClose={handleOnClose}
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle>
      {title}
    </DialogTitle>
    <DialogContent>
      {message}
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={onCancel}>{cancelButtonLabel}</Button>
      <Button variant="contained" color="secondary" onClick={onDone}>{confirmButtonLabel}</Button>
    </DialogActions>
  </Dialog>
}

export default ConfirmationDialogBox;
