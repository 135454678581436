const createTimeStampFileName = (fileName = '', extension = 'csv') => {
  const date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  const hour = `${date.getHours()}`.padStart(2, '0');
  const minutes = `${date.getMinutes()}`.padStart(2, '0');

  return `${fileName}_${year}-${month}-${day}_${hour}-${minutes}.${extension}`;
}

export default createTimeStampFileName;
