import React, { useState, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../constants/routes';
import Headers from './Headers';
import Sidebar from './SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar } from '../store/snackbar';
import SnackBar from '../components/SnackBar';
import InfoHubLayout from '../pages/InfoHub/InfoHubLayout';


const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    overflow: 'hidden'
  },
  containerShifted: {
    height: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 240,
    width: 'calc(100vw - 240px)'
  },
  headers: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2.9px 3.9px hsl(0deg 0% 0% / 0.46)',
    zIndex: 10,
    height: '10vh',
    minHeight: 50
  },
  subContainer: {
    backgroundColor: '#e6e9f2',
    height: '90vh',
    overflow: 'auto'
  },
  [theme.breakpoints.down('md')]: {
    containerShifted: {
      marginLeft: 200,
      width: 'calc(100vw - 200px)'
    }
  }
}));

function Layout({ children, params }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSideBarVisible, setIsSideBarVisible] = useState(false);
  const isSnackBarOpen = useSelector(state => state.snackbar.isOpen);
  const snackBarType = useSelector(state => state.snackbar.type);
  const snackBarMessage = useSelector(state => state.snackbar.message);
  const { pathname } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsSideBarVisible(routes.find(el => matchPath(pathname, { path: el.path, exact: true }))?.showSidebar || false);
  }, [dispatch, pathname])

  const toggleDrawer = (isOpen) => (event) => {
    setIsOpen(isOpen);
  };

  return (
    <div>
      {isSideBarVisible && <Sidebar isOpen={isOpen} />}
      <main>
        <Grid container className={clsx(classes.container, { [classes.containerShifted]: (isOpen && isSideBarVisible) })}>
          <Grid item xs={12} className={classes.headers}>
            <Headers toggleDrawer={toggleDrawer} isOpen={isOpen} isSideBarVisible={isSideBarVisible} params={params} />
          </Grid>
          <Grid item xs={12} className={classes.subContainer}>
            {
              !params?.infoHub && children
            }
            {
              params?.infoHub && <InfoHubLayout params={params}>{children}</InfoHubLayout>
            }
          </Grid>
        </Grid>
        <SnackBar
          open={isSnackBarOpen}
          onClose={() => dispatch(closeSnackbar())}
          message={snackBarMessage}
          type={snackBarType}
        />
      </main>
    </div>
  )
}

export default Layout;