import request from "../utils/request";

const emailReportEndPoint = `${process.env.REACT_APP_BASE_URL}/email-report`;

const downloadEmailReport = async ({ emailTypes, from, to }) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await request({
      url: emailReportEndPoint,
      method: 'post',
      data: {
        timezone,
        emailTypes,
        from,
        to
      },
      headers: {
        "Accept": "application/ms-excel"
      }
    });
    
    return {
      status: true,
      statusCode: response?.status,
      message: 'Report generation started successfully',
      data: response.data?.taskId || '',
    };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status,
      message: 'Report generation failed'
    };
  }
}

export default downloadEmailReport;
