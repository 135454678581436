const menuItems = (path) => [
  {
    name: 'Homepage',
    path: path
  },
  {
    name: 'All Notices',
    path: `${path}/notices`
  },
  {
    name: 'FAQs',
    path: `${path}/faqs`
  },
  {
    name: 'Training Material',
    path: `${path}/training-material`
  },
  {
    name: 'Resources',
    path: `${path}/resources`
  }
]

export default menuItems;
