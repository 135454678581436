import React from 'react';
import { Grid, TextField, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '../SvgIcons';
import CopyButton from '../CopyButton';
import iconNames from '../../constants/iconNames';
import styling from './styling';

const useStyles = makeStyles(theme => styling(theme));

function SMSPreview({ isSubmitting, sms, handleReset, copySMS, isSendDisabled }) {
  const classes = useStyles();

  return (
    <Grid container >
      <Grid item container display="flex" alignItems='center' spacing={1} className={classes.headingContainer} >
        <Grid item container sm={1} xs={2}>
          <Typography variant="h6" className={classes.heading}>
            <span className={classes.numberBox}>2</span>
          </Typography>
        </Grid>
        <Grid item sm={11} xs={10}>
          <Typography variant="h6" className={classes.heading} style={{marginLeft:'5px'}}>
            SMS Preview
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.fullWidth}>
        <Grid container>
          <Grid item container  alignContent="flex-start" className={classes.marginBottom}>
            <Grid item container justifyContent="flex-start" sm={1} xs={2}>
              <SvgIcon className={classes.smsIcon} color="primary" iconName={iconNames.SMS} />
            </Grid>
            <Grid item sm={11} xs={10}>
              <TextField
                label=""
                variant="filled"
                value={sms}
                className={classes.smsPreview}
                fullWidth
                multiline
                minRows={9}
                maxRows={9}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end" className={classes.marginBottom}>
            <CopyButton disabled={!Boolean(sms)} copy={copySMS} title="Copy SMS" />
          </Grid>
          <Grid item container spacing={1} justifyContent="flex-end" className={classes.marginBottom}>
            <Grid item>
              <Button type="submit" color="primary" variant="contained" disabled={isSendDisabled || isSubmitting}
                startIcon={<SvgIcon iconName={iconNames.Send} />}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Send SMS'}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={handleReset} disabled={isSubmitting}
                startIcon={<SvgIcon iconName={iconNames.DeleteForever} />}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SMSPreview;
