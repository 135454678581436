import { initialState } from '../snackbar';
import SNACKBAR_TYPES from "../../constants/snackBarTypes";

const openSnackbar = (state, action) => {
  state.message = action.payload?.message;
  state.type = action.payload?.type || SNACKBAR_TYPES.SUCCESS;
  state.isOpen = true;
}

const closeSnackbar = (state, action) => {
  state.isOpen = false;
}

const resetSnackBar = (state, action) => {
  return initialState
}

const reducers = { openSnackbar, closeSnackbar, resetSnackBar };

export default reducers;
