import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "@material-ui/core";
import createPersistedState from "use-persisted-state";
import iconNames from "../../constants/iconNames";
import SvgIcon from "../../components/SvgIcons";

import { TextField, MenuItem, InputAdornment } from "@material-ui/core";
import { useSelector } from "react-redux";
import AddressSMSType from "../../components/SMSForm/AddressSmsType";
import {
  createEmptyNotesData,
  generateNotesDescription,
} from "../../utils/notesDataHandler";
import "./notesTool.scss";
const useNotesState = createPersistedState("notesData");
const useNotesTemplateState = createPersistedState("notesTemplate");

const NotesTool = () => {
  const { notesTemplate: notes } = useSelector((state) => state.notes);
  const [isCleared, setIsCleared] = useState(true);
  const [notesContentData, setNotesContentData] = useNotesState(
    createEmptyNotesData(notes?.types || [])
  );
  const [template, setTemplate] = useNotesTemplateState("");
  const addressRef = useRef(null);

  const onInputChange = ({ reference, type, prefix }, value) => {
    setNotesContentData({
      ...notesContentData,
      [reference]: { type, value, prefix },
    });
    if (isCleared) {
      setIsCleared(false);
    }
  };

  const handleClear = () => {
    setNotesContentData([]);
    setIsCleared(true);
    setTemplate("");
    addressRef.current?.clearAddress?.();
  };

  useEffect(() => {
    if (!isCleared) {
      setTemplate(generateNotesDescription(notesContentData, notes?.template));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes?.template, notesContentData]);

  return (
    <div className="container">
   <Card className="main-notes-container">
      <div className="sub-notes-container">
        {notes?.types?.map((type, index, types) => {
          const isLastElement = types.length - 1 === index;
          if (type.type === "Text") {
            return (
              <TextField
                label={type.label}
                key={index}
                fullWidth
                autoComplete="off"
                value={notesContentData[type.reference]?.value || ""}
                onChange={(e) => onInputChange(type, e.target.value)}
                variant="outlined"
                margin="normal"
                size="small"
                name={type.reference}
                className={`${isLastElement ? 'textarea-box':'input-box' }`}
              />
            );
          } else if (type.type === "TextArea") {
            return (
              <TextField
                key={index}
                label={type.label}
                variant="outlined"
                autoComplete="off"
                value={notesContentData[type.reference]?.value || ""}
                onChange={(e) => onInputChange(type, e.target.value)}
                name={type.reference}
                fullWidth
                multiline
                margin="normal"
                minRows={5}
                maxRows={5}
                className={`${isLastElement ? 'textarea-box':'input-box' }`}
              />
            );
          } else if (type.type === "Number") {
            return (
              <TextField
                key={index}
                label={type.label}
                variant="outlined"
                name={type.reference}
                fullWidth
                type="number"
                autoComplete="off"
                value={notesContentData[type.reference]?.value || ""}
                onChange={(e) => onInputChange(type, e.target.value)}
                size="small"
                margin="normal"
                className={`${isLastElement ? 'textarea-box':'input-box' }`}
              />
            );
          } else if (type.type === "Referral") {
            return (
              <TextField
                key={index}
                label={type.label}
                variant="outlined"
                name={type.reference}
                fullWidth
                autoComplete="off"
                value={notesContentData[type.reference]?.value || ""}
                onChange={(e) => onInputChange(type, e.target.value)}
                size="small"
                margin="normal"
                InputProps={{
                  startAdornment: type?.prefix && (
                    <InputAdornment position="start">
                      {type?.prefix}
                    </InputAdornment>
                  ),
                }}
                className={`${isLastElement ? 'textarea-box':'input-box' }`}
              />
            );
          } else if (type.type === "Dropdown") {
            return (
              <TextField
                key={index}
                label={type.label}
                select
                variant="outlined"
                name={type.reference}
                fullWidth
                autoComplete="off"
                value={notesContentData[type.reference]?.value || ""}
                onChange={(e) => onInputChange(type, e.target.value)}
                size="small"
                margin="normal"
                className={`${isLastElement ? 'textarea-box':'input-box' }`}
              >
                {type?.options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            );
          } else if (type.type === "Address") {
            return (
              <AddressSMSType
                key={index}
                ref={addressRef}
                onChange={(e) => onInputChange(type.reference, e)}
                type="SMS"
                className={`${isLastElement ? 'textarea-box':'input-box' }`}
              />
            );
          }
          return null;
        })}
      </div>
      {/* <TextField
        label="Notes"
        variant="filled"
        autoComplete="off"
        value={template}
        onChange={(e) => setTemplate(e.target.value)}
        name="template"
        margin="normal"
        fullWidth
        multiline
        minRows={5}
        maxRows={5}
      /> */}

      <div className="btn-box">
          <Button
            disabled={!Boolean(template)}
            variant="outlined"
            color="primary"
            onClick={handleClear}
            startIcon={<SvgIcon iconName={iconNames.DeleteForever} />}
          >
            Clear
          </Button>
      </div>
    </Card>
    </div>
 
  );
};

export default NotesTool;
