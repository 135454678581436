import request from "../utils/request";
import generateQueryParams from "../utils/generateQueryParams";

const UsersServiceEndPoint = (query) => {
  let link = `${process.env.REACT_APP_BASE_URL}/list-users`;
  if (query) {
    link = link.concat(`?${generateQueryParams(query)}`);
  }
  return link;
};

const getAllUsersService = async (query = null) => {
  try {
    const response = await request({
      url: UsersServiceEndPoint(query),
      baseURL: '/',
      method: 'get'
    });
    return response.data;
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status,
      message: error?.response?.data || error.message
    };
  }
}

export default getAllUsersService;