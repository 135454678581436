const generateQueryParams = (obj) => {
  const keys = Object.keys(obj);
  const query = [];

  keys.forEach(key => {
    if (obj[key] && Array.isArray(obj[key]) && obj[key]?.length > 0) {
      obj[key]?.forEach((item) => query.push(`${key}=${encodeURIComponent(item)}`));
    } else if (obj[key] && typeof (obj[key]) === 'string' && obj[key]?.length > 0) {
      query.push(`${key}=${encodeURIComponent(obj[key])}`);
    } else if (obj[key] && typeof (obj[key]) === 'number') {
      query.push(`${key}=${encodeURIComponent(obj[key])}`);
    }
  });

  const queryString = query?.length ? `${query.join('&')}` : '';

  return queryString;
}

export default generateQueryParams;
