import request from "../utils/request";

const smsTypesEndpoint = `${process.env.REACT_APP_BASE_URL}/sms-type`;

export const fetchSmsType = async () => {
  try {
    const response = await request({
      url: smsTypesEndpoint,
      method: 'GET'
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message,
      data: response.data?.data
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}

export const createSmsType = async (payload) => {
  try {
    const response = await request({
      url: smsTypesEndpoint,
      method: 'POST',
      data: payload
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}

export const updateSmsType = async (payload) => {
  try {
    const response = await request({
      url: smsTypesEndpoint,
      method: 'PUT',
      data: payload
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}

export const deleteSmsType = async (payload) => {
  try {
    const response = await request({
      url: smsTypesEndpoint,
      method: 'DELETE',
      data: payload
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}
