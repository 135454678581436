export const styling = (theme) => ({
  smsTypeInput: {
    width:'100%',
    [theme.breakpoints.down('md')]:{
       marginTop:'5px'
    }
  },
  deleteIcon: {
    color: theme.palette.error.main
  },
  deleteButton: {
    height: 30,
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 5
    }
  },
  accordion: {
    width: '100%',
    border: `1px solid ${theme.palette.primaryButton[500]}`,
    '& .MuiAccordionSummary-root': {
      background: '#c9e2f5',
      borderBottom: `1px solid ${theme.palette.primaryButton[500]}`,
      '& .MuiIconButton-label .MuiSvgIcon-root': {
        color: theme.palette.primary.main
      }
    }
  }
})