import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import SvgIcon from "../SvgIcons";
import iconNames from "../../constants/iconNames";
import AddressSMSType from "../SMSForm/AddressSmsType";
import styling from "./styling";
import "./chipsStyle.scss";
import { getIsValidParentValue } from "../SMSForm/SMSUserDetails";
import EmailChipInput from "../EmailType/EmailChipInput";

const useStyles = makeStyles(styling);

const selectListContainerHeight = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  },
};

function EmailUserDetails({ emailTypes, formik }) {
  const classes = useStyles();
  const [fieldTo, setFieldTo] = useState([]);
  const [fieldCc, setFieldCc] = useState([]);
  const isInvalid = (index) => {
    return (
      formik.touched?.typeValues?.[index] &&
      !!formik.errors?.typeValues?.[index]
    );
  };

  const addToEmails = (data = []) => {
    const emailType = emailTypes[formik.values.emailType];
    if (!emailType?.isToEditable) {
      return;
    }
    const newValues = [...fieldTo, ...data];
    setFieldTo(newValues);
    formik.setFieldValue("to", newValues);
  };

  const getErrorMessage = (error) => {
    if (Array.isArray(error)) {
      const validErrorMessages = error.filter(Boolean);
      return validErrorMessages[0];
    }
    return error;
  }
  const deleteToEmails = (data) => {
    const emailType = emailTypes[formik.values.emailType];
    if (!emailType?.canRemovePreFilledToEmails && emailType?.to?.includes(data)) {
      return;
    }
    const newValues = fieldTo.filter(item => item !== data);
    setFieldTo(newValues);
    formik.setFieldValue("to", newValues);
  };
  const addCcEmails = (data = []) => {
    const emailType = emailTypes[formik.values.emailType];
    if (!emailType?.isCcEditable) {
      return;
    }
    const newValues = [...fieldCc, ...data];
    setFieldCc(newValues);
    formik.setFieldValue("cc", newValues, true);
  };
  const deleteCcEmails = (data) => {
    const emailType = emailTypes[formik.values.emailType];
    if (!emailType?.canRemovePreFilledCcEmails && emailType?.cc?.includes(data)) {
      return;
    }
    const newValues = fieldCc.filter(item => item !== data);
    setFieldCc(newValues);
    formik.setFieldValue("cc", newValues, true);
  };
  const validateTo = async () => {
    await formik.validateField('to');
    await formik.setFieldTouched('to');
  }
  const validateCc = async () => {
    await formik.validateField('cc');
    await formik.setFieldTouched('cc');
  }

  useEffect(() => {
    const emailType = emailTypes[formik.values.emailType];
    setFieldTo(emailType?.to || []);
    setFieldCc(emailType?.cc || []);
    formik.setFieldValue("to", emailType?.to || [], true);
    formik.setFieldValue("cc", emailType?.cc || [], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTypes, formik.values.emailType,]);

  return (
    <Grid className={classes.container}>
      <Grid
        item
        container
        display="flex"
        alignItems="center"
        spacing={1}
        className={classes.headingContainer}
      >
        <Grid item container sm={1} xs={2}>
          <Typography variant="h6" className={classes.heading}>
            <span className={classes.numberBox}>1</span>
          </Typography>
        </Grid>
        <Grid item sm={11} xs={10}>
          <Typography
            variant="h6"
            className={classes.heading}
            style={{ marginLeft: "5px" }}
          >
            Aged Care User and Email Details
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.emailTypeBoxHeight}>
      <Grid container spacing={1} className={classes.emailTypesContainer}>
        <Grid item container alignItems="center">
          <Grid item container sm={1} xs={2} className={classes.inputIcon}>
            <SvgIcon color="primary" iconName={iconNames.SMS} />
          </Grid>
          <Grid item sm={11} xs={10}>
            <TextField
              label={
                !!emailTypes?.length
                  ? "Select Email Type"
                  : "No Email Types available"
              }
              name="emailType"
              select
              variant="filled"
              fullWidth
              autoComplete="off"
              size="small"
              SelectProps={selectListContainerHeight}
              value={formik.values.emailType < 0 ? "" : formik.values.emailType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.emailType && Boolean(formik.errors.emailType)
              }
              helperText={
                (formik.touched.emailType && formik.errors.emailType) || " "
              }
              disabled={!emailTypes?.length}
              className={classes.inputLabels}
            >
              {emailTypes.map((option, index) => (
                <MenuItem key={index} value={index}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {
          formik.values.emailType >= 0 && (
            <>
              <Grid item container alignItems="center">
                <Grid
                  item
                  container
                  sm={1}
                  xs={2}
                  style={{ padding: '4px 0px' }}
                  className={classes.inputIcon}
                >
                  <SvgIcon color="primary" iconName={iconNames.Email} />
                </Grid>
                <Grid item sm={11} xs={10}>
                  <EmailChipInput
                    value={fieldTo}
                    onAdd={addToEmails}
                    onDelete={deleteToEmails}
                    label="TO"
                    onBlur={validateTo}
                    error={formik.touched.to && Boolean(formik.errors.to)}
                    helperText={(formik.touched.to && getErrorMessage(formik.errors.to)) || " "}
                    variant="filled"
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid
                  item
                  container
                  sm={1}
                  xs={2}
                  style={{ padding: '4px 0px' }}
                  className={classes.inputIcon}
                >
                  <SvgIcon color="primary" iconName={iconNames.Email} />
                </Grid>
                <Grid item sm={11} xs={10}>
                  <EmailChipInput
                    value={fieldCc}
                    onAdd={addCcEmails}
                    onDelete={deleteCcEmails}
                    label="CC"
                    onBlur={validateCc}
                    error={formik.touched.cc && Boolean(formik.errors.cc)}
                    helperText={(formik.touched.cc && getErrorMessage(formik.errors.cc)) || " "}
                    variant="filled"
                  />
                </Grid>
              </Grid>
            </>
          )}

        {formik.values.emailType >= 0 &&
          emailTypes[formik.values.emailType]?.types.map((emailType, index) => {
            if (!!emailType?.parent && !getIsValidParentValue(emailTypes[formik.values.emailType]?.types, emailType?.parent, emailType?.id, index, formik)) {
              return null;
            }
            if (emailType?.type === "Text") {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid
                    item
                    container
                    sm={1}
                    xs={2}
                    className={classes.inputIcon}
                  >
                    <SvgIcon color="primary" iconName={iconNames.Alphabets} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={emailType?.label}
                      title={emailType?.label}
                      placeholder={emailType?.placeholder || ""}
                      name={`typeValues[${index}]`}
                      variant="filled"
                      fullWidth
                      autoComplete="off"
                      value={formik.values.typeValues[index] || ""}
                      onChange={formik.handleChange}
                      size="small"
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={
                        (isInvalid(index) &&
                          `${emailType?.placeholder} ${formik.errors?.typeValues[index]}`) ||
                        " "
                      }
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              );
            } else if (emailType?.type === "TextArea") {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid
                    item
                    container
                    sm={1}
                    xs={2}
                    className={classes.inputIcon}
                  >
                    <SvgIcon color="primary" iconName={iconNames.TextArea} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={emailType?.label}
                      title={emailType?.label}
                      placeholder={emailType?.placeholder || ""}
                      variant="filled"
                      name={`typeValues[${index}]`}
                      fullWidth
                      multiline
                      minRows={5}
                      maxRows={5}
                      autoComplete="off"
                      value={formik.values.typeValues[index] || ""}
                      onChange={formik.handleChange}
                      size="small"
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={
                        (isInvalid(index) &&
                          `${emailType?.placeholder} ${formik.errors?.typeValues[index]}`) ||
                        " "
                      }
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              );
            } else if (emailType?.type === "Number") {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid
                    item
                    container
                    sm={1}
                    xs={2}
                    className={classes.inputIcon}
                  >
                    <SvgIcon color="primary" iconName={iconNames.Numbers} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={emailType?.label}
                      title={emailType?.label}
                      placeholder={emailType?.placeholder || ""}
                      variant="filled"
                      name={`typeValues[${index}]`}
                      fullWidth
                      type="number"
                      autoComplete="off"
                      value={formik.values.typeValues[index] || ""}
                      onChange={formik.handleChange}
                      size="small"
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={
                        (isInvalid(index) &&
                          `${emailType?.placeholder} ${formik.errors?.typeValues[index]}`) ||
                        " "
                      }
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              );
            } else if (emailType?.type === "Referral") {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid
                    item
                    container
                    sm={1}
                    xs={2}
                    className={classes.inputIcon}
                  >
                    <SvgIcon color="primary" iconName={iconNames.Assignment} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={emailType?.label}
                      title={emailType?.label}
                      placeholder={emailType?.placeholder || ""}
                      variant="filled"
                      fullWidth
                      autoComplete="off"
                      name={`typeValues[${index}]`}
                      value={formik.values.typeValues[index] || ""}
                      onChange={formik.handleChange}
                      size="small"
                      InputProps={{
                        startAdornment: emailType?.prefix && (
                          <InputAdornment position="start">
                            {emailType?.prefix}
                          </InputAdornment>
                        ),
                      }}
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={
                        (isInvalid(index) &&
                          `${emailType?.placeholder} ${formik.errors?.typeValues[index]}`) ||
                        " "
                      }
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              );
            } else if (emailType?.type === "Dropdown") {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid
                    item
                    container
                    sm={1}
                    xs={2}
                    className={classes.inputIcon}
                  >
                    <SvgIcon color="primary" iconName={iconNames.ArrowDown} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={emailType?.label}
                      title={emailType?.label}
                      placeholder={emailType?.placeholder || ""}
                      select
                      variant="filled"
                      name={`typeValues[${index}]`}
                      fullWidth
                      autoComplete="off"
                      value={formik.values.typeValues[index] || ""}
                      onChange={formik.handleChange}
                      size="small"
                      SelectProps={selectListContainerHeight}
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={
                        (isInvalid(index) &&
                          `${emailType?.placeholder} ${formik.errors?.typeValues[index]}`) ||
                        " "
                      }
                      className={classes.inputLabels}
                    >
                      {emailType?.options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              );
            } else if (emailType?.type === "Address") {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid
                    item
                    container
                    sm={1}
                    xs={2}
                    className={classes.inputIcon}
                  >
                    <SvgIcon color="primary" iconName={iconNames.Location} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <AddressSMSType
                      onChange={(value) =>
                        formik.setFieldValue(
                          `typeValues[${index}]`,
                          value,
                          true
                        )
                      }
                      type="EMAIL"
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              );
            }
            return null;
          })}
      </Grid>
      </Grid>
    </Grid>
  );
}

export default EmailUserDetails;
