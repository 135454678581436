export const createEmptyNotesData = (types) => {
  const data = {};

  types?.forEach(type => data[type?.reference] = { value: '' });

  return data
};

export const generateNotesDescription = (data = {}, template = '') => {
  let generatedMessage = template;
  Object.keys(data)?.forEach((item) => {
    if (data[item]?.type === 'Referral') {
      generatedMessage = generatedMessage.replaceAll(`{{${item}}}`, `${data[item]?.prefix || ''}${data[item]?.value}`);
    } else {
      generatedMessage = generatedMessage.replaceAll(`{{${item}}}`, data[item]?.value);
    }
  });
  return generatedMessage;
}
