import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import UploadUsersCard from '../../components/UserManagementCards/UploadUsersCard';
import CurrentUsersCard from '../../components/UserManagementCards/CurrentUsersCard';
import UserInfoCard from '../../components/UserManagementCards/UserInfoCard';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: 0,
    padding: '0px 24px',
    paddingTop: '1%',
    '& .MuiDropzoneArea-text.MuiTypography-root': {
      margin: '24px 8px'
    }
  },
}));

function UserManagement() {
  const classes = useStyles();
  const [isUsersListLoading, setIsUsersListLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);


  return (
    <Grid container justifyContent="center" spacing={4} className={classes.container}>
      <Grid item md={8} xs={12} >
        <CurrentUsersCard
          isLoading={isUsersListLoading}
          setIsLoading={setIsUsersListLoading}
          setSelectedUser={setSelectedUser}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        {!selectedUser && <UploadUsersCard setIsUsersListLoading={setIsUsersListLoading} />}
        {selectedUser && <UserInfoCard selectedUser={selectedUser} setSelectedUser={setSelectedUser} />}
      </Grid>
    </Grid>
  )
}

export default UserManagement;
