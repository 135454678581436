import React, { useState } from 'react';
import { Grid, Card, CardHeader, CardContent, TextField, Divider, Button, CircularProgress, Typography } from '@material-ui/core';
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { sendCode } from '../../store/auth';
import { primaryColorLink } from '../../constants/linkClasses';
import './SendCode.scss';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';

function SendCode() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!email) return;

    setIsLoading(true);

    const response = await sendCode(email)(dispatch);

    if (response.status) {
      dispatch(openSnackbar({ message: 'Verification code sent to your registered email', type: SNACKBAR_TYPES.SUCCESS }));
      return history.push('/reset-password');
    }
    dispatch(openSnackbar({ message: response.message, type: SNACKBAR_TYPES.ERROR }));
    setIsLoading(false);
  }

  return (
    <Grid container justifyContent="center" alignContent="center" className="send-code-container">
      <Card className="send-code-card">
        <CardHeader title="Send Code" />
        <Divider />
        <CardContent>
          <form onSubmit={submitHandler}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>Enter your email and we will send a code to that email to verify your account.</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={isLoading} fullWidth>
                  {!isLoading && 'Send Code'}
                  {isLoading && <CircularProgress size={24} color="inherit" />}
                </Button>
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Link color="primary" to="/" className={primaryColorLink}>Remember your password? Login</Link>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default SendCode;
