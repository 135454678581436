function downloadDocument(url, name) {
    const prev_element = document.getElementById("downloadDocument");
    prev_element && prev_element.remove();
    const a = document.createElement('a');
    a.setAttribute('id', 'downloadDocument')
    document.body.appendChild(a);
    a.href = url;
    if (name) {
        a.download = name;
    }
    a.click();
}

export default downloadDocument;