import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoHubHeader from './InfoHubHeader';
import SnackBar from '../../components/SnackBar';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar } from '../../store/snackbar';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%'
  },
  infoHubContainer: {
    height: 'calc(100% - 7vh)',
    padding: '2% 3%',
    overflow: 'auto'
  }
}));

function InfoHub({ children, params }) {
  const classes = useStyles();
  const isSnackBarOpen = useSelector(state => state.snackbar.isOpen);
  const snackBarType = useSelector(state => state.snackbar.type);
  const snackBarMessage = useSelector(state => state.snackbar.message);
  const dispatch = useDispatch();

  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      <InfoHubHeader params={params}></InfoHubHeader>
      <Grid item container alignContent="flex-start" id="info-hub-layout" className={classes.infoHubContainer}>
        {children}
      </Grid>
      <SnackBar
        open={isSnackBarOpen}
        onClose={() => dispatch(closeSnackbar())}
        message={snackBarMessage}
        type={snackBarType}
      />
    </Grid>
  )
}

export default InfoHub
