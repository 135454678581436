import React from 'react';
import { Grid, TextField, Typography, Button, CircularProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '../SvgIcons';
import iconNames from '../../constants/iconNames';
import styling from '../SMSForm/styling';
import { Editor } from '@tinymce/tinymce-react';

const useStyles = makeStyles(theme => ({
  ...styling(theme),
  previewContainer: {
    marginTop: 24,
    height: '90%',
    "& input[disabled] ": {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  subject: {
    marginBottom: 15
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  editorHeight: {
    width: '100%',
    [theme.breakpoints.up("md")]: {
      height: '70%'
    },
    [theme.breakpoints.down("sm")]: {
      height: '500px'
    },
  },
  numberBox: {
    width: '30px',
    height: '30px',
    backgroundColor: '#2a4c7e',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.38',
    letterSpacing: '0px',
    color: '#ffffff'
  }
}));

function EmailPreview({ isSubmitting, body = '', subject = '', handleReset, isSendDisabled }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Grid container className={classes.editorHeight}>
        <Grid item container display="flex" alignItems='center' spacing={1}>
          <Grid item container sm={1} xs={2}>
            <Typography variant="h6" className={classes.heading}>
              <span className={classes.numberBox}>2</span>
            </Typography>
          </Grid>
          <Grid item sm={11} xs={10}>
            <Typography variant="h6" className={classes.heading} style={{ marginLeft: '5px' }}>
              Email Preview
            </Typography>
          </Grid>
        </Grid>
        <Grid container display="flex" justifyContent='flex-end' className={classes.previewContainer} style={{ marginLeft: '5px' }}>
          <Grid item sm={11} xs={10} className={classes.marginBottom} >
            <TextField
              label="Subject"
              variant="outlined"
              size="small"
              fullWidth
              value={subject}
              className={classes.subject}
              disabled
            />
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              init={{
                menubar: false,
                branding: false,
                statusbar: false,
                height: '80%',
                width: '100%',
                resize: false,
                toolbar: 'preview',
                plugins: ['preview'],
                readonly: 1
              }}
              value={body}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="flex-end" className={classes.marginBottom}>
        <Grid item>
          <Button type="submit" color="primary" variant="contained" disabled={isSendDisabled || isSubmitting}
            startIcon={<SvgIcon iconName={iconNames.Send} />}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Send Email'}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleReset} disabled={isSubmitting}
            startIcon={<SvgIcon iconName={iconNames.DeleteForever} />}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EmailPreview;