import { configureStore } from '@reduxjs/toolkit';
import auth from './auth';
import userManagement from './userManagement';
import infoHub from './info-hub';
import snackbar from './snackbar';
import smsTypes from './smsTypes';
import emailTypes from './emailTypes';
import infohubsManagement from './infohubManagement';
import notes from './notes'

export default configureStore({
  reducer: {
    auth,
    userManagement,
    infoHub,
    snackbar,
    smsTypes,
    infohubsManagement,
    emailTypes,
    notes
  },
});