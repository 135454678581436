import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Grid,
  CircularProgress,
  Typography,
  CardContent,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";


import { formValidation } from "../../validations";
import { formatAusPhoneNumber } from "../../utils/validatePhoneNumber";
import SMSUserDetails from "../../components/SMSForm/SMSUserDetails";
import SMSPreview from "../../components/SMSForm/SMSPreview";
import generateSmsText from "../../utils/generateSmsText";
import sendSMSService from "../../services/sendSMSService";
import copyToClipboard from "../../utils/copyToClipboard";
import { openSnackbar } from "../../store/snackbar";
import SNACKBAR_TYPES from "../../constants/snackBarTypes";
import { getSmsTypes } from "../../store/smsTypes";
import { makeStyles } from '@material-ui/core/styles';
import styling from './styling';

const validationSchema = yup.object(formValidation());

const useStyles = makeStyles((theme) => ({
  ...styling(theme)
}));

export const validateTypeValues = (types = [], typeValues = []) => {
  let isParentsValid = false;
  if (types.length === 0) {
    isParentsValid = true;
  }
  types.forEach((type, index) => {
    if (!type?.parent && typeValues?.[index]) {
      isParentsValid = true;
    } else if (!type?.parent && !typeValues?.[index]) {
      isParentsValid = false;
    }
  });

  return isParentsValid;
}

function GenerateSMS() {
  const [sms, setSms] = useState("");
  const smsTypes = useSelector((state) => state.smsTypes.allowedTypes);
  const user = useSelector((state) => state.auth.user);
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [isCleared, setIsCleared] = useState(true);
  const [isFetching, setIsFetching] = useState(!smsTypes?.length);
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      phone: "",
      smsType: -1,
      typeValues: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        const response = await sendSMSService({
          phone: formatAusPhoneNumber(values.phone),
          smsTypeId: smsTypes[values.smsType]._id,
          smsTypeName: smsTypes[values.smsType].name,
          userName: user.name,
          userId: user.id,
          userEmail: user.email,
          metadata: { values: values.typeValues, phone: values.phone },
          sms,
        });
        if (!response.status)
          dispatch(
            openSnackbar({
              message: response.message || "There is an error sending SMS",
              type: SNACKBAR_TYPES.ERROR,
            })
          );
        else {
          dispatch(
            openSnackbar({
              message: response.message,
              type: SNACKBAR_TYPES.SUCCESS,
            })
          );
          setIsSendDisabled(true);
          setIsCleared(false);
        }
      }
    },
  });

  const copySMSToClipboard = async () => {
    await copyToClipboard(sms);
    dispatch(
      openSnackbar({
        message: "Copied to clipboard",
        type: SNACKBAR_TYPES.SUCCESS,
      })
    );
  };

  function handleReset() {
    setIsSendDisabled(true);
    setIsCleared(true);
    setSms("");
    formik.handleReset();
  }

  useEffect(() => {
    if (!smsTypes.length) {
      (async () => {
        await getSmsTypes(dispatch);
        setIsFetching(false);
      })();
    }
  }, [dispatch, smsTypes?.length]);

  useEffect(() => {
    if (formik.isValid && validateTypeValues(smsTypes[formik.values.smsType]?.types, formik.values.typeValues)) {
      setSms(
        generateSmsText(
          smsTypes[formik.values.smsType],
          formik.values.typeValues
        )
      );
      if (isCleared && sms) setIsSendDisabled(false);
      else setIsSendDisabled(true);
    } else if (!formik.isValid && !validateTypeValues(smsTypes[formik.values.smsType]?.types, formik.values.typeValues)) {
      setSms("");
      setIsSendDisabled(true);
    }
  }, [formik, isCleared, sms, smsTypes]);

  useEffect(() => {
    if (formik.values.smsType >= 0) {
      formik.setFieldValue(
        "typeValues",
        smsTypes[formik.values.smsType]?.types?.map(type => ""),
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.smsType]);

  return (
    <Grid className={classes.homeContainer}>
      <Grid
        container
        className={classes.fullHeightWidth}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container className={classes.overflowAuto}>
          <form onSubmit={formik.handleSubmit} id="form" className={classes.fullWidth}>
            {isFetching && (
              <Grid
                container
                className={classes.loaderBackground}
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <CircularProgress color="primary" />
                <Typography variant="button" color="primary">
                  Loading SMS Types
                </Typography>
              </Grid>
            )}
            {!isFetching && (
              <Grid className={classes.cardContent}>
                <Grid item container xs={12} md={6}>
                  <CardContent className={classes.cardFormSection}>
                    <SMSUserDetails smsTypes={smsTypes} formik={formik} />
                  </CardContent>
                </Grid>
                <Grid item container xs={12} md={6}>
                  <CardContent className={classes.cardFormSection}>
                    <SMSPreview
                      isSubmitting={formik.isSubmitting}
                      sms={sms}
                      handleReset={handleReset}
                      copySMS={copySMSToClipboard}
                      isSendDisabled={isSendDisabled}
                    />
                  </CardContent>
                </Grid>
              </Grid>
            )}
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GenerateSMS;
