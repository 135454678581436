import { initialState } from '../auth';

const saveUser = (state, action) => {
  state.user = { ...action.payload };
  state.isLoggedIn = true;
}

const logoutUser = (state, action) => {
  const authInitialState = { ...initialState };
  authInitialState.isBrowserRefreshing = false;
  return authInitialState;
}

const forgotPasswordEmail = (state, action) => {
  state.forgotPasswordEmail = action.payload;
}

const changePasswordEmail = (state, action) => {
  state.changePasswordEmail = action.payload;
}

const setIsBrowserRefreshing = (state, action) => {
  state.isBrowserRefreshing = action.payload;
}

const reducers = { saveUser, logoutUser, forgotPasswordEmail, changePasswordEmail, setIsBrowserRefreshing };

export default reducers;
