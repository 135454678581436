import iconNames from './iconNames';
import roleNames from './roleNames';

export const sms = [
  {
    name: 'SMS Platform',
    iconName: iconNames.SMS,
    path: '/sms-platform',
    role: roleNames.SMS,
    description: 'Send reference numbers to older Australians via SMS'
  }
];

export const email = [
  {
    name: 'Email Platform',
    iconName: iconNames.Email,
    path: '/email-platform',
    role: roleNames.EMAIL,
    description: 'Send mail to older Australians via Email'
  }
];

export const notes = [
  {
    name: 'Clear Notes',
    iconName: iconNames.Notes,
    path: '/notes-tool',
    role: roleNames.NOTES,
    description: 'Click to access clear notes'
  }
];

export const adminApps = [
  {
    name: 'User Management',
    iconName: iconNames.ManageAccount,
    path: '/user-management',
    role: roleNames.ADMIN,
    description: 'Manage all the call centre users here'
  },
  {
    name: 'SMS Type',
    iconName: iconNames.SMSManagement,
    path: '/sms-type',
    role: roleNames.ADMIN,
    description: 'Manage templates for SMS'
  },
  {
    name: 'Email Type',
    iconName: iconNames.SMSManagement,
    path: '/email-type',
    role: roleNames.ADMIN,
    description: 'Manage templates for Emails'
  },
  {
    name: 'Info Hub Management',
    iconName: iconNames.TextBoxMultiple,
    path: '/infohub',
    role: roleNames.ADMIN,
    description: 'Manage info hubs'
  },
  {
    name: 'Clear Notes Management',
    iconName: iconNames.Notes,
    path: '/notes',
    role: roleNames.ADMIN,
    description: 'Manage clear notes'
  }
];
