import { initialState } from '../emailTypes';

const emailTypes = (state, action) => {
  state.types = [...action.payload];
}

const allowedEmailTypes = (state, action) => {
  state.allowedTypes = [...action.payload];
}

const resetEmailTypes = (state, action) => {
  return initialState;
}

const reducers = { emailTypes, resetEmailTypes, allowedEmailTypes };

export default reducers;
