import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid, Icon, Typography, CircularProgress, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Notices from '../../assets/icons/notices.svg';
import FAQ from '../../assets/icons/faq.svg';
import Training from '../../assets/icons/training.svg';
import Resources from '../../assets/icons/resources.svg';
import BackToTopButton from '../../components/BackToTopButton';
import styling from './styling';
import articleStyling from './articleStyling';
import { getInfoHubData } from '../../store/info-hub';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import handleInternalLinks from '../../utils/handleInternalLinks';
import { articlesCount } from '../../constants/infoHubHomepage';
import scrollToTop from '../../utils/scrollToTop';
import trackEvents from '../../utils/trackEvents';
import { getInfoHubTitle } from '../../utils/infoHubRoutesProperties';

const useStyles = makeStyles(theme => ({
  heading: styling(theme).heading,
  message: styling(theme).message,
  subHeading: styling(theme).subHeading,
  publishDate: styling(theme).publishDateSpacing,
  articleContent: articleStyling(theme).articleContent,
  spacing: {
    padding: '12px 0px'
  },
  height: {
    height: '100%'
  },
  cardLeft: {
    width: '100%',
    margin: '10px 10px 10px 0px',
    padding: '8px'
  },
  imageRight: {
    textAlign: 'right',
    margin: '20px 0px'
},
  cardRight: {
    width: '100%',
    margin: '10px 0px 10px 10px',
    [theme.breakpoints.down('md')]:{
      margin: '10px 0px 10px 0px',
    },
    padding: '8px'
  },
  topicHeading: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    margin: '2% 0%'
  },
  topicContent: {
    color: theme.palette.common.black,
    fontSize: '0.8rem',
    margin: '20px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    display: 'block',
    fontWeight: '600',
    fontSize: '1rem',
    marginBottom: '20px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  icon: {
    height: '100px',
    width: '100px'
  },

  [theme.breakpoints.down('md')]: {
    icon: {
      height: '70px',
      width: '70px'
    }
  },
  marginAuto: {
    margin:'0 auto'
  }
}));

function InfoHub({ params }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.infoHub[params?.infoHub]?.isLoading);
  const isDataFetched = useSelector(state => state.infoHub[params?.infoHub]?.isDataFetched);
  const notices = useSelector(state => state.infoHub[params?.infoHub]?.fetchedNotices)?.slice(0, articlesCount.notices);
  const faqs = useSelector(state => state.infoHub[params?.infoHub]?.faqs)?.slice(0, articlesCount.faqs);
  const resources = useSelector(state => state.infoHub[params?.infoHub]?.fetchedResources?.slice(0, articlesCount.resources));
  const trainingMaterial = useSelector(state => state.infoHub[params?.infoHub]?.fetchedTrainingMaterial?.slice(0, articlesCount.trainingMaterial));
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
    (async () => {
      if (!isDataFetched) {
        const res = await getInfoHubData(params?.infoHub, dispatch);

        if (!res.status) dispatch(openSnackbar({
          message: 'Failed getting infohub data',
          type: SNACKBAR_TYPES.ERROR
        }));
      }
    })()
  }, [dispatch, isDataFetched, params?.infoHub]);


  useEffect(() => {
    handleInternalLinks(classes.articleContent, history);
  }, [notices, classes.articleContent, history]);

  const routeTo = (path, section) => {
    trackEvents('dashboard_browse_all_click', {
      info_hub_title: getInfoHubTitle(params),
      info_hub_page: 'homepage',
      section
    });
    history.push(`${pathname}/${path}`)
  };

  const trackLinks = (link, title, section) => {
    trackEvents('dashboard_section_link_click', {
      link_url: link,
      title,
      section,
      info_hub_title: getInfoHubTitle(params),
      info_hub_page: 'homepage'
    });
  }

  return (
    <Grid container className={classes.height}>
      {isLoading &&
        <Grid container className={classes.height}>
          <Grid item container justifyContent="center" alignContent="center" className={classes.height} xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      }
      {!isLoading && <Grid container className={classes.spacing}>
        <Grid item container alignContent="flex-start" md={8} xs={12} className={classes.marginAuto}>
          <Card className={classes.cardLeft}>
            <CardContent>
              <Grid container alignContent="flex-start">
                <Grid item xs={10}>
                  <Typography variant="h5" className={classes.heading}>
                    Latest Notices
                  </Typography>
                </Grid>
                {Boolean(!notices?.length) && <Grid container>
                  <Grid item container alignContent="flex-start" xs={10}>
                    <Grid item xs={12} className={classes.spacing}>
                      <Typography className={classes.message}>
                        Welcome! No notices have been added yet.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.spacing}>
                      <Typography variant="body2">
                        The latest notices will be shown here as they are added, so check back soon.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} className={classes.imageRight}>
                    <img src={Notices} className={classes.icon} alt="icon" />
                  </Grid>
                </Grid>}
                {Boolean(notices?.length) && notices.map((notice, index) => (
                  <Grid item container alignContent="flex-start" xs={12} key={notice.title}>
                    <Grid item xs={index === 0 ? 10 : 12} className={classes.spacing}>
                      <Typography className={classes.subHeading}>
                        {notice.title}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.publishDate}>
                        {`Published: ${notice.review_date}`}
                      </Typography>
                    </Grid>
                    {index === 0 && <Grid item xs={2}>
                      <img src={Notices} className={classes.icon} alt="icon" />
                    </Grid>}
                    <Grid item xs={12} className={classes.spacing}>
                      <div className={classes.articleContent} dangerouslySetInnerHTML={{ __html: notice.body }}></div>
                    </Grid>
                    <Grid item xs={12} className={classes.spacing}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}
                {Boolean(notices?.length) && <Grid item container alignContent="flex-start" xs={12}>
                  <Button variant="contained" color="primary" onClick={() => routeTo(`notices`, 'All Notices')}>
                    All Notices
                  </Button>
                </Grid>}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item container alignContent="flex-start" md={4} xs={12} className={classes.marginAuto}>
          <Card className={classes.cardRight}>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.heading}>
                    FAQs
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={8}>
                    {Boolean(!faqs?.length) && <div>
                      <Typography className={classes.topicHeading}>
                        No FAQs yet.
                      </Typography>
                      <Typography className={classes.topicContent}>
                        Check back soon to learn more about common FAQs and how to help older Australians.
                      </Typography>
                    </div>}
                    {Boolean(faqs?.length) && <div>
                      <Typography component="div" className={classes.topicContent} >
                        {faqs.map(
                          el => <Link className={classes.link} to={el?.friendly_url} rel="noreferrer" onClick={() => trackLinks(el?.friendly_url, el.title, 'FAQs')} key={el.title}>
                            {el.title}
                          </Link>
                        )}
                      </Typography>
                      <Button variant="contained" color="primary" onClick={() => routeTo(`faqs`, 'FAQs')}>
                        Browse All
                      </Button>
                    </div>}
                  </Grid>
                  <Grid item xs={4} className={classes.imageRight}>
                    <Icon><img src={FAQ} className={classes.icon} alt="icon"></img></Icon>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.cardRight}>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.heading}>
                    Training Material
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={8}>
                    {Boolean(!trainingMaterial?.length) && <div>
                      <Typography className={classes.topicHeading}>
                        No training material yet.
                      </Typography>
                      <Typography className={classes.topicContent}>
                        Here’s where you can find the latest training material as they get added.
                      </Typography>
                    </div>}
                    {Boolean(trainingMaterial?.length) && <div>
                      <Typography component="div" className={classes.topicContent} >
                        {trainingMaterial.map(
                          el => <a className={classes.link} href={el.link} rel="noreferrer" target="_blank" onClick={() => trackLinks(el.link, el.title, 'Training Material')} key={el.title}>
                            {el.title}
                          </a>
                        )}
                      </Typography>
                      <Button variant="contained" color="primary" onClick={() => routeTo(`training-material`, 'Training Material')}>
                        Browse All
                      </Button>
                    </div>}
                  </Grid>
                  <Grid item xs={4} className={classes.imageRight}>
                    <Icon><img src={Training} className={classes.icon} alt="icon"></img></Icon>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.cardRight}>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.heading}>
                    Resources
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={8}>
                    {Boolean(!resources?.length) && <div>
                      <Typography className={classes.topicHeading}>
                        No resources yet.
                      </Typography>
                      <Typography className={classes.topicContent}>
                        Access resources here as they get added.
                      </Typography>
                    </div>}
                    {Boolean(resources?.length) && <div>
                      <Typography component="div" className={classes.topicContent} >
                        {resources.map(
                          el => <a className={classes.link} href={el.link} rel="noreferrer" target="_blank" onClick={() => trackLinks(el.link, el.title, 'Resources')} key={el.title}>
                            {el.title}
                          </a>
                        )}
                      </Typography>
                      <Button variant="contained" color="primary" onClick={() => routeTo(`resources`, 'Resources')}>
                        Browse All
                      </Button>
                    </div>}
                  </Grid>
                  <Grid item xs={4} className={classes.imageRight}>
                    <Icon><img src={Resources} className={classes.icon} alt="icon"></img></Icon>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} xs={12} className={classes.marginAuto}>
          <BackToTopButton />
        </Grid>
      </Grid>}
    </Grid>
  )
}

export default InfoHub
