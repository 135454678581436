import request from "../utils/request";
import downloadApiResponseFile from "../utils/downloadApiResponseFile";

const UploadUserTemplateEndpoint = `${process.env.REACT_APP_BASE_URL}/upload-users-template`;

const downloadUploadUserTemplate = async () => {
  try {
    const response = await request({
      url: UploadUserTemplateEndpoint,
      method: 'get',
      responseType: 'blob',
      headers: {
        "Accept": "application/ms-excel"
      }
    });
    downloadApiResponseFile(response.data, 'mac_upload_users_template.xlsx');

    return {
      status: true,
      statusCode: response?.status,
      message: 'Template downloaded successfully'
    };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status,
      message: 'Template download failed, please try again'
    };
  }
}

export default downloadUploadUserTemplate;
