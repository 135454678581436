import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  actionButtons: {
    margin: '10px 0px 10px 10px',
  }
})

function TypeActions({ handleDelete, handleAddDependency }) {
  const classes = useStyles();

  return <Grid item container justifyContent="flex-end">
    <Button variant="outlined" color="primary" className={classes.actionButtons} onClick={handleDelete}>Delete</Button>
    {handleAddDependency &&
      <Button variant="contained" color="primary" className={classes.actionButtons} onClick={handleAddDependency}>Add Dependency</Button>}
  </Grid>
}

export default TypeActions;
