const theme = {
  typography: {
    fontFamily: "'Open Sans', sans-serif, Dorado",
  },
  palette: {
    primary: {
      main: '#1e4387',
      light: '#556eb7',
      dark: '#001d59',
      contrastText: '#ffffff'
    },
    error: {
      main: '#bf3d4e',
      light: '#f66f7a',
      dark: '#890026'
    },
    primaryButton: {
      900: '#1e4387',
      800: '#295fa7',
      700: '#3070b9',
      600: '#3881cb',
      500: '#3e8ed8',
      400: '#539edd',
      300: '#6eafe2',
      200: '#94c5eb',
      100: '#bcdbf3',
      50: '#e3f1fa'
    },
    secondaryButton: {
      900: '#42175f',
      800: '#5c1f71',
      700: '#6b237a',
      600: '#7b2983',
      500: '#872d88',
      400: '#984799',
      300: '#aa65aa',
      200: '#c28fc3',
      100: '#dabbda',
      50: '#f0e4f0'
    },
    header: {
      900: '#0d2247',
      800: '#14325f',
      700: '#1b3b6b',
      600: '#234476',
      500: '#2a4c7e',
      400: '#4c648e',
      300: '#6b7fa0',
      200: '#93a2bc',
      100: '#bdc7d7',
      50: '#e5e8ee'
    },
    textColor: {
      900: '#282828',
      800: '#4a4a4a',
      700: '#696969',
      600: '#a7a7a7',
      500: '#a7a7a7',
      400: '#c6c6c6',
      300: '#e8e8e8',
      200: '#f2f2f2',
      100: '#f6f6f6',
      50: '#fbfbfb',
    }
  }
}

export default theme;
