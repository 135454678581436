import {initialState} from './index'


const addNotes = (state, action) => {
    state.notesTemplate = action.payload || {};
}

const setNotesContent = (state, action) => {
    state.notesContent = action.payload || {};
}

const resetNotes = (state, action) => {
    return initialState
}

const reducer = {addNotes, resetNotes, setNotesContent}
export default reducer