const styling = (theme) => ({
  fullWidth: {
    width: '100%',
  },
  cardFormSection: {
    width: '100%',
    height: '98%',
  },
  boxShadowBox: {
    width: "100%",
    margin: "auto",
  },
  loaderBackground: {
    width: "83%",
    height: "65vh",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    [theme.breakpoints.up('lg')]: {
      padding: '32px'
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      height: "73vh",
      padding: '25px'
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
      padding: '20px'
    },
  },
  fullHeightWidth: {
    width: "83%",
    display: "flex",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16) !important",
    borderRadius: "4px",
    background: "#fff",
    margin: "auto",
  },
  overflowAuto: {
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
      justifyContent: "center",
    },
  },
  homeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: "0%",
      margin: '0px',
      height: '100%'
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3% 0%",
    },
  },
})

export default styling;