import request from "../utils/request";

const smsReportEndPoint = `${process.env.REACT_APP_BASE_URL}/sms-report`;

const downloadSmsReport = async ({ smsTypes, from, to }) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await request({
      url: smsReportEndPoint,
      method: 'post',
      data: {
        timezone,
        smsTypes,
        from,
        to
      },
      headers: {
        "Accept": "application/ms-excel"
      }
    });
    return {
      status: true,
      statusCode: response?.status,
      message: 'Report generation started successfully',
      data: response.data?.taskId,
    };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status,
      message: 'Report generation failed'
    };
  }
}

export default downloadSmsReport;
