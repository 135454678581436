import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackToTopButton from '../../components/BackToTopButton';
import Training from '../../assets/icons/training.svg';
import InfoHubEmptyState from './InfoHubEmptyState';
import styling from './styling';
import { getInfoHubData } from '../../store/info-hub';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import scrollToTop from '../../utils/scrollToTop';
import trackEvents from '../../utils/trackEvents';
import { getInfoHubTitle } from '../../utils/infoHubRoutesProperties';

const useStyles = makeStyles(theme => ({
  ...styling(theme),
  tableContainer: {
    maxHeight: '100%',
    margin: '2% 0%'
  },
  tableRow: {
    background: theme.palette.common.white
  },
  tableHeading: {
    fontWeight: '600'
  },
  resourceLink: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  height: {
    height: '100%'
  }
}));


function InfoHubTrainingMaterial({ params }) {
  const classes = useStyles();

  const isLoading = useSelector(state => state.infoHub[params?.infoHub]?.isLoading);
  const isDataFetched = useSelector(state => state.infoHub[params?.infoHub]?.isDataFetched);
  const trainingMaterial = useSelector(state => state.infoHub[params?.infoHub]?.fetchedTrainingMaterial);
  const dispatch = useDispatch();

  useEffect(() => {
    scrollToTop();
    (async () => {
      if (!isDataFetched) {
        const res = await getInfoHubData(params?.infoHub, dispatch);
        if (!res.status) dispatch(openSnackbar({
          message: 'Failed getting info hub data',
          type: SNACKBAR_TYPES.ERROR
        }));
      }
    })()
  }, [dispatch, params, isDataFetched]);

  const handleLinkClick = (link, title) => trackEvents("training_material_link_click", {
    title,
    link_url: link,
    info_hub_title: getInfoHubTitle(params),
    info_hub_page: 'Training Material'
  });

  return (
    <Grid container alignContent="flex-start" className={classes.height}>
      {isLoading &&
        <Grid container className={classes.height}>
          <Grid item container justifyContent="center" alignContent="center" className={classes.height} xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      }
      {!isLoading && <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.heading}>Training Material</Typography>
        </Grid>
        <Grid item xs={12}>
          {Boolean(!trainingMaterial?.length) && <InfoHubEmptyState
            img={Training}
            heading="Welcome! No training material has been added yet."
            subHeading="Here’s where you can find the latest training material as they get added. Please check back at a later time."
          />}
          {Boolean(trainingMaterial?.length) && <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeading}>Documents</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trainingMaterial.map((data, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell>
                    <a
                        className={classes.resourceLink}
                        href={data.link}
                        rel="noreferrer"
                        target="_blank"
                        onClick={() => handleLinkClick(data.link, data.title)}
                      >
                      {data.title}
                    </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
        </Grid>
        <Grid item xs={12}>
          <BackToTopButton />
        </Grid>
      </Grid>}
    </Grid>
  )
}

export default InfoHubTrainingMaterial;
