async function generateImageDataUrl(image) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onloadend = () => {
      resolve(fr.result)
    };
    fr.onerror = reject;
    fr.readAsDataURL(image);
  });
}

export default generateImageDataUrl;
