const AppsCardItemsStyling = (theme) => ({
    card: {
        height: '100%',
        '& .MuiCardHeader-root': {
          color: theme.palette.secondaryButton[900],
          '& .MuiCardHeader-avatar':{
            [theme.breakpoints.down('md')]: {
              marginRight:'8px'
    
            },
            [theme.breakpoints.up('lg')]: {
              marginRight:'16px'
            },
          },
          '& .MuiCardHeader-content': {
            '& .MuiTypography-root.MuiCardHeader-title': {
              fontWeight: 'bold',
              [theme.breakpoints.up('lg')]: {
                fontSize: '1rem',
              },
            }
          },
        },
        '& .MuiCardContent-root': {
          fontSize: '1rem',
          [theme.breakpoints.down('md')]: {
            lineHeight: '1',
          },
          [theme.breakpoints.up('lg')]: {
            lineHeight: '1.3',
          },
        }
      },
    descriptionTitle:{
          fontSize: '1rem',
      },
      cardBox:{
          padding: '20px'
       },
      svgIconWidth:{
        [theme.breakpoints.down('md')]: {
          width:'16px',
          display:'flex' 
       },
       [theme.breakpoints.up('lg')]: {
         width: '24px',
         display:'flex'
       },
      },
      cardHeaderPadding:{
         padding: '16px'
      } 
})

export default AppsCardItemsStyling