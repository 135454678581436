import { createSlice } from '@reduxjs/toolkit';
import infoHubService from '../../services/infoHubService';
import infoHubReducer from './infoHubReducer';
import parseInfoHubData from '../../utils/parseInfoHubData';

export const initialState = {
  article: null,
  searchData: [],
  searchValue: '',
  loading: false,
  faqs: [],
};

export const infoHubSlice = createSlice({
  name: 'infoHub',
  initialState,
  reducers: { ...infoHubReducer },
});

export const { searchInfo, setArticle, setSearchValue, setFAQ, setInfoHubData, resetInfoHub } = infoHubSlice.actions;

export const searchTerm = (query) => async (dispatch) => {
  try {
    const response = await infoHubService.searchContent({ searchTerm: query, start: 0, rowsPerPage: 50 });
    dispatch(searchInfo(response?.contents?.docs));
    return { status: true, message: "Search got successful" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const getArticle = (id) => async (dispatch) => {
  try {
    const response = await infoHubService.fetchContentById({ id });
    if (response?.status === false) throw response.message;
    dispatch(setArticle(response.items[0].fields));
    return { status: true, message: "Article fetched successfully" };
  } catch (error) {
    return { status: false, message: error };
  }
}

export const getFAQ = () => async ({ friendlyURL, infoHub, index, dispatch }) => {
  try {
    const response = await infoHubService.fetchContentById({ id: friendlyURL });
    if (response?.status === false) throw response.message;
    dispatch(setFAQ({ index, infoHub, response: response?.items[0].fields.body }));
    return { status: true, message: "FAQs fetched successfully" };
  } catch (error) {
    return { status: false, message: error };
  }
}

export const getInfoHubData = async (infoHub, dispatch) => {
  try {
    dispatch(setInfoHubData({ infoHub, data: { isLoading: true } }));
    const { response } = await infoHubService.fetchAllFriendlyUrls();
    if (!response) {
      dispatch(setInfoHubData({ infoHub, data: { isLoading: false, isDataFetched: true } }));
      return { status: false, message: 'Failed fetching data' };
    }
    const infoHubData = response?.docs?.filter(el => el?.friendly_url.startsWith(`${infoHub}/`)) || [];
    dispatch(setInfoHubData({ infoHub, data: await parseInfoHubData(infoHub, infoHubData) }));
    return { status: true, message: 'Data fetched successfully' };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export default infoHubSlice.reducer;
