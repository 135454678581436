const handleClick = (event, history) => {
  const link = event.path[0]?.getAttribute('href') || '#';

  if (link.indexOf('http') === -1) {
    event.preventDefault();
    history.push(link);
  }
}

const handleInternalLinks = (articleContentClass, history) => {
  const aTags = document.querySelectorAll(`div.${articleContentClass} a`);

  aTags.forEach(a => {
    a.onclick = (event) => handleClick(event, history);
    a.target = '_blank';
  });
}

export default handleInternalLinks;
