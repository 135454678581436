import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from './SvgIcons';
import iconNames from '../constants/iconNames';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#42175f',
    '&:hover': {
      backgroundColor: '#1c0035',
    },
  },
}))(Button);

function CopyButton({ copy, disabled, title }) {
  return (
    <ColorButton variant="contained" disabled={disabled} onClick={copy}
      startIcon={<SvgIcon iconName={iconNames.Copy} />}
    >
      {title || 'Copy'}
    </ColorButton>
  )
}

export default CopyButton;
