import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Warning, GetApp } from '@material-ui/icons';
import { exportFailedUsersAsCsv } from '../../store/userManagement';

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: '3%',
    maxHeight: '500px'
  },
  tableHeading: {
    background: theme.palette.primary.main,
    '& > .MuiTypography-root.MuiTypography-body1': {
      fontWeight: 'bold',
      color: theme.palette.common.white,
    }
  },
  tableHead: {
    background: '#eaeaea'
  },
  tableBody: {
    background: '#f6f6f6'
  },
  alertIcon: {
    marginRight: '10px',
    color: theme.palette.error.main
  },
  heading: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  dialogAction: {
    padding: '16px',
    '& > :not(:first-child)': {
      marginLeft: '24px'
    }
  }
}));

function StatusDialog({ open, handleClose, response }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [maxWidth, setMaxWidth] = useState('md');
  const classes = useStyles();

  useEffect(() => {
    setIsSuccess(response?.status);
    if (response?.status) setMaxWidth('md');
    else setMaxWidth('xs');
  }, [response?.status])

  const getFailedUsers = () => {
    if (response?.data) {
      return response?.data?.filter?.(el => el?.success === false);
    } else {
      return []
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle disableTypography>
        <Grid container alignItems="center">
          <Warning className={classes.alertIcon} />
          <Typography variant="h6" className={classes.heading}>Updates failed</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {
          !isSuccess && <DialogContentText component="div">
            <Typography variant="body2" color="textPrimary" component="div">
              {response?.message?.errorMessage || response?.message?.message}
            </Typography>
          </DialogContentText>
        }
        {
          isSuccess && !!getFailedUsers()?.length && <DialogContentText component="div">
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" color="textPrimary" component="div">
                Some records could not be added/updated. Refer to the list below for details..
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer className={classes.table}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeading} colSpan={3}>
                          <Typography>
                            Items with errors
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>Name</TableCell>
                        <TableCell className={classes.tableHead}>Email</TableCell>
                        <TableCell className={classes.tableHead}>Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getFailedUsers()?.map(el => (
                        <TableRow key={el?.user?.email} className={classes.tableBody}>
                          <TableCell>{el?.user?.name}</TableCell>
                          <TableCell>{el?.user?.email}</TableCell>
                          <TableCell>{el?.errorMessage}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button onClick={handleClose} size="large" variant="outlined" color="primary">
          Close
        </Button>
        {
          Boolean(getFailedUsers()?.length) && <Button
            variant="contained"
            startIcon={<GetApp />}
            color="primary"
            size="large"
            onClick={() => exportFailedUsersAsCsv(getFailedUsers())}
          >
            Download List
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default StatusDialog;
