import request from "../utils/request";
const TaskServiceEndPoint = `${process.env.REACT_APP_BASE_URL}/tasks/`;

const getTaskByIdService = async (id) => {
  try {
    const response = await request({
      url: `${TaskServiceEndPoint}${id}`,
      method: 'get'
    });
    return { status: true, data: response?.data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status,
      message: error?.response?.data || error.message
    };
  }
}

export default getTaskByIdService;
