import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, IconButton, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '../SvgIcons';
import iconNames from '../../constants/iconNames';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '80vh',
    position: 'relative'
  },
  cardContent: {
    height: '100%',
    width: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  crossIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 20,
  },
  heading: {
    maxHeight: '40%',
    padding: 10,
    width: '100%',
    boxShadow: '0px 2.9px 3.9px hsl(0deg 0% 0% / 0.46)',
    zIndex: 10,
    overflow: 'auto'
  },
  content: {
    padding: 10,
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1
  },
  name: {
    padding: '10px',
    fontWeight: 'bold',
    color: theme.palette.secondaryButton[900]
  },
  headingText: {
    fontWeight: '600',
    marginLeft: 10,
    color: theme.palette.secondaryButton[900]
  },
  rolesContainer: {
    overflow: 'auto',
  },
  permissionsContainer: {
    margin: '5px 0px 5px 10px',
    width: '100%'
  },
  permissionTypeContainer: {
    marginBottom: '10px',
    marginRight: 5
  },
  chip: {
    marginLeft: 5,
    marginTop: 5
  }
}));

function UserInfoCard({ selectedUser, setSelectedUser }) {
  const classes = useStyles();
  const [showPermissions, setShowPermissions] = useState(false);
  const [smsPermissions, setSmsPermissions] = useState([]);
  const [emailPermissions, setEmailPermissions] = useState([]);

  useEffect(() => {
    if (selectedUser.roles?.findIndex(role => role.role === 'ADMIN') >= 0) {
      setShowPermissions(true)
      setSmsPermissions(['ALL']);
      return setEmailPermissions(['ALL']);
    }
    setShowPermissions(!!selectedUser?.smsPermissions || !!selectedUser?.emailPermissions);
    if (!!selectedUser?.smsPermissions) {
      setSmsPermissions(selectedUser?.smsPermissions);
    }
    if (!!selectedUser?.emailPermissions) {
      setEmailPermissions(selectedUser?.emailPermissions);
    }
  }, [selectedUser]);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <IconButton className={classes.crossIcon} onClick={() => setSelectedUser(null)}>
          <SvgIcon iconName={iconNames.Close} />
        </IconButton>
        <Grid container className={classes.heading}>
          <Grid item>
            <Typography variant="h5" className={classes.name}>{selectedUser.name}</Typography>
          </Grid>
          <Grid container>
            <Grid item><Typography variant="h6" className={classes.headingText}>Roles</Typography></Grid>
            <Grid container item className={classes.rolesContainer}>
              {selectedUser.roles?.map(role => <div key={role.role} className={classes.chip}>
                <Chip
                  label={role?.role?.trim()}
                  color="primary"
                  size="small"
                  variant="outlined"
                />
              </div>)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.content}>
          {
            showPermissions && <Grid container direction="column">
              <Grid item><Typography variant="h6" className={classes.headingText}>Permissions</Typography></Grid>
              <Grid container item className={classes.permissionsContainer}>
                {!!smsPermissions?.length && (
                  <>
                    <Grid item><Typography variant="button" color="textSecondary">SMS Types</Typography></Grid>
                    <Grid container item className={classes.permissionTypeContainer}>
                      {smsPermissions.map(permission => <div key={permission} className={classes.chip}>
                        <Chip
                          label={permission}
                          color="primary"
                          size="small"
                          variant="outlined"
                        />
                      </div>)}
                    </Grid>
                  </>
                )}
                {!!emailPermissions?.length && (
                  <>
                    <Grid item><Typography variant="button" color="textSecondary">Email Types</Typography></Grid>
                    <Grid container item className={classes.permissionTypeContainer}>
                      {emailPermissions.map(permission => <div key={permission} className={classes.chip}>
                        <Chip
                          label={permission}
                          color="primary"
                          size="small"
                          variant="outlined"
                        />
                      </div>)}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default UserInfoCard;
