import React, { useEffect, useState } from 'react';
import { Grid, TextField, makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import SvgIcon from '../SvgIcons';
import iconNames from '../../constants/iconNames';
import { styling } from './styling';
import TypeHeader from './TypeHeader';
import TypeActions from './TypeActions';
import DependentOnContainer from './DependentOnContainer';

const useStyles = makeStyles(theme => ({
  ...styling(theme),
  inputContainer: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  columnPadding: {
    [theme.breakpoints.up('sm')]:{
      padding: '0px 10px 0px 0px'
    },
    [theme.breakpoints.down('sm')]:{
      padding: '0px 0px 0px 0px'
    } 
  },
}) )

function SMSTypeText({
  id,
  heading,
  label,
  onLabelChange,
  placeholder,
  onPlaceholderChange,
  reference,
  onReferenceChange,
  handleDelete,
  updateError,
  types,
  setTags,
  parent,
  expandAll,
  updateSubReason
}) {
  const [referenceError, updateReferenceError] = useState('');
  const [labelError, updateLabelError] = useState('');
  const [expanded, setExpanded] = useState(true);
  const validate = () => {
    updateReferenceError(!!reference ? '' : 'Reference is required');
    updateLabelError(!!label ? '' : 'Label is required');
    const isExisting = types?.filter(type => reference && type?.reference === reference).length > 1
    if (isExisting) {
      updateReferenceError('Same reference already exists');
    }
    if (!!reference && !isExisting && !!setTags) {
      const tags = types.filter(type => !!type.reference)
      setTags(tags)
    }
  };
  const classes = useStyles();
  useEffect(() => {
    if (updateError) {
      updateError(!reference || !label);
    }
  }, [label, reference, updateError]);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  const toggleExpanded = () => setExpanded(prev => !prev);

  const parentTypeData =  parent && types?.find(item => item.id === parent)

  const parentOptionList = parentTypeData?.options;

  const optionsSelected = parentTypeData?.dependant?.find(itm => itm.child === id)?.criteria;

  const updateParentCriteria = (optionId, checked) => {
    updateSubReason(parent, id, optionId, checked)
  }

  return <Accordion expanded={expanded} onChange={toggleExpanded} square className={classes.accordion}>
    <AccordionSummary expandIcon={<SvgIcon iconName={iconNames.ChevronDown} />}>
      <TypeHeader
        title={reference}
        type={heading}
        parent={parent}
        types={types}
      />
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2} direction="column">
        <Grid item container>
        <Grid item xs={12} md={4} className={classes.columnPadding}>
          <TextField
            label="Reference*"
            variant="standard"
            size="small"
            value={reference}
            onChange={onReferenceChange}
            onBlur={validate}
            error={!!referenceError}
            helperText={referenceError}
            className={classes.smsTypeInput}
          />
          </Grid>
          <Grid item xs={12} md={4} className={classes.columnPadding}>
          <TextField
            variant="standard"
            size="small"
            label="Label*"
            value={label}
            onBlur={validate}
            error={!!labelError}
            helperText={labelError}
            onChange={onLabelChange}
            className={classes.smsTypeInput}
          />
          </Grid>
          <Grid item xs={12} md={4}>
          <TextField
            variant="standard"
            size="small"
            label="Placeholder"
            value={placeholder}
            onChange={onPlaceholderChange}
            className={classes.smsTypeInput}
          />
          </Grid>
        </Grid>
        {parent && <DependentOnContainer parentOptionList={parentOptionList} currentDependencyList={optionsSelected} handleOptionCheck={updateParentCriteria}></DependentOnContainer>}
        <TypeActions handleDelete={handleDelete} />
      </Grid>
    </AccordionDetails>
  </Accordion>
}

export default SMSTypeText;
