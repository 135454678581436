import React from 'react';
import { Grid, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import SvgIcon from '../SvgIcons';
import uuidGenerator from '../../utils/uuidGenerator';

const useStyles = makeStyles({
  iconButtons: {
    padding: '0px 12px',
  }
});

function TypesToolbarButtons({ buttons, onClick }) {
  const classes = useStyles();

  return <Grid container alignItems="center">
    {
      buttons?.map((button, index) =>
        <div key={index} className={classes.iconButtons}>
          <Tooltip title={button.label} placement="bottom">
            <IconButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => onClick({ ...button.data, id: uuidGenerator() })}
            >
              <SvgIcon iconName={button.icon} />
            </IconButton>
          </Tooltip>
        </div>)
    }
  </Grid>
}

export default TypesToolbarButtons;
