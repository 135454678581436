import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

const validateNumber = (number) => {
  try {
    // Regular expression pattern for Australian phone numbers
    const regex = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;
    // Test the input phoneNumber against the regex pattern
    return regex.test(number);
  } catch (error) {
    return false;
  }
};

export const formatAusPhoneNumber = (number) => {
  try {
    const parsedNumber = phoneUtil.parse(number, "AU");
    return `+${parsedNumber.getCountryCode()}${parsedNumber.getNationalNumber()}`;
  } catch (error) {
    return null;
  }
};

export default validateNumber;
