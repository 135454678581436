import { initialState } from './';

const setInfohubs = (state, action) => {
  state.infohubs = [...action.payload];
}

const setInfohubRoutes = (state, action) => {
  state.infohubRoutes = [...action.payload];
}

const resetInfohubs = (state, action) => {
  return initialState;
}

const reducers = { setInfohubs, setInfohubRoutes, resetInfohubs };

export default reducers;
