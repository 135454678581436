import { createSlice } from '@reduxjs/toolkit';
import infohubManagementReducer from './infohubManagementReducer';
import {
  fetchInfohub,
  createInfohub as createInfohubService,
  updateInfohub as updateInfohubService,
  deleteInfohub as deleteInfohubService
} from '../../services/infohubManagement';
import capitalizeFirstLetter from '../../utils/capitaliseFirstLetter';

export const initialState = {
  infohubs: [],
};

export const infohubsSlice = createSlice({
  name: 'infohubsManagement',
  initialState,
  reducers: { ...infohubManagementReducer },
});

export const { setInfohubs, resetInfohubs } = infohubsSlice.actions;

export const getInfohubs = async (dispatch) => {
  try {
    const data = await fetchInfohub();
    if (!data?.status) throw data;

    dispatch(setInfohubs(data.data?.map(item => ({ ...item, name: capitalizeFirstLetter(item.name) })) || []));
    return { status: true, message: "Infohubs fetched successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const createInfohub = async (payload) => {
  try {
    const data = await createInfohubService(payload);
    if (!data?.status) throw data;

    return { status: true, message: "Infohub created successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const updateInfohub = async (payload) => {
  try {
    const data = await updateInfohubService(payload);
    if (!data?.status) throw data;

    return { status: true, message: "Infohub updated successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const deleteInfohub = async (infohubRole) => {
  try {
    const data = await deleteInfohubService({ infohubRole });
    if (!data?.status) throw data;

    return { status: true, message: "Infohub deleted successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export default infohubsSlice.reducer;
