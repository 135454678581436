import React, { useRef } from 'react';
import { Grid, TextField, makeStyles, Chip, Box } from '@material-ui/core';

import SvgIcon from '../SvgIcons';
import iconNames from '../../constants/iconNames';
import styling from '../SMSForm/styling';

const useStyles = makeStyles(theme => ({
  ...styling(theme),
  tagsContainer: {
    maxHeight: '100%',
    width: '98%',
    margin: '0 auto',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  chips: {
    marginRight: 5,
    marginBottom: 5,
    display: 'inline-block'
  },
}));

function SMSTemplatePreview({ smsTemplate, setSmsTemplate, tags = [], title }) {
  const classes = useStyles();
  const inputRef = useRef();

  const handleChipClick = (referenceWord) => {
    const textToInsert = `{{${referenceWord}}}`;
    const cursorPosition = document.getElementById("smsType_preview")?.selectionStart;
    if (cursorPosition === 0) {
      return setSmsTemplate(textToInsert);
    }
    const text = `${smsTemplate.slice(0, cursorPosition)}${textToInsert}${smsTemplate.slice(cursorPosition)}`;
    setSmsTemplate(text);
    if (inputRef.current) {
      inputRef.current?.focus();
      const position = cursorPosition + textToInsert.length;
      inputRef.current?.setSelectionRange(position, position);
    }
  }

  return <Grid container>
    <Grid container item>
      <Box className={classes.tagsContainer}>
        {tags.filter(tag => !!tag.reference).map(tag => {
          const handleClick = () => handleChipClick(tag.reference)
          return <Box className={classes.chips} key={tag.reference}>
            <Chip
              key={tag.reference}
              label={tag.reference}
              onClick={handleClick}
              onDelete={handleClick}
              deleteIcon={<SvgIcon iconName={iconNames.Plus} fontSize='small' />}
              variant="outlined"
            />
          </Box>
        })}
      </Box>
    </Grid>
    <Grid item container >
      <TextField
        id='smsType_preview'
        inputRef={inputRef}
        label=""
        variant="filled"
        value={smsTemplate}
        onChange={(e) => setSmsTemplate(e.target.value)}
        className={classes.smsPreview}
        fullWidth
        multiline
        minRows={11}
        maxRows={11}
      />
    </Grid>
  </Grid>
}

export default SMSTemplatePreview;