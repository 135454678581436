import iconNames from '../constants/iconNames';
import store from '../store';

const getInfoHub = (params) => store.getState().infohubsManagement.infohubs.find(el => el.link === params?.infoHub);

export const getInfoHubTitle = (params) => getInfoHub(params)?.name;

export const getInfoHubHeader = (params) => getInfoHub(params)?.name;

export const getInfoHubRoleName = (params) => getInfoHub(params)?.role;

export const getInfoHubIcons = (params) => getInfoHub(params)?.icon || iconNames.Book;
