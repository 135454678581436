import React, { useState, useCallback, useRef } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  Chip,
  Box,
  Switch
} from "@material-ui/core";

import SvgIcon from "../SvgIcons";
import iconNames from "../../constants/iconNames";
import styling from "../SMSForm/styling";
import { Editor } from "@tinymce/tinymce-react";
import EmailChipInput from "./EmailChipInput";
import validateEmailAddress from "../../utils/validateEmail";

const useStyles = makeStyles((theme) => ({
  ...styling(theme),
  tagsContainer: {
    maxHeight: 100,
    width: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    margin: '15px 0px',
  },
  templatePreviewHeight: {
    height: '90%',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  chips: {
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 10,
    display: "inline-block",
  },
  inputs: {
    marginBottom: "10px",
  },
  titleContainer: {
    padding: '4px 2px'
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  switchText: {
    margin: '0px 5px'
  }
}));

function EmailTemplatePreview({
  emailTemplate,
  setEmailTemplate,
  emailTypeSubject,
  updateEmailTypeSubject,
  to = [],
  setTo,
  cc = [],
  setCc,
  updateError,
  tags = [],
  isToEditable,
  setIsToEditable,
  isCcEditable,
  setIsCcEditable,
  canRemovePreFilledCcEmails,
  setCanRemovePreFilledCcEmails,
  canRemovePreFilledToEmails,
  setCanRemovePreFilledToEmails,
}) {
  const classes = useStyles();
  const [emailTypeSubjectError, updateEmailTypeSubjectError] = useState("");
  const [emailTypeTemplateError, updateEmailTypeTemplateError] = useState("");
  const [toEmailsError, updateToEmailsError] = useState("");
  const [ccEmailsError, updateCcEmailsError] = useState("");
  const editorRef = useRef(null);
  const handleChipClick = (referenceWord) => {
    const textToInsert = `{{${referenceWord}}}`;
    editorRef.current.insertContent(textToInsert);
  };
  const validateSubject = useCallback(() => {
    const isEmailTypeSubjectValid = !!emailTypeSubject;
    updateEmailTypeSubjectError(
      isEmailTypeSubjectValid ? "" : "Email subject is required"
    );
    if (updateError) {
      updateError(!isEmailTypeSubjectValid);
    }
    return isEmailTypeSubjectValid;
  }, [emailTypeSubject, updateError]);

  const validateTemplate = useCallback(() => {
    const isEmailTypeTemplateValid = !!emailTemplate;
    updateEmailTypeTemplateError(
      isEmailTypeTemplateValid ? "" : "Email body is required"
    );
    if (updateError) {
      updateError(!isEmailTypeTemplateValid);
    }
    return isEmailTypeTemplateValid;
  }, [emailTemplate, updateError]);

  const validateToEmails = useCallback((data = []) => {
    console.log(data, to);
    const isToEmailsValid = (Array.isArray(data) ? data : to).every(email => validateEmailAddress(email));
    updateToEmailsError(
      isToEmailsValid ? "" : "Some of the emails are not valid"
    );
    if (updateError) {
      updateError(!isToEmailsValid);
    }
    return isToEmailsValid;
  }, [to, updateError]);

  const validateCcEmails = useCallback((data = []) => {
    const isToEmailsValid = (Array.isArray(data) ? data : cc).every(email => validateEmailAddress(email));
    updateCcEmailsError(
      isToEmailsValid ? "" : "Some of the emails are not valid"
    );
    if (updateError) {
      updateError(!isToEmailsValid);
    }
    return isToEmailsValid;
  }, [cc, updateError]);

  const handleSubjectChange = ({ target: { value } }) => {
    updateEmailTypeSubject(value);
    if (value?.trim()?.length && emailTypeSubjectError !== "") {
      updateEmailTypeSubjectError("");
    }
  };

  const handleTemplateChange = (value) => {
    setEmailTemplate(value);
    if (value?.trim()?.length && emailTypeTemplateError !== "") {
      updateEmailTypeTemplateError("");
    }
  };

  const onAddTo = (data = []) => {
    setTo(prev => [...prev, ...data]);
    validateToEmails(data);
  };

  const onAddCc = (data = []) => {
    setCc(prev => [...prev, ...data]);
    validateCcEmails(data);
  };

  const handleDeleteChipTo = (emailToBeDeleted) => {
    const newValues = to?.filter((email) => email !== emailToBeDeleted)
    setTo(newValues);
    validateToEmails(newValues);
  }

  const handleDeleteChipCc = (emailToBeDeleted) => {
    const newValues = cc?.filter((email) => email !== emailToBeDeleted)
    setCc(newValues);
    validateCcEmails(newValues);
  }


  return (
    <Grid container className={classes.templatePreviewHeight}>
      <Grid container spacing={2}>
        <Grid item container justifyContent="center" xs={12}>
          <EmailChipInput
            value={to}
            onAdd={onAddTo}
            onDelete={handleDeleteChipTo}
            label="TO"
            onBlur={validateToEmails}
            error={!!toEmailsError}
            helperText={toEmailsError}
          />
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item className={classes.switchContainer}>
            <Switch
              checked={isToEditable || false}
              onChange={(_, checked) => setIsToEditable(checked)}
              color="primary"
              label="User can add emails"
              inputProps={{ "aria-label": "primary checkbox" }}
              size="small"
            />
            <span className={classes.switchText}>User can add emails</span>
          </Grid>
          <Grid item className={classes.switchContainer}>
            <Switch
              checked={canRemovePreFilledToEmails || false}
              onChange={(_, checked) => setCanRemovePreFilledToEmails(checked)}
              color="primary"
              label="User can remove pre-filled emails"
              inputProps={{ "aria-label": "primary checkbox" }}
              size="small"
            />
            <span className={classes.switchText}>User can remove pre-filled emails</span>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <EmailChipInput
            value={cc}
            onAdd={onAddCc}
            onDelete={handleDeleteChipCc}
            label="CC"
            onBlur={validateCcEmails}
            error={!!ccEmailsError}
            helperText={ccEmailsError}
          />
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item className={classes.switchContainer}>
            <Switch
              checked={isCcEditable || false}
              onChange={(_, checked) => setIsCcEditable(checked)}
              color="primary"
              label="User can add emails"
              inputProps={{ "aria-label": "primary checkbox" }}
              size="small"
            />
            <span className={classes.switchText}>User can add emails</span>
          </Grid>
          <Grid item className={classes.switchContainer}>
            <Switch
              checked={canRemovePreFilledCcEmails || false}
              onChange={(_, checked) => setCanRemovePreFilledCcEmails(checked)}
              color="primary"
              label="User can remove pre-filled emails"
              inputProps={{ "aria-label": "primary checkbox" }}
              size="small"
            />
            <span className={classes.switchText}>User can remove pre-filled emails</span>
          </Grid>
        </Grid>
        <Grid container item>
          <TextField
            label="Subject"
            variant="standard"
            size="small"
            fullWidth
            value={emailTypeSubject}
            onChange={handleSubjectChange}
            onBlur={validateSubject}
            error={!!emailTypeSubjectError}
            helperText={emailTypeSubjectError}
            className={classes.inputs}
          />
        </Grid>
        {!!tags?.length && (
          <Box className={classes.tagsContainer}>
            {tags.map((tag) => {
              const handleClick = () => handleChipClick(tag.reference);
              return (
                <Box className={classes.chips} key={tag.reference}>
                  <Chip
                    key={tag.reference}
                    label={tag.reference}
                    onClick={handleClick}
                    onDelete={handleClick}
                    deleteIcon={
                      <SvgIcon iconName={iconNames.Plus} fontSize="small" />
                    }
                    variant="outlined"
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </Grid>
      <Grid container className={classes.previewContainer}>
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          className="tinymce_preview"
          init={{
            menubar: false,
            branding: false,
            statusbar: false,
            min_height: 300,
            width: "100%",
            resize: false,
            toolbar_mode: "sliding",
            toolbar:
              "bold italic underline | fontsize | forecolor | preview fullscreen | " +
              "alignleft aligncenter alignright alignjustify | bullist numlist | link image table | " +
              "blocks | fontfamily | code | removeformat",
            font_size_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
            plugins: [
              "link",
              "image",
              "lists",
              "preview",
              "code",
              "fullscreen",
              "table",
            ],
          }}
          onInit={(_evt, editor) => (editorRef.current = editor)}
          onEditorChange={handleTemplateChange}
          value={emailTemplate}
          onBlur={validateTemplate}
        />
        <Typography variant="caption" color="error">
          {emailTypeTemplateError}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default EmailTemplatePreview;
