import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { sms, email, notes, adminApps } from '../../constants/appsList';
import { useSelector } from 'react-redux';
import AppsCardItems from '../../components/AppsCardItems';
import hideHeadingOnEmptyRoutes from '../../utils/hideHeadingsOnEmptyRoutes';
import './HomePage.scss';

const useStyles = makeStyles({
  appContainer: {
    marginLeft: '-20px'
  }
});

function HomePage() {
  const groups = useSelector((state) => state.auth?.user?.groups);
  const infohubs = useSelector((state) => state.infohubsManagement.infohubs);
  const classes = useStyles();

  return (
    <Grid container alignContent="flex-start" className="apps-container">
      {(hideHeadingOnEmptyRoutes(groups, adminApps) && <Grid item xs={12} className="apps-heading">
        <Typography variant="h5">
          Admin
        </Typography>
      </Grid>)}
      <Grid item container xs={12} className={classes.appContainer}>
        <AppsCardItems apps={adminApps} />
      </Grid>
      {(hideHeadingOnEmptyRoutes(groups, sms) && <Grid item xs={12} className="apps-heading">
        <Typography variant="h5">
          SMS
        </Typography>
      </Grid>)}
      <Grid item container xs={12} className={classes.appContainer} >
        <AppsCardItems apps={sms} />
      </Grid>
      {(hideHeadingOnEmptyRoutes(groups, email) && <Grid item xs={12} className="apps-heading">
        <Typography variant="h5">
          Email
        </Typography>
      </Grid>)}
      <Grid item container xs={12} className={classes.appContainer} >
        <AppsCardItems apps={email} />
      </Grid>
      {(hideHeadingOnEmptyRoutes(groups, notes) && <Grid item xs={12} className="apps-heading">
        <Typography variant="h5">
          Clear Notes
        </Typography>
      </Grid>)}
      <Grid item container xs={12} className={classes.appContainer} >
        <AppsCardItems apps={notes} />
      </Grid>
      {(hideHeadingOnEmptyRoutes(groups, infohubs) && <Grid item xs={12} className="apps-heading">
        <Typography variant="h5">
          Info Hubs
        </Typography>
      </Grid>)}
      <Grid item container xs={12} className={classes.appContainer} >
        <AppsCardItems apps={infohubs?.map(infohub => ({
          ...infohub,
          path: `/${infohub.link}`,
          iconName: infohub.icon
        }))} />
      </Grid>
    </Grid>
  )
}

export default HomePage
