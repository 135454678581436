import React, { useState } from 'react';
import { Grid, Card, CardHeader, CardContent, TextField, Divider, Button, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { forgotPasswordValidations } from '../../validations';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { forgotPassword } from '../../store/auth';
import { primaryColorLink } from '../../constants/linkClasses';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import './ForgotPassword.scss';

const validationSchema = yup.object(forgotPasswordValidations());

function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const email = useSelector((state) => state.auth.forgotPasswordEmail);
  const history = useHistory();
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: email || "",
      code: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      if (formik.isValid) {
        const response = await forgotPassword({
          email: values.email,
          code: values.code,
          newPassword: values.newPassword
        });
        if (response.status) {
          dispatch(openSnackbar({
            message: 'Password reset successful',
            type: SNACKBAR_TYPES.SUCCESS
          }));
          return history.push('/');
        }
        dispatch(openSnackbar({
          message: response.message,
          type: SNACKBAR_TYPES.ERROR
        }));
      }
      setIsLoading(false);
    }
  });

  return (
    <Grid container justifyContent="center" alignContent="center" className="reset-password-container">
      <Card className="reset-password-card">
        <CardHeader title="Reset Password" />
        <Divider />
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  disabled={email}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="code"
                  label="Code"
                  type="text"
                  fullWidth
                  required
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="newPassword"
                  label="New Password"
                  type="password"
                  fullWidth
                  required
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  helperText={formik.touched.newPassword && formik.errors.newPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="confirmNewPassword"
                  label="Confirm New Password"
                  type="password"
                  fullWidth
                  required
                  value={formik.values.confirmNewPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                  helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                />
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={isLoading} fullWidth>
                  {!isLoading && 'Reset Password'}
                  {isLoading && <CircularProgress size={24} color="inherit" />}
                </Button>
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Link color="primary" to="/" className={primaryColorLink}>Remember your password? Login</Link>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ForgotPassword;
