const articleStyling = (theme) => ({
  articleContent: {
    '& .cont_layout-container': {
      display: 'flex',
      width: '100%',

      '& .cont_layout-col': {
        width: '50%',
        padding: '8px',
      },

      '& .cont_layout-col_standout': {
        width: '50%',
        backgroundColor: theme.palette.primaryButton[800],
        padding: '20px',
        borderRadius: '4px',
        '& p': {
          color: theme.palette.textColor[100],
        },
        '& strong': {
          color: theme.palette.textColor[100],
        }
      },

      '& .cont_layout-col-left-small': {
        padding: '8px',
        justifyContent: 'flex-start',
      },

      '& .cont_layout-col-right-small': {
        padding: '8px',
        justifyContent: 'flex-end',
      },

      '& .cont_layout-col-left-large': {
        width: '58.33333%',
        padding: '8px',
        justifyContent: 'flex-start',
      },

      '& .cont_layout-col-right-large': {
        width: '58.33333%',
        padding: '8px',
        justifyContent: 'flex-end',
      },

      '& .cont_layout-col-left-small_standout': {
        padding: '8px',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.primaryButton[800],
        margin: '8px',
        borderRadius: '4px',
        '& p': {
          color: theme.palette.textColor[100],
        },
        '& strong': {
          color: theme.palette.textColor[100],
        }
      },

      '& .cont_layout-col-right-small_standout': {
        padding: '8px',
        justifyContent: 'flex-end',
        backgroundColor: theme.palette.primaryButton[800],
        margin: '8px',
        borderRadius: '4px',
        '& p': {
          color: theme.palette.textColor[100],
        },
        '& strong': {
          color: theme.palette.textColor[100],
        }
      },

      '& .cont_layout-col-left-large_standout': {
        width: '58.33333%',
        padding: '8px',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.primaryButton[800],
        margin: '8px',
        borderRadius: '4px',
        '& p': {
          color: theme.palette.textColor[100],
        },
        '& strong': {
          color: theme.palette.textColor[100],
        }
      },

      '& .cont_layout-col-right-large_standout': {
        width: '58.33333%',
        padding: '8px',
        justifyContent: 'flex-end',
        backgroundColor: theme.palette.primaryButton[800],
        margin: '8px',
        borderRadius: '4px',
        '& p': {
          color: theme.palette.textColor[100],
        },
        '& strong': {
          color: theme.palette.textColor[100],
        }
      },

      "@media (max-width:1030px)": {
        '& .cont_layout-col': {
          width: '100%',
        },

        '& .cont_layout-col_standout': {
          width: '100%',
        },

        '& .cont_layout-col-left-small': {
          width: '100%'
        },

        '& .cont_layout-col-right-small': {
          width: '100%'
        },

        '& .cont_layout-col-left-large': {
          width: '100%',
        },

        '& .cont_layout-col-right-large': {
          width: '100%',
        },

        '& .cont_layout-col-left-small_standout': {
          width: '100%'
        },

        '& .cont_layout-col-right-small_standout': {
          width: '100%'
        },

        '& .cont_layout-col-left-large_standout': {
          width: '100%',

        },

        '& .cont_layout-col-right-large_standout': {
          width: '100%',
        },
      },

      '& .cont_layout-media-object': {
        display: 'flex',
        alignItems: 'center',
        '& .cont_layout-media-object-left': {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '5px'
        },
        '& .cont_layout-media-object-right': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: '5px'
        }
      },

      '& .cont_layout-standout': {
        backgroundColor: theme.palette.primaryButton[800],
        padding: '32px 20.3px 29px 32px',
        borderRadius: '4px',
        color: theme.palette.primary.contrastText,
        '& h4': {
          fontSize: '28px',
          fontWeight: 600,
        },
        '& p': {
          fontSize: '16px',
        },
        '& strong': {
          fontWeight: 600
        }
      },

      '& .cont_layout-standout_action1': {
        backgroundColor: '#f2f6ff',
        padding: '32px 20.3px 29px 32px',
        borderRadius: '4px',
        border: 'solid 1px #cbd7ef',
        '& h2': {
          fontSize: '28px',
          fontWeight: 600,
          color: theme.palette.textColor[900],
          margin: '0 0 24px',
        },
        '& p': {
          fontSize: '16px',
          color: theme.palette.textColor[900],
          margin: '0 0 24px'
        },
        '& strong': {
          color: theme.palette.textColor[900],
          margin: '0 0 24px'
        }
      },

      '& .cont_layout-standout_action2': {
        backgroundColor: theme.palette.textColor[100],
        padding: '32px 20.3px 29px 32px',
        borderRadius: '4px',
        border: 'none',
        '& h2': {
          fontSize: '28px',
          fontWeight: 600,
          color: theme.palette.textColor[900],
          margin: '0 0 24px',
        },
        '& p': {
          fontSize: '16px',
          color: theme.palette.textColor[900],
          margin: '0 0 24px'
        },
        '& strong': {
          color: theme.palette.textColor[900],
          margin: '0 0 24px'
        }
      },

      '& .cont_layout-quote': {
        padding: '20px',
        backgroundColor: theme.palette.textColor[100],
        borderLeft: `10px solid ${theme.palette.primaryButton[800]}`
      },

      '& .cont_layout-quote:before': {
        color: '#365A9B',
        content: 'open-quote',
        fontSize: '4em',
        lineHeight: '.1em',
        marginRight: '.25em',
        verticalAlign: '-.4em'
      },

      '& .cont_image_is-native-w': {
        maxWidth: 'none'
      },

      '& .cont_image_is-max-w-25': {
        maxWidth: '25px'
      },

      '& .cont_image_is-max-w-50': {
        maxWidth: '50px'
      },

      '& .cont_image_is-max-w-75': {
        maxWidth: '75px'
      },

      '& .cont_image_is-max-w-100': {
        maxWidth: '100px'
      },

      '& .cont_image_is-max-w-125': {
        maxWidth: '125px'
      },

      '& .cont_image_is-max-w-150': {
        maxWidth: '150px'
      },

      '& .cont_image_is-max-w-175': {
        maxWidth: '175px'
      },

      '& .cont_image_is-max-w-200': {
        maxWidth: '200px'
      },

      '& .cont_image_is-max-w-250': {
        maxWidth: '250px'
      },

      '& .cont_image_is-max-w-350': {
        maxWidth: '350px'
      },

      '& .cont_image_is-max-w-400': {
        maxWidth: '400px'
      },

      '& .cont_image_is-max-w-450': {
        maxWidth: '450px'
      },
    },

    "@media (max-width:1030px)": {
      '& .cont_layout-container': {
        flexDirection: 'column'
      }
    },

    '& h2': {
      fontSize: '28px',
      fontWeight: '600',
      lineHeight: '1.43   '
    },

    '& h3': {
      fontSize: '23px',
      fontWeight: '600',
      lineHeight: '1.74'
    },

    '& h4': {
      fontSize: '19px',
      fontWeight: '600',
      lineHeight: '1.26'
    },

    '& h5': {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '1.5'
    },

    '& h6': {
      fontSize: '13px',
      fontWeight: '600',
      lineHeight: '1.85'
    },

    '& figure': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& figcaption': {
        fontStyle: 'italic',
        color: 'rgba(100, 100, 100, 0.87)',
        textAlign: 'center',
        marginTop: '10px'
      }
    },

    '& table': {
      border: 'none',
      borderCollapse: 'collapse',
      width: '100%',
      '& caption': {
        backgroundColor: theme.palette.primaryButton[800],
        color: theme.palette.primary.contrastText,
        textAlign: 'initial',
        padding: '14px',
        fontWeight: '600',
      },
      '& thead': {
        '& tr': {
          '& th': {
            backgroundColor: theme.palette.textColor[300],
            color: theme.palette.textColor[900],
            padding: '14px',
            fontWeight: '600',
            borderBottom: `1px solid ${theme.palette.textColor[400]}`
          }
        }
      },
      '& tbody': {
        overflow: 'auto',
        '& tr': {
          '& td': {
            backgroundColor: theme.palette.textColor[100],
            color: theme.palette.textColor[900],
            padding: '14px',
            borderBottom: `1px solid ${theme.palette.textColor[400]}`
          }
        }
      },
      '& tfoot': {
        '& tr': {
          '& td': {
            backgroundColor: theme.palette.textColor[300],
            color: theme.palette.textColor[900],
            padding: '14px',
            borderBottom: `1px solid ${theme.palette.textColor[400]}`
          }
        }
      },
    },

    '& .cont_layout-fake-table': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '0px',
      '& h1': {
        backgroundColor: theme.palette.primaryButton[800],
        color: theme.palette.primary.contrastText,
        padding: '5px 24px',
        fontWeight: '600',
        gridColumn: '1/span 3',
        gridRow: 1,
      },
      '& h2': {
        backgroundColor: theme.palette.primaryButton[800],
        color: theme.palette.primary.contrastText,
        padding: '5px 24px',
        fontWeight: '600',
        gridColumn: '1/span 3',
        gridRow: 1,
      },
      '& h3': {
        backgroundColor: theme.palette.primaryButton[800],
        color: theme.palette.primary.contrastText,
        padding: '5px 24px',
        fontWeight: '600',
        gridColumn: '1/span 3',
        gridRow: 1,
      },
      '& h4': {
        backgroundColor: theme.palette.primaryButton[800],
        color: theme.palette.primary.contrastText,
        padding: '5px 24px',
        fontWeight: '600',
        gridColumn: '1/span 3',
        gridRow: 1,
      },
      '& h5': {
        backgroundColor: theme.palette.primaryButton[800],
        color: theme.palette.primary.contrastText,
        padding: '5px 24px',
        fontWeight: '600',
        gridColumn: '1/span 3',
        gridRow: 1,
      },
      '& h6': {
        backgroundColor: theme.palette.primaryButton[800],
        color: theme.palette.primary.contrastText,
        padding: '5px 24px',
        fontWeight: '600',
        gridColumn: '1/span 3',
        gridRow: 1,
      },
      '& .cont_layout-col-x-small': {
        backgroundColor: theme.palette.textColor[100],
        padding: '14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },

    '& .cont_is-pulled-left': {
      float: 'left'
    },

    '& .cont_is-pulled-right': {
      float: 'right'
    },

    [theme.breakpoints.down('lg')]: {
      '& .cont_label-large': {
        fontSize: '2rem'
      }
    },

    '& .cont_btn-link': {
      display: 'inline-block'
    },

    '& p': {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '16px',
      color: theme.palette.textColor[900],
      marginBottom: '20px',
      '& strong': {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '16px',
        color: theme.palette.textColor[900],
        fontWeight: 'bold',
      },
      '& em': {
        fontFamily: '"Open Sans", sans-serif',
        color: theme.palette.textColor[900],
        fontStyle: 'italic',
      }
    },

    '& ul': {
      fontSize: '16px',
      marginBottom: '20px',
      paddingLeft: '30px',
      '& li': {
        lineHeight: 1.2,
        '&::marker': {
          fontSize: '1.3rem',
          color: '#365a9b',
        }
      },
    },

    '& ol': {
      fontSize: '16px',
      marginBottom: '20px',
      paddingLeft: '30px',
      '& li': {
        lineHeight: 1.2,
        '&::marker': {
          fontSize: '1rem',
          color: '#365a9b',
        }
      },
    },

    '& .center-items': {
      display: 'flex',
      alignItems: 'center'
    },

    '& a': {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      color: theme.palette.primaryButton[800],
    }
  }
})

export default articleStyling;
