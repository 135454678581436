import { initialState } from '../smsTypes';

const smsTypes = (state, action) => {
  state.types = [ ...action.payload ];
}

const allowedSmsTypes = (state, action) => {
  state.allowedTypes = [ ...action.payload ];
}

const resetSmsTypes = (state, action) => {
  return initialState;
}

const reducers = { smsTypes, resetSmsTypes, allowedSmsTypes };

export default reducers;
