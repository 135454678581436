import axios from 'axios';
import transformSearchResultsDocuments from './../utils/functions';
import { params } from '../constants/contentAPI';

const endpoints = {
  fetchAllContent: 'https://www.healthdirect.gov.au/api/cs/v2/kb-search',
  searchContent: 'https://www.healthdirect.gov.au/api/cs/v2/resolver',
  fetchContentById: process.env.REACT_APP_FETCH_CONTENT,
  fetchFriendlyURLs: `${process.env.REACT_APP_FETCH_ALL_CONTENT}${params.fetchAllContent}`,
}

const fetchAllContent = async () => {
  try {
    const response = await axios({
      url: endpoints.fetchAllContent,
      method: 'GET'
    });
    return response.data;
  } catch (error) {
    return { status: false, message: error.message };
  }
}

const searchContent = async (payload) => {
  try {
    const response = await axios({
      url: `${endpoints.searchContent}?text=${payload.searchTerm}&start=${payload.start}&rows=${payload.rowsPerPage}&helpline=true`,
      method: 'GET'
    });
    return transformSearchResultsDocuments(response?.data);
  } catch (error) {
    return { status: false, message: error.message };
  }
}

const fetchContentById = async ({ id, depth = 1, channel = 'MAC' }) => {
  try {
    const response = await axios({
      url: `${endpoints.fetchContentById}?id=${id}&depth=${depth}&channel=${channel}`,
      method: 'GET'
    });
    return response.data;
  } catch (error) {
    return { status: false, message: error.message };
  }
}

const fetchAllFriendlyUrls = async () => {
  try {
    const response = await axios({
      url: endpoints.fetchFriendlyURLs,
      method: 'GET'
    });
    return response.data;
  } catch (error) {
    return { status: false, message: error.message };
  }
}

const infoHubServices = { fetchAllContent, searchContent, fetchContentById, fetchAllFriendlyUrls };

export default infoHubServices;