const iconNames = Object.freeze({
  Default: 'default',
  Alphabets: 'Alphabets',
  ArrowDown: 'ArrowDown',
  ArrowRight: 'ArrowRight',
  Assignment: 'Assignment',
  Book: 'Book',
  ChevronDown: 'ChevronDown',
  Close: 'Close',
  Copy: 'Copy',
  Collapse: 'Collapse',
  Dashboard: 'Dashboard',
  DeleteForever: 'DeleteForever',
  Drag: 'Drag',
  Edit: 'Edit',
  Email: 'Email',
  Expand: 'Expand',
  Info: 'Info',
  Location: 'Location',
  ManageAccount: 'ManageAccount',
  Notes: 'Notes',
  Numbers: 'Numbers',
  Numeric1Circle: 'Numeric1Circle',
  Numeric2Circle: 'Numeric2Circle',
  Phone: 'Phone',
  PlusCircle: 'PlusCircle',
  Refresh: 'Refresh',
  Send: 'Send',
  SMS: 'SMS',
  SMSManagement: 'SMSManagement',
  TextArea: 'TextArea',
  TextBoxMultiple: 'TextBoxMultiple',
  TrayUpload: 'TrayUpload',
  Upload: 'Upload',
  User: 'User',
  Plus: 'Plus',
  filter:'filter'
});

export default iconNames;
