const styling = theme => ({
  account: {
    backgroundColor: theme.palette.primaryButton[900]
  },
  logo: {
    width: '120px',
    aspectRatio: '3/2',
    objectFit: 'contain',
    cursor: 'pointer'
  },
  header: {
    height: '50px',
    padding: '0px',
  },
  headerShifted: {
    height: '50px',
    paddingLeft: '2%'
  },
  menu: {
    color: theme.palette.primary.main
  },
  headerTitle: {
    color: theme.palette.secondaryButton[900],
    margin: '0 5px 0px 0px',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1.7rem',
      marginRight: '10px'
    },
    display: 'flex',
    alignItems: 'center'
  },
  accountContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    accountButton: {
      padding: '0px 20px',
    },
    '& .MuiButton-label': {
      fontSize: '13px',
      fontWeight: 600
    },
    '& .MuiButton-startIcon.MuiButton-iconSizeMedium': {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      backgroundColor: '#1e4387',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  userNameSmallScreen: {
    display: 'none'
  },
  userNameLargeScreen:{
    display:'block',
    whiteSpace:'nowrap'
  },
  logoImageShowLargeScreen:{
    display:'flex',
    alignItems:'center',
    justifyContent:'end',
    padding: '0px 10px'
  },
  imageContainerWrapper:{
    width:'24px'
  },
  [theme.breakpoints.down('md')]: {
    accountButton: {
      '& .MuiButton-iconSizeMedium > .MuiAvatar-root.MuiAvatar-circular': {
        fontSize: '16px'
      }
    },
    account: {
      width: '36px',
      height: '36px',
    },
    userNameLargeScreen:{
      display:'none'
    },
  },
  [theme.breakpoints.down('sm')]:{
    logoImageShowLargeScreen:{
      '& img:first-child': {
        display:'none'
      }
    },
  },
   [theme.breakpoints.down('xs')]:{
    headerTitle:{
    fontSize:'1rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
        marginRight: '0px',
      },
    },
    logoImageShowLargeScreen:{
      '& img:first-child': {
        display:'none'
      },
      '& img:nth-child(2)': {
        display:'none'
      }
    },
   }
})

export default styling;
