import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import AutoComplete from '../../../components/AutoComplete';
import clsx from 'clsx';
import infoHubHeaderMenu from '../../../constants/infoHubHeaderMenu';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '7vh',
    background: theme.palette.primary.main,
    margin: '0%',
    padding: '0% 3% 0% 3%',
    boxShadow: '0px 2.9px 3.9px hsl(0deg 0% 0% / 0.46)',
    zIndex: 5,
    overflowX:'auto',
    overflow:'hidden'
  },
  flexColumn:{
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'start', 
    },
  },
  tab: {
    height: '100%',
    borderRadius: '0%',
    padding: '0px 3%',
    color: theme.palette.common.white,
    fontSize: '12px',
    fontWeight: '600',
    minWidth:'fit-content'
  },
  tabSelected: {
    borderBottom: `3px solid ${theme.palette.common.white}`,
    transition: 'all 350ms',
    boxShadow: '0px 4.5px 7.5px hsl(0deg 0% 0% / 0.45)',
    zIndex: 8,
    [theme.breakpoints.down('sm')]: {
      padding:'10px 10px'
    },
  }
}));

function InfoHubHeader({ params }) {
  const classes = useStyles();
  const history = useHistory();
  const { pathname: currentPath } = useLocation();

  const routeTo = (path) => {
    history.push(path);
  }

  function getHeaderButtons(basePath, currentPath) {
    return infoHubHeaderMenu(basePath).map(menu => <Button
      variant="text"
      className={clsx(classes.tab, { [classes.tabSelected]: currentPath === menu.path })}
      onClick={() => routeTo(menu.path)}
      key={menu.path}
    >{menu.name}</Button>)
  }

  return (
    <Grid container className={classes.container} justifyContent="flex-end">
      <Grid item container alignContent="flex-end" xs={12} md={12} className={classes.flexColumn}>
        {getHeaderButtons(`/${params?.infoHub}`, currentPath)}
      </Grid>
      {/* <Grid item container alignContent="center" xs={12} md={4}>
        <AutoComplete params={params} />
      </Grid> */}
    </Grid>
  )
}

export default InfoHubHeader
