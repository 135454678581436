const styling = (theme) => ({
  heading: {
    color: theme.palette.header[900],
    fontSize: '28px',
    fontWeight: '600',
    marginBottom: '2%'
  },
  card: {
    width: '100%',
    height: '50vh'
  },
  cardContent: {
    height: '100%'
  },
  emptyStateCardContent: {
    height: '100%',
    marginTop: '-3%',
  },
  spacing: {
    padding: '10px'
  },
  publishDateSpacing: {
    fontSize: '13px',
    marginTop: '5px'
  },
  message: {
    color: theme.palette.primary.dark,
    fontWeight: '600',
  },
  subHeading: {
    color: theme.palette.header[900],
    fontWeight: '600',
    fontSize: '19px'
  }
})

export default styling;
