import { createSlice } from '@reduxjs/toolkit';
import snackbarReducres from './snackbarReducers';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';

export const initialState = {
  isOpen: false,
  message: '',
  type: SNACKBAR_TYPES.SUCCESS
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: { ...snackbarReducres },
});

export const { openSnackbar, closeSnackbar, resetSnackBar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
