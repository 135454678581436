import React from 'react';
import { CardHeader, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import styling from '../SMSForm/styling';

const useStyles = makeStyles((theme) => ({
  ...styling(theme),
  action: {
    '& .MuiCardHeader-action': {
      margin : 0
    }
  },
  justifyContent:{
    [theme.breakpoints.up('xl')]:{
      justifyContent:'start'
    }
  }
}));

function SMSTypeCardHeader({ title, titleNumber, action }) {
  const classes = useStyles();

  return <>
    <CardHeader
      title={
        <Grid item container spacing={1}>
          {titleNumber && <Grid item container className={classes.justifyCenter}  xs={1}>
            <Typography variant="h6" className={classes.heading}>
              <span className={classes.numberBox}>{titleNumber}</span>
            </Typography>
          </Grid>}
          <Grid item container xs={11} alignItems="center">
            <Typography variant="h6" className={classes.smsTypeHeading}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      }
      action={action}
      className={classes.action}
    />
    <Divider />
  </>
}

export default SMSTypeCardHeader;
