const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
      responseType: 'code',
    },
  },
};

export default amplifyConfig;