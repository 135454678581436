import { initialState } from '../userManagement';

const saveUsers = (state, action) => {
  state.users[state.page] = [...action.payload.results];
  state.count = action.payload?.count || 0;
  state.rolesFilterList = action.payload?.roles || [];
  state.serviceProviderFilterList = action.payload?.serviceProvider?.filter(item => !!item) || [];
}

const setPage = (state, action) => {
  state.page = action.payload;
}

const setLimit = (state, action) => {
  state.limit = action.payload;
}

const setSearch = (state, action) => {
  state.search = action.payload;
}

const setRoles = (state, action) => {
  state.roles = action.payload;
}

const setServiceProvider = (state, action) => {
  state.serviceProvider = action.payload;
}

const emptyUsers = (state, action) => {
  state.users = {};
  state.count = 0;
}

const resetUserManagement = (state, action) => {
  return initialState
}

const reducers = { saveUsers, resetUserManagement, setLimit, setPage, setSearch, setRoles, setServiceProvider, emptyUsers };

export default reducers;
