import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getInfoHubData, getFAQ } from '../../store/info-hub';
import clsx from 'clsx';
import copyToClipboard from '../../utils/copyToClipboard';
import CopyButton from '../../components/CopyButton';
import BackToTopButton from '../../components/BackToTopButton';
import FAQ from '../../assets/icons/faq.svg';
import InfoHubEmptyState from './InfoHubEmptyState';
import styling from './styling';
import articleStyling from './articleStyling';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import handleInternalLinks from '../../utils/handleInternalLinks';
import { useHistory } from 'react-router';
import scrollToTop from '../../utils/scrollToTop';
import trackEvents from '../../utils/trackEvents';
import { getInfoHubTitle } from '../../utils/infoHubRoutesProperties';

const useStyles = makeStyles(theme => ({
  ...styling(theme),
  articleContent: articleStyling(theme).articleContent,
  accordion: {
    width: '100%',
    '&.Mui-expanded': {
      margin: 0
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      backgroundColor: theme.palette.primaryButton[900],
      color: 'white',
      borderRadius: '50%',
      margin: '0px',
      padding: '6px'
    }
  },
  accordionSummary: {
    fontSize: '16px',
    fontWeight: '600'
  },
  accordionOpened: {
    background: '#c9e2f5',
    border: '1px solid #69a8d9',
    color: theme.palette.primaryButton[900],
  },
  accordionDetails: {
    border: '1px solid #69a8d9',
  },
  accordionClosed: {
    background: theme.palette.textColor[100],
    color: theme.palette.textColor[900],
    border: '1px solid #b4b4b4',
    minHeight: '64px'
  }
}));

function InfoHubFAQs({ params }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.infoHub[params?.infoHub]?.isLoading);
  const isDataFetched = useSelector(state => state.infoHub[params?.infoHub]?.isDataFetched);
  const [expanded, setExpanded] = useState(null);
  const articles = useSelector(state => state.infoHub[params?.infoHub]?.faqs);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    scrollToTop();
    (async () => {
      if (!isDataFetched) {
        const res = await getInfoHubData(params?.infoHub, dispatch);
        if (!res.status) dispatch(openSnackbar({
          message: 'Failed getting infohub data',
          type: SNACKBAR_TYPES.ERROR
        }));
      }
    })()
  }, [dispatch, params, isDataFetched]);

  const fetchArticle = async ({ article, index }) => {
    try {
      if (!article?.body) {
        if (!(await getFAQ()({ friendlyURL: article?.friendly_url, infoHub: params?.infoHub, index, dispatch })).status)
          dispatch(openSnackbar({ message: 'Failed getting FAQ', type: SNACKBAR_TYPES.ERROR }));
      }
    } catch (error) {
      dispatch(openSnackbar({ message: 'Failed getting FAQ', type: SNACKBAR_TYPES.ERROR }));
    }
  }

  useEffect(() => {
    handleInternalLinks(classes.articleContent, history);
  }, [articles, classes.articleContent, history]);

  const copyArticle = async (article) => {
    await copyToClipboard(article?.title || '');
    dispatch(openSnackbar({ message: 'Copied to clipboard', type: SNACKBAR_TYPES.SUCCESS }));
    trackEvents("faq_copy", {
      title: article?.title,
      link_url: article?.friendly_url,
      info_hub_title: getInfoHubTitle(params),
      info_hub_page: 'FAQs'
    });
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      trackEvents("faq_click", {
        title: articles[panel]?.title,
        link_url: articles[panel]?.friendly_url,
        info_hub_title: getInfoHubTitle(params),
        info_hub_page: 'FAQs'
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.heading}>FAQs</Typography>
      </Grid>
      <Grid item container alignContent="center" justifyContent="center">
        {isLoading && <CircularProgress color="inherit" />}
        {
          !isLoading && !articles?.length && (
            <InfoHubEmptyState
              img={FAQ}
              heading="Welcome! No FAQs have been added yet."
              subHeading="Check back soon to learn learn more about common FAQs and how to help older Australians."
            />
          )
        }
        {!isLoading && articles?.map((article, index) => (
          <Accordion
            square
            className={classes.accordion}
            onChange={handleChange(index)}
            onClick={() => fetchArticle({ article, index })}
            expanded={expanded === index}
            key={index}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className={clsx({ [classes.accordionOpened]: expanded === index, [classes.accordionClosed]: expanded !== index, })}
            >
              <Typography className={classes.accordionSummary}>{article?.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={clsx({ [classes.accordionDetails]: expanded === index })}>
              <Grid container>
                {article?.isLoading && <Grid item container justifyContent="center" xs={12}>
                  <CircularProgress size={24} />
                </Grid>}
                {!article?.isLoading && <Grid container>
                  <Grid item xs={12} className={classes.spacing}>
                    <CopyButton copy={() => copyArticle(article)} />
                  </Grid>
                  <Grid item xs={12} className={classes.spacing}>
                    <div className={classes.articleContent} dangerouslySetInnerHTML={{ __html: article.body }}></div>
                  </Grid>
                </Grid>}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      {!isLoading && <Grid item xs={12}>
        <BackToTopButton />
      </Grid>}
    </Grid>
  )
}

export default InfoHubFAQs;
