import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TextField,
  Button,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  Badge,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { useDebouncedCallback } from "use-debounce";
import { openSnackbar } from "../../store/snackbar";
import createPersistedState from "use-persisted-state";
import SNACKBAR_TYPES from "../../constants/snackBarTypes";
import getTaskByIdService from "../../services/getTaskById";
import {
  getUsers,
  setPage,
  setLimit,
  setSearch,
  downloadUsers,
  resetUserManagement,
  emptyUsers,
} from "../../store/userManagement";
import SvgIcon from "../SvgIcons";
import iconNames from "../../constants/iconNames";
import downloadDocument from "../../utils/autoDownloadFiles";
import UserManagementFilterDialog from "./UserManagementFilterDialog";
const useDownloadUsersTaskId = createPersistedState("downloadUsersTaskId");

const useStyles = makeStyles((theme) => ({
  card: {
    height: "80vh",
  },
  cardContent: {
    padding: "0px",
    height: "calc(100% - 85px)",
    "& .MuiGrid-container": {
      height: "100%",
    },
  },
  downloadButton: {
    marginLeft: "10px",
  },
  tableHeight: {
    height: "95%",
  },
  "& #account-search": {
    margin: "0% 1%",
  },
  headerAction: {
    overflow: "hidden",
    overflowX: "auto",
    whiteSpace: "nowrap",
    "& > .MuiCardHeader-action": {
      margin: "0px",
      alignSelf: "center",
    },
    "& .statusLink": {
      color: theme.palette.secondaryButton[900],
      textDecoration: "underline",
      fontSize: "small",
      marginTop: "5px",
      marginLeft: "10px",
      cursor: "pointer",
    },
  },
  loading: {
    height: "100%",
    position: "absolute",
  },
  notFound: {
    color: theme.palette.textColor[600],
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  hidden: {
    display: "none",
  },
  block: {
    display: "block",
  },
  w150: {
    minWidth: "150px",
  },
  w120: {
    minWidth: "120px",
  },
  w100: {
    minWidth: "100px",
  },
  badgeCountPosition: {
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      top: '3px',
      right: '3px',
    }
  },
  actionButton: {
    marginLeft: 15
  }
}));

function CurrentUsersCard({ isLoading, setIsLoading, setSelectedUser }) {
  const { users, page, limit, count, search, roles, serviceProvider } =
    useSelector((state) => state.userManagement);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [isDownloadStatusLoading, setIsDownloadStatusLoading] = useState(false);
  const [downloadUsersTaskId, setDownloadUsersTaskId] =
    useDownloadUsersTaskId(null);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [filterBadgeCount, setFilterBadgeCount] = useState(0);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleRefresh = async () => {
    setIsLoading(true);
    setSelectedUser(null);
    dispatch(emptyUsers({}));
    await getUsers({ page, limit, search, roles, serviceProvider })(dispatch);
    setIsLoading(false);
  };

  const exportUsers = async () => {
    setIsDownloadInProgress(true);
    const res = await downloadUsers({ search, roles, serviceProvider })();
    dispatch(
      openSnackbar({
        message: res.message,
        type: res.status ? SNACKBAR_TYPES.SUCCESS : SNACKBAR_TYPES.ERROR,
      })
    );
    if (res.status) {
      setDownloadUsersTaskId(res.taskId);
    } else {
      setIsDownloadInProgress(false);
    }
  };

  const getDownloadStatus = async () => {
    setIsDownloadStatusLoading(true);
    const res = await getTaskByIdService(downloadUsersTaskId);
    if (
      res.status &&
      res.data?.task?.status === "SUCCEEDED" &&
      res.data?.task?.type === "DOWNLOAD"
    ) {
      if (res.data.task.response) {
        downloadDocument(res.data.task.response);
      }
    } else if (res.status && res.data?.task?.status === "FAILED") {
      dispatch(
        openSnackbar({
          message: "Unable to process download users.",
          type: SNACKBAR_TYPES.ERROR,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: "Download users report still under processing.",
          type: SNACKBAR_TYPES.INFO,
        })
      );
      setIsDownloadStatusLoading(false);
      return;
    }
    setDownloadUsersTaskId(null);
    setIsDownloadInProgress(false);
    setIsDownloadStatusLoading(false);
  };

  const clearDownloadStatus = () => {
    setDownloadUsersTaskId(null);
    setIsDownloadInProgress(false);
  }

  useEffect(() => {
    if (!users.length) {
      (async () => {
        await getUsers({ page, limit })(dispatch);
        setIsLoading(false);
      })();
    } else setIsLoading(false);

    return () => {
      dispatch(resetUserManagement());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = async (_, pageNumber) => {
    setIsLoading(true);
    dispatch(setPage(pageNumber));
    setSelectedUser(null);
    if (!users[pageNumber]) {
      await getUsers({
        page: pageNumber,
        limit,
        search,
        roles,
        serviceProvider,
      })(dispatch);
    }
    setIsLoading(false);
  };

  const searchOnValueChange = async (
    pageLimit = null,
    rolesFilter = null,
    serviceProviderFilter = null
  ) => {
    setIsLoading(true);
    dispatch(setPage(0));
    dispatch(emptyUsers({}));
    setSelectedUser(null);
    await getUsers({
      page: 0,
      search,
      limit: pageLimit || limit,
      roles: rolesFilter || roles,
      serviceProvider: serviceProviderFilter || serviceProvider,
    })(dispatch);
    setIsLoading(false);
  };
  const debouncedSearch = useDebouncedCallback(searchOnValueChange, 500);

  const handleChangeRowsPerPage = async (event) => {
    dispatch(setLimit(+event.target.value));
    await searchOnValueChange(+event.target.value);
  };

  const handleSearch = (event) => {
    dispatch(setSearch(event.target.value));
    debouncedSearch();
  };

  const handleFilterDialogClose = (e, reason) => {
    if (reason === "backdropClick") return;
    setIsFilterDialogOpen(false);
  };

  const handleSearchClear = () => {
    dispatch(setSearch(""));
    debouncedSearch();
  };

  useEffect(() => {
    setFilterBadgeCount(!!roles.length + !!serviceProvider.length)
  }, [roles.length, serviceProvider.length]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Current Users"
        disableTypography
        className={classes.headerAction}
        action={
          <div
            style={{
              marginLeft: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative" }}>
              <TextField
                id="account-search"
                placeholder="Search Accounts"
                value={search}
                onChange={handleSearch}
              ></TextField>
              <SvgIcon
                className={`${search.length ? `${classes.block}` : `${classes.hidden}`
                  }`}
                iconName={iconNames.Close}
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  cursor: "pointer",
                  height: '32px',
                  width: '20px'
                }}
                onClick={handleSearchClear}
              />
            </div>
            <Button
              startIcon={<GetApp />}
              className={classes.downloadButton}
              size="small"
              variant="contained"
              color="primary"
              disabled={
                isLoading ||
                !Boolean(users[page]?.length) ||
                isDownloadInProgress
              }
              onClick={exportUsers}
            >
              Download List
            </Button>
            {isDownloadInProgress && downloadUsersTaskId && <div className={classes.downloadButton}>
              <Button
                size="small"
                variant="text"
                color="primary"
                onClick={getDownloadStatus}
                disabled={isDownloadStatusLoading}
              >
                Get Status
              </Button>
              |
              <Button onClick={clearDownloadStatus} color="primary" size="small" >
                Cancel
              </Button>
            </div>}
            <Tooltip title="Filter list">
              <Badge
                className={classes.badgeCountPosition}
                badgeContent={filterBadgeCount}
                color="primary"
                overlap="rectangular"
              >
                <IconButton
                  color={filterBadgeCount > 0 ? "primary" : undefined}
                  variant="contained"
                  size="small"
                  onClick={() => setIsFilterDialogOpen(true)}
                  className={classes.actionButton}
                >
                  <SvgIcon iconName={iconNames.filter} />
                </IconButton>
              </Badge>
            </Tooltip>
            <Tooltip title="Refresh">
              <span>
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.actionButton}
                  disabled={isLoading}
                  onClick={handleRefresh}
                >
                  <SvgIcon iconName={iconNames.Refresh} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        }
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          style={{ position: "relative" }}
        >
          {isLoading && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.loading}
            >
              <CircularProgress size={48} color="primary" />
            </Grid>
          )}
          {!isLoading && !Boolean(users[page]?.length) && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.loading}
            >
              <Typography variant="h5" className={classes.notFound}>
                No Users Found
              </Typography>
            </Grid>
          )}
          <TableContainer className={classes.tableHeight}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell size="small">Name</TableCell>
                  <TableCell size="small">Email</TableCell>
                  <TableCell size="small">Service Provider</TableCell>
                  <TableCell size="small">Roles</TableCell>
                  <TableCell size="small"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users[page]?.map((row) => (
                  <TableRow key={row.email}>
                    <TableCell size="small" className={classes.w150}>
                      {row.name || ""}
                    </TableCell>
                    <TableCell size="small" className={classes.w150}>
                      {row.email || ""}
                    </TableCell>
                    <TableCell size="small" className={classes.w120}>
                      {row.serviceProvider || ""}
                    </TableCell>
                    <TableCell size="small" className={classes.w120}>
                      {row.roles?.map(({ role }) => role)?.join(", ") || ""}
                    </TableCell>
                    <TableCell
                      size="small"
                      className={classes.w100}
                      align="center"
                    >
                      <IconButton onClick={() => setSelectedUser(row)}>
                        <SvgIcon iconName={iconNames.ArrowRight} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={count}
            page={page}
            rowsPerPage={limit}
            colSpan={3}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            shape="rounded"
            component="div"
          />
        </Grid>
      </CardContent>
      <UserManagementFilterDialog
        open={isFilterDialogOpen}
        handleClose={handleFilterDialogClose}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setSelectedUser={setIsLoading}
        searchOnValueChange={searchOnValueChange}
      />
    </Card>
  );
}

export default CurrentUsersCard;
