import { createSlice } from "@reduxjs/toolkit";
import { fetchNotesTemplate, updateNotesTemplate } from "../../services/notes";
import notesReducer from "./notesReducer"


export const initialState = {
  notesTemplate: {},
  notesContent: {},
}

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: { ...notesReducer }
})

export const { addNotes, resetNotes } = notesSlice.actions;

export const getNotes = async (dispatch) => {
  try {
    const data = await fetchNotesTemplate();
    if (!data?.status) throw new Error('Failed fetching Notes');

    dispatch(addNotes(data.data));
    return { status: true, message: "Notes fetched successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const updateNotes = async (payload, dispatch) => {
  try {
    const data = await updateNotesTemplate(payload);
    if (!data?.status) throw data;

    await getNotes(dispatch);
    return { status: true, message: "Notes Template updated successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export default notesSlice.reducer;