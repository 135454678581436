const generateSmsText = (smsType, values = []) => {
  let generatedMessage = smsType?.template;
  smsType?.types?.forEach((type, index) => {
    if (type.type === 'Referral') {
      generatedMessage = generatedMessage.replaceAll(`{{${type.reference}}}`, `${type.prefix || ''}${values[index] || ''}`)
    } else {
      generatedMessage = generatedMessage.replaceAll(`{{${type.reference}}}`, values[index] || '')
    }
  });
  return generatedMessage;
};

export default generateSmsText;