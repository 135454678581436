import { MESSAGE_TYPES } from "../constants/messageTypes";

export default function validateTypes(types = []) {
  let isValid = true;
  types.every(type => {
    if (type?.type === MESSAGE_TYPES.DROPDOWN) {
      isValid = isValid && (!!type?.label && !!type?.reference &&
        type?.options?.reduce((valid, option) => valid && !!option?.label && !!option?.value, true));
    } else {
      isValid = isValid && (!!type?.label && !!type?.reference);
    }
    return isValid;
  });

  return isValid;
}
