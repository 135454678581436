import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowUpward } from '@material-ui/icons';
import scrollToTop from '../utils/scrollToTop';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '1%',
    fontWeight: 'bold'
  }
}))

function BackToTopButton() {
  const classes = useStyles();

  return (
    <Button
      startIcon={<ArrowUpward />}
      variant="text"
      color="primary"
      className={classes.button}
      onClick={scrollToTop}
    >Back To Top</Button>
  )
}

export default BackToTopButton;
