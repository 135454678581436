const styling = (theme) => ({
  heading: {
    color: theme.palette.primary.dark,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      fontWeight: "600",
    },
  },
  container: {
    width: "100%",
    height: "100%",
  },
  headingContainer: {
    height: 40,
  },
  emailTypesContainer: {
    width: "100%",
    padding: "0px",
    paddingRight: 20
  },
  emailTypeBoxHeight:{
    marginTop: "24px",
    overflow: 'hidden',
    display:'flex',
    alignItems:'start',
    height: 'calc(100% - 50px)',
    [theme.breakpoints.up("md")]: {
      height: 'calc(100% - 50px)',
      overflowY: 'auto',
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: '100%',
      overflow: 'hidden'
    },
  },
  inputIcon: {
    marginBottom: 20,
    padding: "4px 0px",
  },
  numberBox: {
    width: "30px",
    height: "30px",
    backgroundColor: "#2a4c7e",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.38",
    letterSpacing: "0px",
    color: "#ffffff",
  },
  disabled: {
    pointerEvents: "none",
    opacity: "0.8",
  },
  inputLabels: {
    marginLeft: 5,
    '& .MuiInputLabel-root': {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "80%",
      height: "100%",
    },
    '& .MuiInputLabel-shrink': {
      maxWidth: "109%",
    }
  }
});

export default styling;