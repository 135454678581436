import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid, Typography, Card, CardContent, CircularProgress, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackToTopButton from '../../components/BackToTopButton';
import Notices from '../../assets/icons/notices.svg';
import InfoHubEmptyState from './InfoHubEmptyState';
import { getInfoHubData } from '../../store/info-hub';
import styling from './styling';
import articleStyling from './articleStyling';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import handleInternalLinks from '../../utils/handleInternalLinks';
import scrollToTop from '../../utils/scrollToTop';

const useStyles = makeStyles(theme => ({
  ...styling(theme),
  articleContent: articleStyling(theme).articleContent,
  height: {
    height: '100%'
  }
}));

function InfoHubNotices({ params }) {
  const classes = useStyles();
  const isLoading = useSelector(state => state.infoHub[params?.infoHub]?.isLoading);
  const isDataFetched = useSelector(state => state.infoHub[params?.infoHub]?.isDataFetched);
  const notices = useSelector(state => state.infoHub[params?.infoHub]?.fetchedNotices);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    scrollToTop();
    (async () => {
      if (!isDataFetched) {
        const res = await getInfoHubData(params?.infoHub, dispatch);
        if (!res.status) dispatch(openSnackbar({
          message: 'Failed getting infohub data',
          type: SNACKBAR_TYPES.ERROR
        }));
      }
    })()
  }, [dispatch, params, isDataFetched]);

  useEffect(() => {
    handleInternalLinks(classes.articleContent, history);
  }, [notices, classes.articleContent, history]);

  return (
    <Grid container alignContent="flex-start" className={classes.height}>
      {isLoading &&
        <Grid container className={classes.height}>
          <Grid item container justifyContent="center" alignContent="center" className={classes.height} xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      }
      {!isLoading && <Grid container className={classes.spacing}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.heading}>All Notices</Typography>
        </Grid>
        {Boolean(!notices?.length) && <Grid item xs={12}>
          <InfoHubEmptyState
            img={Notices}
            heading="Welcome! No notices have been added yet."
            subHeading="The latest notices will be shown here as they are added, so check back soon."
          />
        </Grid>}
        {Boolean(notices?.length) && <Grid item xs={12}>
          <Card>
            <CardContent className={classes.cardContent}>
              {notices.map(notice => (
                <Grid item container alignContent="flex-start" xs={12} key={notice.title}>
                  <Grid item xs={12} className={classes.spacing}>
                    <Typography className={classes.subHeading}>
                      {notice.title}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.publishDateSpacing}>
                      {`Published: ${notice.review_date}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.spacing}>
                    <div className={classes.articleContent} dangerouslySetInnerHTML={{ __html: notice.body }}></div>
                  </Grid>
                  <Grid item xs={12} className={classes.spacing}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
            </CardContent>
          </Card >
        </Grid>}
        <Grid item xs={12}>
          <BackToTopButton />
        </Grid>
      </Grid>}
    </Grid>
  )
}

export default InfoHubNotices;
