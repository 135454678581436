import React, { useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { Grid, Card, Button, makeStyles, CircularProgress } from '@material-ui/core';

import SMSTypeForm from '../../components/SMSType/SMSTypeForm';
import SMSTemplatePreview from '../../components/SMSType/SMSTemplatePreview';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import { clone } from '../../utils/clone';
import { createSmsType, updateSmsType } from '../../store/smsTypes';
import validateTypes from '../../utils/validateTypes';
import SMSTypeCardHeader from '../../components/SMSType/SMSTypeCardHeader';
import TypesToolbarButtons from '../../components/MessageTemplateItems/TypesToolbarButtons';
import { messageButtons } from '../../constants/messageTypes';
import styling from './styling';

const useStyles = makeStyles(styling);

function CreateSMSType() {
  const classes = useStyles();
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [smsTemplatePreview, setSmsTemplatePreview] = useState(state?.template || '');
  const [smsTypeId] = useState(state?._id || null);
  const [isFormValidationError, updateIsFormValidationError] = useState(!state?._id);
  const [smsType, setSmsType] = useState(clone(state));
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState(clone(state)?.types || [])

  const isValidationError = useMemo(() => !smsTemplatePreview?.trim()?.length || !smsType?.name?.trim()?.length || isFormValidationError
    , [isFormValidationError, smsTemplatePreview, smsType?.name])

  const isDisabled = useMemo(() => {
    return isLoading || isValidationError
  }, [isLoading, isValidationError])

  const handleUpdateSmsType = async () => {
    const res = await updateSmsType(
      { ...smsType, name: smsType.name?.trim(), template: smsTemplatePreview, _id: smsTypeId },
      dispatch
    );
    if (res.status) {
      dispatch(openSnackbar({ message: 'SMS type updated', type: SNACKBAR_TYPES.SUCCESS }));
    } else {
      dispatch(openSnackbar({ message: res.message || 'Updating SMS type failed', type: SNACKBAR_TYPES.ERROR }));
    }
    return res;
  }

  const handleCreateSmsType = async () => {
    const res = await createSmsType({ ...smsType, name: smsType.name?.trim(), template: smsTemplatePreview }, dispatch);

    if (res.status) {
      dispatch(openSnackbar({ message: 'SMS type created', type: SNACKBAR_TYPES.SUCCESS }));
    } else {
      dispatch(openSnackbar({ message: res.message || 'Creating SMS type failed', type: SNACKBAR_TYPES.ERROR }));
    }
    return res;
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    if (isFormValidationError || !validateTypes(smsType?.types)) {
      setIsLoading(false);
      return dispatch(openSnackbar({ message: 'There are some errors please check', type: SNACKBAR_TYPES.ERROR }));
    }
    let res;
    smsTypeId ? res = await handleUpdateSmsType() : res = await handleCreateSmsType();
    if (res.status) goBack();
    setIsLoading(false);
  }

  const updateTypesHandler = (selectedType, valueIds = []) => {
    const updatedTypes = smsType?.types?.map(type => {
      return type.id === selectedType?.parent ? { ...type, dependant: [...(type?.dependant || []), { child: selectedType.id, criteria: valueIds }] } : type;
    }) || [];
    setSmsType(prev => ({ ...prev, types: [...updatedTypes, selectedType] }));
  }

  const goBack = () => history.replace('/sms-type');

  return <Grid container spacing={2} className={classes.container}>
    <Grid item container xs={12} md={8} className={classes.card}>
      <Card className={classes.card}>
        <SMSTypeCardHeader
          title={'SMS Type'}
          titleNumber={1}
          action={<TypesToolbarButtons buttons={messageButtons} onClick={updateTypesHandler} />}
        />
        <Grid item className={classes.smsForm}>
          <SMSTypeForm onSmsTypeChange={setSmsType} editSmsType={smsType} updateError={updateIsFormValidationError} setTags={setTags} />
        </Grid>
      </Card>
    </Grid>
    <Grid item container xs={12} md={4} className={classes.card}>
      <Card className={classes.card}>
        <SMSTypeCardHeader title={'Template Builder'} titleNumber={2} />
        <Grid item className={classes.previewContainer}>
          <SMSTemplatePreview smsTemplate={smsTemplatePreview} setSmsTemplate={setSmsTemplatePreview} tags={tags} />
          <Grid container spacing={1} className={classes.submitContainer}>
            <Grid item>
              <Button variant="outlined" disabled={isLoading} onClick={goBack}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" disabled={isDisabled} onClick={handleSubmit}>
                {!isLoading && 'SAVE'}
                {isLoading && <CircularProgress size={24} color="inherit" />}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  </Grid>
}

export default CreateSMSType;