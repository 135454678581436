import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setRoles,
  setServiceProvider,
} from "../../store/userManagement";
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
  DialogContent,
  Button,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";

const selectListContainerHeight = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: 0,
    paddingTop: "1%",
    "& .MuiDropzoneArea-text.MuiTypography-root": {
      margin: "24px 0px",
    },
  },
  heading: {
    fontWeight: "bold",
    color: theme.palette.primary.dark,
  },
  dialogAction: {
    padding: "16px",
    "& > :not(:first-child)": {
      marginLeft: "24px",
    },
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const UserManagementFilterDialog = ({
  open,
  handleClose,
  searchOnValueChange
}) => {
  const { limit, rolesFilterList, serviceProviderFilterList } = useSelector((state) => state.userManagement);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [isFilterButtonDisabled, setIsFilterButtonDisabled] = useState(true);

  const onRolesChange = (event) => {
    setSelectedRoles(event.target.value);
    setIsFilterButtonDisabled(false);
  }

  const onServiceProviderChange = (event) => {
    setSelectedServiceProviders(event.target.value);
    setIsFilterButtonDisabled(false);
  }

  const applyFilters = () => {
    const rolesIds = selectedRoles.map(
      (roleIndex) => rolesFilterList[roleIndex]._id
    );
    dispatch(setRoles(rolesIds));
    dispatch(setServiceProvider(selectedServiceProviders));
    handleClose();
    searchOnValueChange(limit, rolesIds, selectedServiceProviders);
    if (!selectedServiceProviders.length && !selectedRoles.length) {
      setIsFilterButtonDisabled(true);
    }
  };

  const handleReset = () => {
    setSelectedServiceProviders([]);
    setSelectedRoles([]);
    setIsFilterButtonDisabled(true);
    dispatch(setRoles([]));
    dispatch(setServiceProvider([]));
    handleClose();
    dispatch(setRoles([]));
    dispatch(setServiceProvider([]));
    searchOnValueChange(limit, [], []);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle disableTypography style={{ padding: "16px 24px 0px 24px" }}>
        <Grid container alignItems="center">
          <Typography variant="h6" className={classes.heading}>
            Advance Filters
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          spacing={1}
          className={classes.container}
        >
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="service-provider-filter-label">
                Service Provider
              </InputLabel>
              <Select
                value={selectedServiceProviders}
                onChange={onServiceProviderChange}
                multiple
                renderValue={(data) => (
                  <div className={classes.ellipsis}>
                    {data?.map((item) => item)?.join(", ")}
                  </div>
                )}
                labelId="service-provider-filter-label"
                id="service-provider-filter"
              >
                {serviceProviderFilterList?.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox
                      checked={selectedServiceProviders.indexOf(option) > -1}
                    />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="roles-filter-label">Roles</InputLabel>
              <Select
                value={selectedRoles}
                onChange={onRolesChange}
                multiple
                renderValue={(data) => (
                  <div className={classes.ellipsis}>
                    {data
                      ?.map((item) => rolesFilterList[item]?.displayName)
                      .join(", ")}
                  </div>
                )}
                labelId="roles-filter-label"
                id="roles-filter"
                MenuProps={selectListContainerHeight}
              >
                {rolesFilterList?.map((option, index) => (
                  <MenuItem key={option._id} value={index}>
                    <Checkbox checked={selectedRoles.indexOf(index) > -1} />
                    <ListItemText primary={option.displayName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          style={{ margin: "30px 0px 20px 0px" }}
          className={classes.container}
        >
          <Button
            onClick={handleClose}
            size="medium"
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Close
          </Button>
          <Button
            disabled={isFilterButtonDisabled}
            onClick={handleReset}
            size="medium"
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Reset
          </Button>
          <Button
            disabled={isFilterButtonDisabled}
            variant="contained"
            color="primary"
            onClick={applyFilters}
          >
            Apply Filters
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UserManagementFilterDialog;
