import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { CardContent, Grid, CircularProgress, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { emailFormValidation } from '../../validations';
import EmailUserDetails from '../../components/EmailForm/EmailUserDetails';
import EmailPreview from '../../components/EmailForm/EmailPreview';
import { generateEmailBody, generateEmailSubject } from '../../utils/generateEmailText';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import { getEmailTypes } from '../../store/emailTypes';
import sendEmailService from '../../services/sendEmailService';
import styling from './styling';
import { validateTypeValues } from '../GenerateSMSForm/GenerateSMS';

const validationSchema = yup.object(emailFormValidation());

const useStyles = makeStyles((theme) => ({
  ...styling(theme)
}));

function GenerateEmail() {
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');
  const emailTypes = useSelector((state) => state.emailTypes.allowedTypes);
  const user = useSelector((state) => state.auth.user);
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [isCleared, setIsCleared] = useState(true);
  const [isFetching, setIsFetching] = useState(!emailTypes?.length)
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      emailType: -1,
      typeValues: [],
      to: [],
      cc: []
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        const response = await sendEmailService({
          receiverEmail: values.to,
          cc: values.cc,
          emailTypeId: emailTypes[values.emailType]._id,
          emailTypeName: emailTypes[values.emailType].name,
          senderName: user.name,
          senderId: user.id,
          senderEmail: user.email,
          metadata: { values: values.typeValues, email: values.email, types: emailTypes[values.emailType].types },
          body,
          subject
        })
        if (!response.status)
          dispatch(openSnackbar({
            message: response.message || 'There is an error sending SMS',
            type: SNACKBAR_TYPES.ERROR
          }))
        else {
          dispatch(openSnackbar({
            message: response.message,
            type: SNACKBAR_TYPES.SUCCESS
          }))
          setIsSendDisabled(true);
          setIsCleared(false);
        }
      }
    }
  })

  function handleReset() {
    setIsSendDisabled(true);
    setIsCleared(true);
    setSubject('')
    setBody('');
    formik.handleReset();
  }

  useEffect(() => {
    if (!emailTypes.length) {
      (async () => {
        await getEmailTypes(dispatch);
        setIsFetching(false)
      })()
    }
  }, [dispatch, emailTypes?.length]);

  useEffect(() => {
    if (formik.isValid && validateTypeValues(emailTypes[formik.values.emailType]?.types, formik.values.typeValues)) {
      setSubject(generateEmailSubject(emailTypes[formik.values.emailType], formik.values.typeValues));
      setBody(generateEmailBody(emailTypes[formik.values.emailType], formik.values.typeValues));
      if (isCleared && body) setIsSendDisabled(false);
      else setIsSendDisabled(true);
    } else if (!formik.isValid && !validateTypeValues(emailTypes[formik.values.emailType]?.types, formik.values.typeValues)) {
      setSubject('')
      setBody('');
      setIsSendDisabled(true);
    }
  }, [formik, isCleared, body, emailTypes]);

  useEffect(() => {
    if (formik.values.emailType >= 0) {
      formik.setFieldValue('typeValues', emailTypes[formik.values.emailType]?.types?.map(type => ''), true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.emailType]);

  return (
    <Grid className={classes.homeContainer}>
      <Grid
        container
        className={classes.fullHeightWidth}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container className={classes.overflowAuto}>
          <form onSubmit={formik.handleSubmit} id="form" className={classes.widthAndHeight}>
            {
              isFetching && <Grid
                container
                className={classes.loaderBackground}
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <CircularProgress color="primary" />
                <Typography variant="button" color="primary">Loading Email Types</Typography>
              </Grid>
            }
            {!isFetching && <Grid className={classes.cardContent}>
              <Grid item container xs={12} md={6}>
                <CardContent className={classes.cardFormSection}>
                  <EmailUserDetails
                    emailTypes={emailTypes}
                    formik={formik}
                  />
                </CardContent>
              </Grid>
              <Grid item container xs={12} md={6}>
                <CardContent className={classes.cardFormSection}>
                  <EmailPreview
                    isSubmitting={formik.isSubmitting}
                    body={body}
                    subject={subject}
                    handleReset={handleReset}
                    isSendDisabled={isSendDisabled}
                  />
                </CardContent>
              </Grid>
            </Grid>}
          </form>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default GenerateEmail;
