import { useEffect } from 'react';
import { useLocation, matchPath, useParams } from 'react-router-dom';
import routes from '../constants/routes';

function Page({ children }) {
  const { pathname } = useLocation();
  const params = useParams();

  useEffect(() => {
    const { title } = routes.find(el => matchPath(pathname, { path: el.path, exact: true }));
    document.title = `${title(params)} - My Aged Care`;
  }, [pathname, params])

  return children
}

export default Page;