import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  ListItemText,
  Checkbox
} from "@material-ui/core";
import { messageButtons } from '../../constants/messageTypes';
import uuidGenerator from "../../utils/uuidGenerator";


const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  dialogAction: {
    padding: '16px',
    '& > :not(:first-child)': {
      marginLeft: '24px'
    }
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

function DependentTypeDialogBox({ open, handleClose, options = [], id, handleSelectDependant }) {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const [isAddDisabled, setIsAddDisabled] = useState(true);

  const handleSetSelectedTypes = (event) => {
    setSelectedType(event.target.value);
  }

  const handleSetSelectedValues = (event) => {
    setSelectedValues(event.target.value);
  }

  useEffect(() => {
    if (selectedType >= 0 && selectedValues.length > 0) {
      setIsAddDisabled(false);
    } else {
      setIsAddDisabled(true);
    }
  }, [selectedType, selectedValues.length]);

  const close = () => {
    handleClose();
    setSelectedType('');
    setSelectedValues([]);
  }

  const handleAdd = () => {
    handleSelectDependant(
      {
        ...messageButtons?.[selectedType].data,
        id: uuidGenerator(),
        parent: id
      },
      selectedValues?.map(value => options?.[value].id),
    )
    close();
  }

  return <Dialog
    open={open}
    onClose={close}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle disableTypography>
      <Grid container alignItems="center">
        <Typography variant="h6" className={classes.heading}>Select Dependent Types</Typography>
      </Grid>
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid container item>
          <TextField
            id="types"
            select
            value={selectedType}
            onChange={handleSetSelectedTypes}
            fullWidth
            size="small"
            label="Select Type"
            variant="filled"
            SelectProps={{
              MenuProps: { PaperProps: { style: { maxHeight: 300 } } },
            }}
          >
            {messageButtons.map((option, index) => (
              <MenuItem key={index} value={index}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container item>
          <TextField
            id="values"
            select
            value={selectedValues || []}
            onChange={handleSetSelectedValues}
            fullWidth
            size="small"
            label="Select Options"
            variant="filled"
            SelectProps={{
              MenuProps: { PaperProps: { style: { maxHeight: 300 } } },
              multiple: true,
              renderValue: (data) => <div className={classes.ellipsis}>{data.map(item => options[item].label).join(', ')}</div>
            }}
          >
            {options?.length > 0 ? options.map((option, index) => (
              <MenuItem key={index} value={index}>
                <Checkbox checked={selectedValues.indexOf(index) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            )) : <MenuItem disabled>
              <ListItemText primary="Please add more options" />
            </MenuItem>}
          </TextField>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions className={classes.dialogAction}>
      <Button onClick={close} size="medium" variant="outlined" color="primary">
        Close
      </Button>
      <Button onClick={handleAdd} size="medium" variant="contained" color="primary" disabled={isAddDisabled}>
        Add
      </Button>
    </DialogActions>
  </Dialog>
}

export default DependentTypeDialogBox;
