import { createSlice } from '@reduxjs/toolkit';
import emailTypesReducers from './emailTypesReducers';
import {
  fetchEmailType,
  deleteEmailType as deleteEmailTypeService,
  createEmailType as createEmailTypeService,
  updateEmailType as updateEmailTypeService
} from '../../services/emailTypeService';

export const initialState = {
  types: [],
  allowedTypes: []
};

export const emailTypesSlice = createSlice({
  name: 'emailTypes',
  initialState,
  reducers: { ...emailTypesReducers },
});

export const { emailTypes, resetEmailTypes, allowedEmailTypes } = emailTypesSlice.actions;

export const getEmailTypes = async (dispatch) => {
  try {
    const data = await fetchEmailType();
    if (!data?.status) throw new Error('Failed fetching Email types');

    dispatch(emailTypes(data.data));
    dispatch(allowedEmailTypes(data.data.filter(type => type?.status === 'ACTIVE')))
    return { status: true, message: "Email Types fetched successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const deleteEmailType = async (emailTypeId) => {
  try {
    const data = await deleteEmailTypeService({ emailTypeId });
    if (!data?.status) throw new Error('Failed deleting Email type');

    return { status: true, message: "Email Type deleted successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const createEmailType = async (payload, dispatch) => {
  try {
    const data = await createEmailTypeService(payload);
    if (!data?.status) throw data;

    await getEmailTypes(dispatch);
    return { status: true, message: "Email Type created successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const updateEmailType = async (payload, dispatch) => {
  try {
    const data = await updateEmailTypeService(payload);
    if (!data?.status) throw data;

    await getEmailTypes(dispatch);
    return { status: true, message: "Email Type updated successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export default emailTypesSlice.reducer;
