import React, { useState } from 'react';
import { Grid, Card, CardHeader, CardContent, TextField, Divider, Button, CircularProgress, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { changePasswordValidations } from '../../validations';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { changeTempPassword } from '../../store/auth';
import { primaryColorLink } from '../../constants/linkClasses';
import './ChangePassword.scss';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';

const validationSchema = yup.object(changePasswordValidations());

function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const email = useSelector((state) => state.auth.changePasswordEmail);
  const history = useHistory();
  const dispatch = useDispatch();
  
  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: '',
      newPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      if (formik.isValid) {
        const response = await changeTempPassword({
          email: values.email,
          password: values.password,
          newPassword: values.newPassword,
        })(dispatch);
        if (response.status) {
          dispatch(openSnackbar({
            message: 'Password successfully changed',
            type: SNACKBAR_TYPES.SUCCESS
          }));
          return history.push('/dashboard');
        }
        dispatch(openSnackbar({
          message: response.message,
          type: SNACKBAR_TYPES.ERROR
        }));
      }
      setIsLoading(false);
    }
  });

  return (
    <Grid container justifyContent="center" alignContent="center" className="change-password-container">
      <Card className="change-password-card">
        <CardHeader title="Change Password" />
        <Divider />
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography>You signed in with a temporary password please change your password to continue.</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  disabled={Boolean(email)}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  label="Temp Password"
                  type="password"
                  fullWidth
                  required
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="newPassword"
                  label="New Password"
                  type="password"
                  fullWidth
                  required
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  helperText={formik.touched.newPassword && formik.errors.newPassword}
                />
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={isLoading} fullWidth>
                  {!isLoading && 'Change Password'}
                  {isLoading && <CircularProgress size={24} color="inherit" />}
                </Button>
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Link color="primary" to="/" className={primaryColorLink}>Already changed password? Login</Link>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ChangePassword;
