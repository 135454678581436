import React from 'react';
import { Grid, TextField, MenuItem, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SvgIcon from '../SvgIcons';
import iconNames from '../../constants/iconNames';
import styling from './styling';
import AddressSMSType from './AddressSmsType';

const useStyles = makeStyles(theme => ({
  ...styling(theme),
  container: {
    width: '100%',
    height: '100%',
  },
  headingContainer: {
    height: 40
  },
  smsTypesContainer: {
    width: '100%',
    padding: '0px',
    paddingRight: 20
  },
  smsTypeBoxHeight:{
    margin: '36px 0px',
    overflow: 'hidden',
    display:'flex',
    alignItems:'start',
    height: 'calc(100% - 50px)',
    [theme.breakpoints.up("md")]: {
      height: 'calc(100% - 50px)',
      overflowY: 'auto',
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: '100%',
      overflow: 'hidden'
    },
  },
  inputIcon: {
    marginBottom: 20,
    padding: '4px'
  }
}));

export const getIsValidParentValue = (types = [], parentId, childId, childIndex, formik) => {
  const parentIndex = types?.findIndex(type => type?.id === parentId);
  const parent = types?.[parentIndex];
  const parentValueId = parent?.options?.find(option => option.value === formik.values?.typeValues?.[parentIndex])?.id;
  const isValidParentValue = parent?.dependant.findIndex(item => item.child === childId && item?.criteria?.includes(parentValueId)) > -1;
  if (!isValidParentValue) {
    formik.values?.typeValues?.splice(childIndex, 1, undefined);
    formik.touched?.typeValues?.splice(childIndex, 1, undefined);
  }
  return isValidParentValue;
}

function SMSUserDetails({ smsTypes, formik }) {
  const classes = useStyles();

  const isInvalid = (index) => {
    return formik.touched?.typeValues?.[index]
      && !!formik.errors?.typeValues?.[index];
  }

  const selectListContainerHeight = {
    MenuProps: {
      PaperProps: {
        style: {
          maxHeight: 300,
        }
      }
    }
  };

  return (
    <Grid className={classes.container}>
      <Grid item container display="flex" alignItems='center' spacing={1} className={classes.headingContainer}>
        <Grid item container sm={1} xs={2}>
          <Typography variant="h6" className={classes.heading}>
            <span className={classes.numberBox}>1</span>
          </Typography>
        </Grid>
        <Grid item sm={11} xs={10}>
          <Typography variant="h6" className={classes.heading} style={{ marginLeft: '5px' }}>
            Aged Care User and SMS Details
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.smsTypeBoxHeight}>
      <Grid container className={classes.smsTypesContainer}>
        <Grid item container alignItems="center">
          <Grid item container sm={1} xs={2} className={classes.marginBottom} >
            <SvgIcon color="primary" iconName={iconNames.Phone} />
          </Grid>
          <Grid item sm={11} xs={10}>
            <TextField
              label="Enter Phone Number"
              name="phone"
              variant="filled"
              margin="none"
              fullWidth
              size="small"
              autoComplete="off"
              value={formik.values.phone || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={(formik.touched.phone && formik.errors.phone) || ' '}
              className={classes.inputLabels}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item container sm={1} xs={2} className={classes.marginBottom} >
            <SvgIcon color="primary" iconName={iconNames.SMS} />
          </Grid>
          <Grid item sm={11} xs={10}>
            <TextField
              label={!!smsTypes?.length ? "Select SMS Type" : "No SMS Types available"}
              name="smsType"
              select
              variant="filled"
              fullWidth
              autoComplete="off"
              size="small"
              SelectProps={selectListContainerHeight}
              value={formik.values.smsType < 0 ? '' : formik.values.smsType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.smsType && Boolean(formik.errors.smsType)}
              helperText={(formik.touched.smsType && formik.errors.smsType) || ' '}
              disabled={!smsTypes?.length}
              className={classes.inputLabels}
            >
              {smsTypes.map((option, index) => (
                <MenuItem key={index} value={index}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {
          formik.values.smsType >= 0 && smsTypes[formik.values.smsType]?.types.map((smsType, index) => {
            if (!!smsType?.parent && !getIsValidParentValue(smsTypes[formik.values.smsType]?.types, smsType?.parent, smsType?.id, index, formik)) {
              return null;
            }
            if (smsType?.type === 'Text') {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid item container sm={1} xs={2} className={classes.marginBottom}>
                    <SvgIcon color="primary" iconName={iconNames.Alphabets} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={smsType?.label}
                      title={smsType?.label}
                      placeholder={smsType?.placeholder || ''}
                      name={`typeValues[${index}]`}
                      variant="filled"
                      fullWidth
                      autoComplete="off"
                      value={formik.values.typeValues[index] || ''}
                      onChange={formik.handleChange}
                      size="small"
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={(isInvalid(index) && `${smsType?.label} ${formik.errors?.typeValues[index]}`) || ' '}
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              )
            } else if (smsType?.type === 'TextArea') {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid item container sm={1} xs={2} className={classes.marginBottom}>
                    <SvgIcon color="primary" iconName={iconNames.TextArea} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={smsType?.label}
                      title={smsType?.label}
                      placeholder={smsType?.placeholder || ''}
                      variant="filled"
                      name={`typeValues[${index}]`}
                      fullWidth
                      multiline
                      minRows={5}
                      maxRows={5}
                      autoComplete="off"
                      value={formik.values.typeValues[index] || ''}
                      onChange={formik.handleChange}
                      size="small"
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={(isInvalid(index) && `${smsType?.label} ${formik.errors?.typeValues[index]}`) || ' '}
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              )
            } else if (smsType?.type === 'Number') {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid item container sm={1} xs={2} className={classes.marginBottom}>
                    <SvgIcon color="primary" iconName={iconNames.Numbers} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={smsType?.label}
                      title={smsType?.label}
                      placeholder={smsType?.placeholder || ''}
                      variant="filled"
                      name={`typeValues[${index}]`}
                      fullWidth
                      type="number"
                      autoComplete="off"
                      value={formik.values.typeValues[index] || ''}
                      onChange={formik.handleChange}
                      size="small"
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={(isInvalid(index) && `${smsType?.label} ${formik.errors?.typeValues[index]}`) || ' '}
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              )
            } else if (smsType?.type === 'Referral') {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid item container sm={1} xs={2} className={classes.marginBottom} >
                    <SvgIcon color="primary" iconName={iconNames.Assignment} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={smsType?.label}
                      title={smsType?.label}
                      placeholder={smsType?.placeholder || ''}
                      variant="filled"
                      fullWidth
                      autoComplete="off"
                      name={`typeValues[${index}]`}
                      value={formik.values.typeValues[index] || ''}
                      onChange={formik.handleChange}
                      size="small"
                      InputProps={{
                        startAdornment: smsType?.prefix && <InputAdornment position="start">{smsType?.prefix}</InputAdornment>,
                      }}
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={(isInvalid(index) && `${smsType?.label} ${formik.errors?.typeValues[index]}`) || ' '}
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              )
            } else if (smsType?.type === 'Dropdown') {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid item container sm={1} xs={2} className={classes.marginBottom}>
                    <SvgIcon color="primary" iconName={iconNames.ArrowDown} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <TextField
                      label={smsType?.label}
                      title={smsType?.label}
                      placeholder={smsType?.placeholder || ''}
                      select
                      variant="filled"
                      name={`typeValues[${index}]`}
                      fullWidth
                      autoComplete="off"
                      value={formik.values.typeValues[index] || ''}
                      onChange={formik.handleChange}
                      size="small"
                      SelectProps={selectListContainerHeight}
                      onBlur={formik.handleBlur}
                      error={isInvalid(index)}
                      helperText={(isInvalid(index) && `${smsType?.label} ${formik.errors?.typeValues[index]}`) || ' '}
                      className={classes.inputLabels}
                    >
                      {smsType?.options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              )
            } else if (smsType?.type === 'Address') {
              return (
                <Grid item container alignItems="center" key={index}>
                  <Grid item container sm={1} xs={2} >
                    <SvgIcon color="primary" iconName={iconNames.Location} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <AddressSMSType
                      onChange={(value) => formik.setFieldValue(`typeValues[${index}]`, value, true)}
                      type='SMS'
                      className={classes.inputLabels}
                    />
                  </Grid>
                </Grid>
              )
            }
            return null;
          })
        }
      </Grid>
      </Grid>
    </Grid>
  )
}

export default SMSUserDetails;