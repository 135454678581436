import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, matchPath } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, IconButton, CircularProgress, Avatar, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router';
import { logout } from '../../store/auth';
import routes from '../../constants/routes';
import { Menu } from '@material-ui/icons';
import SvgIcon from '../../components/SvgIcons';
import iconNames from '../../constants/iconNames';
import HealthDirectLogo from '../../assets/icons/health_direct_logo.png';
import AustralianGovLogo from '../../assets/icons/australian_gov_logo.png';
import AgedCareLogo from '../../assets/icons/aged_care_logo.png';
import styling from './styling';
import extractInitialsFromName from '../../utils/exctractInitialsFromName';

const useStyles = makeStyles((theme) => styling(theme));

function Headers({ toggleDrawer, isOpen, isSideBarVisible, params }) {
  const defaultHeader = "My Aged Care Platform";
  const [header, setHeader] = useState(defaultHeader);
  const [iconName, setIconName] = useState('default');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const name = useSelector((state) => state.auth.user?.name || '');
  const initials = useSelector((state) => extractInitialsFromName(state.auth.user?.name || ''));
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    setHeader(routes.find(el => matchPath(pathname, { path: el.path, exact: true }))?.header(params) || defaultHeader);
    setIconName(routes.find(el => matchPath(pathname, { path: el.path, exact: true }))?.iconName(params));
  }, [pathname, params])

  const logoutUser = async () => {
    setIsButtonLoading(true);
    await logout()(dispatch);
    localStorage.removeItem('notesData');
    localStorage.removeItem('notesTemplate');
    setIsButtonLoading(false);
  }

  function handleState() {
    if (!isLoggedIn) {
      history.push("/");
    } else {
      logoutUser();
    }
  }

  function handleLogoClick() {
    history.push("/dashboard");
  }

  return (
    <Grid container>
      {isSideBarVisible && <Grid item container alignContent="center" justifyContent="center" xs={Number(isSideBarVisible)}>
        <IconButton onClick={toggleDrawer(!isOpen)}><Menu className={classes.menu} /></IconButton>
      </Grid>}
      <Grid
        item
        container
        alignContent="center"
        justifyContent="space-between"
        xs={12 - Number(isSideBarVisible)}
        className={clsx({ [classes.headerShifted]: !isSideBarVisible, [classes.header]: isSideBarVisible })}
      >
        <Grid item container alignItems="center" justifyContent="flex-start" md={5} xs={9}>
          <Typography className={classes.headerTitle}>
            {iconName && iconName.startsWith('data:image/')
              ? <img src={iconName} alt="icon" className={classes.imageContainerWrapper}/>
              : <SvgIcon iconName={iconName} />}
          </Typography>
          <Typography className={classes.headerTitle}>
            {header}
          </Typography>
        </Grid>
        <Grid item className={classes.accountContainer} md={7} xs={3}>
          <span className={classes.logoImageShowLargeScreen}>
            <img src={HealthDirectLogo} alt="Logo" className={classes.logo} onClick={handleLogoClick}></img>
            <img src={AustralianGovLogo} alt="Logo" className={classes.logo} onClick={handleLogoClick}></img>
            <img src={AgedCareLogo} alt="Logo" className={classes.logo} onClick={handleLogoClick}></img>
          </span>
        
          {!isLoggedIn && <Tooltip title="Login">
            <span>
              <Button
                onClick={handleState}
                className={classes.accountButton}
                disabled={isButtonLoading}
                startIcon={
                  !isButtonLoading && <SvgIcon iconName={iconNames.User} />
                }
              >
                {isButtonLoading && <CircularProgress size={24} color="primary" />}
              </Button>
            </span>
          </Tooltip>}
          {isLoggedIn && <Tooltip title={isButtonLoading ? 'Logging out..' : 'Logout'}>
            <span>
              <Button
                onClick={handleState}
                className={classes.accountButton}
                disabled={isButtonLoading}
                startIcon={
                  !isButtonLoading && initials
                    ? <Avatar className={classes.account}>{initials}</Avatar> : <SvgIcon iconName={iconNames.User} />
                }
              >
                {isButtonLoading && <CircularProgress size={24} color="primary" />}
              <span className={classes.userNameLargeScreen}>{!isButtonLoading && name} </span>
              <span className={classes.userNameSmallScreen}>{!isButtonLoading && name} </span>
              </Button>
            </span>
          </Tooltip>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Headers;