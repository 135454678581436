import { createSlice } from '@reduxjs/toolkit';
import userManagementReducers from './userManagementReducers';
import getAllUsersService from '../../services/getAllUsers';
import createAndDownloadCSV from '../../utils/createAndDownloadCSV';
import downloadUsersService from '../../services/downloadUsersReport';

export const initialState = {
  users: {},
  count: 0,
  page: 0,
  limit: 10,
  search: '',
  roles: [],
  serviceProvider: [],
  rolesFilterList: [],
  serviceProviderFilterList: [],
};

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: { ...userManagementReducers },
});

export const { saveUsers, resetUserManagement, setLimit, setPage, setSearch, setRoles, setServiceProvider, emptyUsers } = userManagementSlice.actions;

export const getUsers = (query) => async (dispatch) => {
  try {
    const data = await getAllUsersService({ ...query, page: query.page + 1 });
    if (!data) throw new Error('Failed fetching users');
    dispatch(saveUsers(data));
    return { status: true, message: "Users fetched successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const exportUsersAsCsv = (users) => {
  const usersList = [
    ['NAME', 'EMAIL', 'SERVICE PROVIDER', 'ROLES', 'SMS PERMISSIONS', 'EMAIL PERMISSIONS']
  ]
  users.forEach(el => {
    usersList.push([
      el?.name,
      el?.email,
      el?.serviceProvider,
      `"${el?.roles?.map(({ role }) => role)?.join(', ')}"`,
      `"${el?.smsPermissions.join(', ')}"`,
      `"${el?.emailPermissions.join(', ')}"`
    ]);
  });
  createAndDownloadCSV(usersList, 'users_list');
}

export const exportFailedUsersAsCsv = (users) => {
  const usersList = [
    ['NAME', 'EMAIL', 'REASON']
  ]
  users.forEach(el => {
    usersList.push([el?.user?.name, el?.user?.email, el?.errorMessage]);
  });
  createAndDownloadCSV(usersList, 'failed_users_list');
}

export const downloadUsers = (query = {}) => async () => {
  try {
    const taskId = await downloadUsersService(query);
    if (!taskId) throw new Error('Failed Downloading users');
    return { status: true, message: "Users sheet generation started successfully", taskId };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export default userManagementSlice.reducer;
