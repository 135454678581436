import request from "../utils/request";

const downloadUsersEndpoint = `${process.env.REACT_APP_BASE_URL}/download-users`;

const downloadUsersService = async (filters = {}) => {
    try {
        const response = await request({
            url: downloadUsersEndpoint,
            method: 'POST',
            data: filters
        });
        return response.data?.taskId;
    } catch (error) {
        return false;
    }
}

export default downloadUsersService;
