import { Route, Redirect } from 'react-router-dom';
import Page from '../components/Page';
import checkRouteForRole from './checkRouteForRole';

const getComponentForRoutes = ({ isLoggedIn, groups, params }, { Component, isProtected, path, role, roleName }) => {
  if (isLoggedIn && !isProtected) return <Route exact path={path} key={path} ><Redirect to="/dashboard" /></Route>;
  else if (!isLoggedIn && isProtected) return <Route exact path={path} key={path} ><Redirect to="/" /></Route>;
  else if (isLoggedIn && isProtected && role && checkRouteForRole(groups, roleName(params))) return <Route exact path={path} key={path} ><Page><Component params={params} /></Page></Route>;
  else if (isLoggedIn && isProtected && role && !checkRouteForRole(groups, roleName(params))) return <Route exact path={path} key={path} ><Redirect to="/dashboard" /></Route>;
  else return <Route exact path={path} key={path} ><Page><Component params={params} /></Page></Route>;
}

export default getComponentForRoutes;
