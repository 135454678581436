import axios from 'axios';
import { Auth } from 'aws-amplify';
const baseURL = process.env.REACT_APP_BASE_URL;

const request = axios.create({
  baseURL,
});

request.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  config.headers.Authorization = token;
  return config
});

export default request;
