import * as Yup from 'yup';
import validateNumber from '../utils/validatePhoneNumber';
import validateEmailAddress from '../utils/validateEmail'

export const formValidation = () => {
  return {
    phone: Yup.string()
      .required('Phone number can not be empty')
      .test(
        'phone',
        'Phone number is not valid',
        (val) => validateNumber(val)
      ),

    smsType: Yup.number()
      .required('Please select a sms type')
      .moreThan(-1, 'Please select a sms type'),

    // typeValues: Yup.array().of(
    //   Yup.string()
    //     .required('can not be empty')
    // )
  }
}

export const emailFormValidation = () => {
  return {
    to: Yup.array().of(
      Yup.string()
        .required('To can not be empty')
        .test(
          'email',
          'Some of the emails are not valid',
          (email) => validateEmailAddress(email))
    ).min(1, 'To is required'),
    cc: Yup.array().of(
      Yup.string()
        .required('CC can not be empty')
        .test(
          'email',
          'Some of the emails are not valid',
          (email) => validateEmailAddress(email))
    ),
    emailType: Yup.number()
      .required('Please select a email type')
      .moreThan(-1, 'Please select a email type'),
    // typeValues: Yup.array().of(
    //   Yup.string()
    //     .required('can not be empty')
    // )
  }
}

export const forgotPasswordValidations = () => {
  return {
    email: Yup.string()
      .required('Email can not be empty')
      .email('Not a valid email'),

    code: Yup.string()
      .required('Code can not be empty'),

    newPassword: Yup.string()
      .required('Password can not be empty')
      .min(16, 'Password must be min 16 characters long.')
      .test(
        'newPassword',
        'Password must have at least one number, one lowercase, one uppercase letter and should contain one special character',
        (val) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(val)
      ),

    confirmNewPassword: Yup.string()
      .required('Confirm password can not be empty')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords does not match')
  }
}

export const changePasswordValidations = () => {
  return {
    email: Yup.string()
      .required('Email can not be empty')
      .email('Not a valid email'),

    password: Yup.string()
      .required('Password can not be empty'),

    newPassword: Yup.string()
      .required('Confirm password can not be empty')
      .min(16, 'Password must be min 16 characters long.')
      .test(
        'newPassword',
        'Password must have at least one number, one lowercase, one uppercase letter and should contain one special character',
        (val) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(val)
      ),
  }
}