import React, { useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Grid,
  Card,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import EmailTypeForm from "../../components/EmailType/EmailTypeForm";
import EmailTemplatePreview from "../../components/EmailType/EmailTemplatePreview";
import { openSnackbar } from "../../store/snackbar";
import SNACKBAR_TYPES from "../../constants/snackBarTypes";
import { clone } from "../../utils/clone";
import { createEmailType, updateEmailType } from "../../store/emailTypes";
import validateTypes from "../../utils/validateTypes";
import styling from "../CreateSMSType/styling";
import SMSTypeCardHeader from "../../components/SMSType/SMSTypeCardHeader";
import TypesToolbarButtons from "../../components/MessageTemplateItems/TypesToolbarButtons";
import { messageButtons } from "../../constants/messageTypes";

const useStyles = makeStyles({
  ...styling,
  emailInputContainer: {
    overflow: 'auto',
  },
  emailPreviewActionContainer: {
    marginTop: 10
  }
});

function CreateEmailType() {
  const classes = useStyles();
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailTemplatePreview, setEmailTemplatePreview] = useState(
    state?.template || ""
  );
  const [emailTypeId] = useState(state?._id || null);
  const [isFormValidationError, updateIsFormValidationError] = useState(
    !state?._id
  );
  const [emailType, setEmailType] = useState(clone(state));
  const [isLoading, setIsLoading] = useState(false);
  const [emailTypeSubject, updateEmailTypeSubject] = useState(
    state?.subject || ""
  );
  const [to, setTo] = useState(state?.to || []);
  const [cc, setCc] = useState(state?.cc || []);
  const [isToEditable, setIsToEditable] = useState(typeof state?.isToEditable === 'undefined' ? true : state?.isToEditable);
  const [isCcEditable, setIsCcEditable] = useState(typeof state?.isCcEditable === 'undefined' ? true : state?.isCcEditable);
  const [canRemovePreFilledToEmails, setCanRemovePreFilledToEmails] = useState(state?.canRemovePreFilledToEmails);
  const [canRemovePreFilledCcEmails, setCanRemovePreFilledCcEmails] = useState(state?.canRemovePreFilledCcEmails);
  const [tags, setTags] = useState(clone(state)?.types || []);

  const isValidationError = useMemo(
    () =>
      !emailTemplatePreview?.trim()?.length ||
      !emailTypeSubject?.trim()?.length ||
      !emailType?.name?.trim()?.length ||
      isFormValidationError,
    [
      emailTemplatePreview,
      emailType?.name,
      emailTypeSubject,
      isFormValidationError,
    ]
  );

  const isDisabled = useMemo(() => {
    return isLoading || isValidationError;
  }, [isLoading, isValidationError]);

  const handleUpdateEmailType = async () => {
    const res = await updateEmailType(
      {
        ...emailType,
        name: emailType.name?.trim(),
        template: emailTemplatePreview,
        subject: emailTypeSubject,
        _id: emailTypeId,
        to,
        cc,
        isToEditable,
        isCcEditable,
        canRemovePreFilledCcEmails,
        canRemovePreFilledToEmails
      },
      dispatch
    );
    if (res.status) {
      dispatch(
        openSnackbar({
          message: "Email type updated",
          type: SNACKBAR_TYPES.SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: res.message || "Updating Email type failed",
          type: SNACKBAR_TYPES.ERROR,
        })
      );
    }
    return res;
  };

  const handleCreateEmailType = async () => {

    const res = await createEmailType(
      {
        ...emailType,
        name: emailType.name?.trim(),
        template: emailTemplatePreview,
        subject: emailTypeSubject,
        to,
        cc,
        isToEditable,
        isCcEditable,
        canRemovePreFilledCcEmails,
        canRemovePreFilledToEmails
      },
      dispatch
    );

    if (res.status) {
      dispatch(
        openSnackbar({
          message: "Email type created",
          type: SNACKBAR_TYPES.SUCCESS,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: res.message || "Creating Email type failed",
          type: SNACKBAR_TYPES.ERROR,
        })
      );
    }
    return res;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (isFormValidationError || !validateTypes(emailType?.types)) {
      setIsLoading(false);
      dispatch(
        openSnackbar({
          message: "There are some errors please check",
          type: SNACKBAR_TYPES.ERROR,
        })
      );
    }
    let res;
    emailTypeId
      ? (res = await handleUpdateEmailType())
      : (res = await handleCreateEmailType());
    if (res?.status) goBack();
    setIsLoading(false);
  };

  const updateTypesHandler = (selectedType, valueIds = []) => {
    const updatedTypes = emailType?.types?.map(type => {
      return type.id === selectedType?.parent ? { ...type, dependant: [...(type?.dependant || []), { child: selectedType.id, criteria: valueIds }] } : type;
    }) || [];
    setEmailType(prev => ({ ...prev, types: [...updatedTypes, selectedType] }));
  }

  const goBack = () => history.replace("/email-type");

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item container xs={12} md={7} className={classes.card}>
        <Card className={classes.card}>
          <SMSTypeCardHeader
            title={'Email Type'}
            titleNumber={1}
            action={<TypesToolbarButtons buttons={messageButtons} onClick={updateTypesHandler} />}
          />
          <Grid item className={classes.smsForm}>
            <EmailTypeForm
              onEmailTypeChange={setEmailType}
              editEmailType={emailType}
              updateError={updateIsFormValidationError}
              setTags={setTags}
            />
          </Grid>
        </Card>
      </Grid>
      <Grid item container xs={12} md={5} className={classes.card}>
        <Card className={classes.card}>
          <SMSTypeCardHeader title={'Template Builder'} titleNumber={2} />
          <Grid item className={`${classes.previewContainer} ${classes.emailInputContainer}`}>
            <EmailTemplatePreview
              emailTemplate={emailTemplatePreview}
              setEmailTemplate={setEmailTemplatePreview}
              emailTypeSubject={emailTypeSubject}
              updateEmailTypeSubject={updateEmailTypeSubject}
              updateError={updateIsFormValidationError}
              tags={tags}
              to={to}
              setTo={setTo}
              cc={cc}
              setCc={setCc}
              isToEditable={isToEditable}
              setIsToEditable={setIsToEditable}
              isCcEditable={isCcEditable}
              setIsCcEditable={setIsCcEditable}
              canRemovePreFilledCcEmails={canRemovePreFilledCcEmails}
              setCanRemovePreFilledCcEmails={setCanRemovePreFilledCcEmails}
              canRemovePreFilledToEmails={canRemovePreFilledToEmails}
              setCanRemovePreFilledToEmails={setCanRemovePreFilledToEmails}
            />
            <Grid container spacing={1} className={`${classes.submitContainer} ${classes.emailPreviewActionContainer}`}>
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  onClick={goBack}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isDisabled}
                  onClick={handleSubmit}
                >
                  {!isLoading && "SAVE"}
                  {isLoading && <CircularProgress size={24} color="inherit" />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CreateEmailType;
