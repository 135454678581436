const transformSearchResultsDocuments = (data) => {
  return {
    ...data,
    ...(data.contents && {
      contents: {
        ...data.contents,
        docs: data.contents?.docs.map(doc => {
          const titleSplitIndex = doc.title_en.indexOf('-');
          return {
            ...doc,
            ...(titleSplitIndex !== -1 && {
              title_en_first_part: doc.title_en.substr(0,titleSplitIndex).trim(),
              title_en_second_part: doc.title_en.substr(titleSplitIndex+1).trim()
            })
          }
        })
      }
    }) 
  }
};

export default transformSearchResultsDocuments;