export const generateEmailBody = (emailType, values = []) => {
  let generatedEmailBody = emailType?.template;
  emailType?.types?.forEach((type, index) => {
    if (type.type === 'Referral') {
      generatedEmailBody = generatedEmailBody.replaceAll(`{{${type.reference}}}`, `${type.prefix || ''}${values[index] || ''}`)
    } else {
      generatedEmailBody = generatedEmailBody.replaceAll(`{{${type.reference}}}`, values[index] || '')
    }
  });
  return generatedEmailBody;
};

export const generateEmailSubject = (emailType, values = []) => {
  let generatedSubject = emailType?.subject;
  emailType?.types?.forEach((type, index) => {
    if (type.type === 'Referral') {
      generatedSubject = generatedSubject.replaceAll(`{{${type.reference}}}`, `${type.prefix || ''}${values[index] || ''}`)
    } else {
      generatedSubject = generatedSubject.replaceAll(`{{${type.reference}}}`, values[index] || '')
    }
  });
  return generatedSubject;
};