import request from "../utils/request";

const loggedInUserEndPoint = `${process.env.REACT_APP_BASE_URL}/me`;

export const fetchLoggedInUserDetails = async () => {
  try {
    const response = await request({
      url: loggedInUserEndPoint,
      method: 'GET'
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data?.message,
      data: response.data?.data
    };
  } catch (error) {
    return { status: false, message: error.response.data.errorMessage || error.message };
  }
}
