import React from 'react';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import SNACKBAR_TYPES from '../constants/snackBarTypes';

const useStyles = makeStyles(theme => ({
  snackBar: {
    '& .MuiAlert-root.MuiAlert-standardSuccess': {
      color: theme.palette.common.white,
      backgroundColor: '#248d71',
    },
    '& .MuiAlert-root.MuiAlert-standardError': {
      color: theme.palette.common.white,
      backgroundColor: '#bf3d4e',
    },
    '& .MuiAlert-root.MuiAlert-standardWarning': {
      backgroundColor: theme.palette.warning.light,
      '& .MuiAlert-icon': {
        color: theme.palette.warning.dark
      }
    },
    '& .MuiAlert-standardSuccess .MuiAlert-icon': {
      color: theme.palette.common.white,
    },
    '& .MuiAlert-standardError .MuiAlert-icon': {
      color: theme.palette.common.white,
    },
  }
}));

function SnackBar({ open = false, message = '', type = SNACKBAR_TYPES.SUCCESS, onClose }) {
  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}
      className={classes.snackBar}
      autoHideDuration={6000}
    >
      <Alert onClose={onClose} severity={type}>{message}</Alert>
    </Snackbar>
  );
}

export default SnackBar;
