import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { Grid, Card, Button, makeStyles, CircularProgress } from '@material-ui/core';

import SMSTypeForm from '../../components/SMSType/SMSTypeForm';
import SMSTypeCardHeader from '../../components/SMSType/SMSTypeCardHeader';
import TypesToolbarButtons from '../../components/MessageTemplateItems/TypesToolbarButtons';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import { clone } from '../../utils/clone';
import { useSelector } from 'react-redux';
import { updateNotes } from '../../store/notes';
import validateTypes from '../../utils/validateTypes';
import { messageButtons } from '../../constants/messageTypes';


const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    padding: '3%',
  },
  card: {
    width: '100%',
    height: '100%',
  },
  cardContent: {
    height: '100%',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  smsForm: {
    height: 'calc(100% - 150px)',
    overflow: 'hidden',
    overflowY: 'auto',
    margin: '10px 0px 20px 0px'
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: "0px 16px",
    width: '100%'
  },
  mr20: {
    marginRight: '20px'
  }
});

function NotesBuilder() {
  const classes = useStyles()
  const { notesTemplate: notes } = useSelector((state) => state.notes)
  const history = useHistory();
  const dispatch = useDispatch();
  const [isFormValidationError, updateIsFormValidationError] = useState(!notes?._id);
  const [notesType, setNotesType] = useState(clone(notes));
  const [isLoading, setIsLoading] = useState(false);

  const isValidationError = useMemo(() => !notesType?.name?.trim()?.length || isFormValidationError, [notesType?.name, isFormValidationError]);

  const isDisabled = useMemo(() => {
    return isLoading || isValidationError
  }, [isLoading, isValidationError])

  const handleUpdateNotesTemplate = async () => {
    const res = await updateNotes(
      { ...notesType, name: notesType.name?.trim(), template: '', _id: notes?._id || '' },
      dispatch
    );
    if (res.status) {
      dispatch(openSnackbar({ message: 'Clear Notes template updated', type: SNACKBAR_TYPES.SUCCESS }));
    } else {
      dispatch(openSnackbar({ message: res.message || 'Updating Clear Notes template failed', type: SNACKBAR_TYPES.ERROR }));
    }
    return res;
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    if (isValidationError || !validateTypes(notesType?.types)) {
      setIsLoading(false);
      return dispatch(openSnackbar({ message: 'There are some errors please check', type: SNACKBAR_TYPES.ERROR }));
    }
    await handleUpdateNotesTemplate();
    setIsLoading(false);
  }

  const updateTypesHandler = (selectedType, valueIds = []) => {
    const updatedTypes = notesType?.types?.map(type => {
      return type.id === selectedType?.parent ? { ...type, dependant: [...(type?.dependant || []), { child: selectedType.id, criteria: valueIds }] } : type;
    }) || [];
    setNotesType(prev => ({ ...prev, types: [...updatedTypes, selectedType] }));
  }

  const goBack = () => history.replace('/dashboard');

  useEffect(() => {
    updateIsFormValidationError(!notes?._id);
    setNotesType(clone(notes));
  }, [notes])

  return <Grid container spacing={2} className={classes.container}>
    <Grid item container xs={12} className={classes.card}>
      <Card className={classes.card}>
        <SMSTypeCardHeader
          title={'Clear Notes Builder'}
          action={<TypesToolbarButtons buttons={messageButtons} onClick={updateTypesHandler} />}
        />
        <Grid item className={classes.smsForm}>
          <SMSTypeForm title="Clear Notes Template" onSmsTypeChange={setNotesType} editSmsType={notesType} updateError={updateIsFormValidationError} setTags={() => { }} />
        </Grid>
        <Grid container className={classes.submitContainer}>
          <Grid item className={classes.mr20}>
            <Button variant="outlined" disabled={isLoading} onClick={goBack}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={isDisabled} onClick={handleSubmit}>
              {!isLoading && 'SAVE'}
              {isLoading && <CircularProgress size={24} color="inherit" />}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  </Grid>
}

export default NotesBuilder;
