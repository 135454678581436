import { createSlice } from '@reduxjs/toolkit';
import smsTypesReducers from './smsTypesReducers';
import {
  fetchSmsType,
  deleteSmsType as deleteSmsTypeService,
  createSmsType as createSmsTypeService,
  updateSmsType as updateSmsTypeService
} from '../../services/smsTypeService';
import uuidGenerator from '../../utils/uuidGenerator';
import { MESSAGE_TYPES } from '../../constants/messageTypes';

export const initialState = {
  types: [],
  allowedTypes: []
};

export const smsTypesSlice = createSlice({
  name: 'smsTypes',
  initialState,
  reducers: { ...smsTypesReducers },
});

export const { smsTypes, resetSmsTypes, allowedSmsTypes } = smsTypesSlice.actions;

function addIds(data = []) {
  return data.map(item => {
    const newTypes = item?.types?.map((type) => {
      const updatedType = type;
      if (!type.id) {
        updatedType.id = uuidGenerator();
      }
      if (type.type === MESSAGE_TYPES.DROPDOWN) {
        updatedType.options = type?.options?.map(option => option?.id ? option : { ...option, id: uuidGenerator() });
      }
      return updatedType;
    })
    return { ...item, types: newTypes };
  })
}

export const getSmsTypes = async (dispatch) => {
  try {
    const data = await fetchSmsType();
    if (!data?.status) throw new Error('Failed fetching SMS types');
    const dataWithIds = addIds(data.data);
    dispatch(smsTypes(addIds(dataWithIds)));
    dispatch(allowedSmsTypes(dataWithIds.filter(type => type?.status === 'ACTIVE')));
    return { status: true, message: "SMS Types fetched successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const deleteSmsType = async (smsTypeId) => {
  try {
    const data = await deleteSmsTypeService({ smsTypeId });
    if (!data?.status) throw new Error('Failed deleting SMS type');

    return { status: true, message: "SMS Type deleted successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const createSmsType = async (payload, dispatch) => {
  try {
    const data = await createSmsTypeService(payload);
    if (!data?.status) throw data;

    await getSmsTypes(dispatch);
    return { status: true, message: "SMS Type created successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export const updateSmsType = async (payload, dispatch) => {
  try {
    const data = await updateSmsTypeService(payload);
    if (!data?.status) throw data;

    await getSmsTypes(dispatch);
    return { status: true, message: "SMS Type updated successfully" };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export default smsTypesSlice.reducer;
