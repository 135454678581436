const styling = (theme) => ({
  heading: {
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.25rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      fontWeight: '600',
    }
  },

  smsTypeHeading: {
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
      fontWeight: '600',
      marginLeft: '20px'
    }
  },

  smsIcon: {
    marginTop: '15px'
  },
  smsPreview: {
    marginLeft: '5px',
    '& .MuiFilledInput-root.Mui-disabled': {
      color: 'black',
    },
    '& .MuiInputBase-input.MuiFilledInput-input.MuiInputBase-inputMultiline.MuiFilledInput-inputMultiline': {
      lineHeight: '1.4'
    },
    '& .MuiInputBase-root.MuiFilledInput-root': {
      padding: '10px 12px 10px'
    }
  },
  headingContainer: {
    height: 40
  },
  fullWidth: {
    width: '100%',
    marginTop: '32px',
  },
  numberBox: {
    width: '30px',
    height: '30px',
    backgroundColor: '#2a4c7e',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.38',
    letterSpacing: '0px',
    color: '#ffffff'
  },
  marginBottom: {
    marginBottom: '20px'
  },
  inputLabels: {
    marginLeft: 5,
    '& .MuiInputLabel-root': {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "80%",
      height: "100%",
    },
    '& .MuiInputLabel-shrink': {
      maxWidth: "109%",
    }
  }
})

export default styling;
