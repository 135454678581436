import createTimeStampFileName from './createTimestampFileName';

const createAndDownloadCSV = (data = [], fileName = 'list') => {
  let csv = '';
  data.forEach(el => csv += `${el.join(',')}\n`);

  const hiddenElement = document.createElement('a');
  hiddenElement.style.display = 'none';
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenElement.target = '_blank';
  hiddenElement.rel = "noreferrer";
  hiddenElement.download = createTimeStampFileName(fileName);
  hiddenElement.click();
}

export default createAndDownloadCSV;
