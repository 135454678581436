import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import { Dashboard } from '@material-ui/icons';
import { useHistory, useLocation } from "react-router-dom";
import checkRouteForRole from '../../utils/checkRouteForRole';
import { useSelector } from 'react-redux';
import { sms, email, notes, adminApps } from '../../constants/appsList';
import hideHeadingOnEmptyRoutes from '../../utils/hideHeadingsOnEmptyRoutes';
import SvgIcon from '../../components/SvgIcons';
import iconNames from '../../constants/iconNames';

const useStyles = makeStyles((theme) => ({
  list: {
    flex: 1,
    width: '240px',
    paddingTop: '15%',
    background: theme.palette.primaryButton[800],
    '& .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected': {
      backgroundColor: theme.palette.primaryButton[900]
    },
    '& .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:hover': {
      backgroundColor: theme.palette.primaryButton[900]
    }
  },
  listItem: {
    color: theme.palette.primary.contrastText,
    wordWrap: 'break-word'
  },
  listHeader: {
    color: theme.palette.primary.contrastText
  },
  [theme.breakpoints.down('md')]: {
    list: {
      width: '200px'
    }
  },
  imageContainerWrapper:{
    width:'24px'
  }
}));

function SideBar({ isOpen }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const groups = useSelector(state => state.auth?.user?.groups);
  const infohubs = useSelector((state) => state.infohubsManagement.infohubs);
  const classes = useStyles();

  const redirectTo = (path) => (event) => history.push(path);

  const list = () => (
    <div className={classes.list}>
      <List>
        <ListItem
          button
          key="Dashboard"
          onClick={redirectTo('/dashboard')}
          selected={pathname === '/dashboard'}
        >
          <ListItemIcon><Dashboard color="inherit" className={classes.listItem} /></ListItemIcon>
          <ListItemText primary="My Apps" className={classes.listItem} />
        </ListItem>
      </List>

      {groups && hideHeadingOnEmptyRoutes(groups, adminApps) && <List
        subheader={
          <ListSubheader component="div" className={classes.listHeader}>
            Admin
          </ListSubheader>
        }
      >
        {adminApps.map(app => {
          return checkRouteForRole(groups, app.role) && <ListItem
            button
            key={app.name}
            onClick={redirectTo(app.path)}
            selected={pathname === app.path}
          >
            <ListItemIcon><SvgIcon iconName={app.iconName} className={classes.listItem} /></ListItemIcon>
            <ListItemText primary={app.name} className={classes.listItem} />
          </ListItem>
        })}
      </List>}

      {groups && hideHeadingOnEmptyRoutes(groups, sms) && <List
        subheader={
          <ListSubheader component="div" className={classes.listHeader}>
            SMS
          </ListSubheader>
        }
      >
        {sms.map(app => {
          return checkRouteForRole(groups, app.role) && <ListItem
            button
            key={app.name}
            onClick={redirectTo(app.path)}
            selected={pathname === app.path}
          >
            <ListItemIcon><SvgIcon iconName={app.iconName} className={classes.listItem} /></ListItemIcon>
            <ListItemText primary={app.name} className={classes.listItem} />
          </ListItem>
        })}
      </List>}
      {groups && hideHeadingOnEmptyRoutes(groups, email) && <List
        subheader={
          <ListSubheader component="div" className={classes.listHeader}>
            Email
          </ListSubheader>
        }
      >
        {email.map(app => {
          return checkRouteForRole(groups, app.role) && <ListItem
            button
            key={app.name}
            onClick={redirectTo(app.path)}
            selected={pathname === app.path}
          >
            <ListItemIcon><SvgIcon iconName={app.iconName} className={classes.listItem} /></ListItemIcon>
            <ListItemText primary={app.name} className={classes.listItem} />
          </ListItem>
        })}
      </List>}

      {groups && hideHeadingOnEmptyRoutes(groups, notes) && <List
        subheader={
          <ListSubheader component="div" className={classes.listHeader}>
            Notes Tool
          </ListSubheader>
        }
      >
        {notes.map(app => {
          return checkRouteForRole(groups, app.role) && <ListItem
            button
            key={app.name}
            onClick={redirectTo(app.path)}
            selected={pathname === app.path}
          >
            <ListItemIcon><SvgIcon iconName={app.iconName} className={classes.listItem} /></ListItemIcon>
            <ListItemText primary={app.name} className={classes.listItem} />
          </ListItem>
        })}
      </List>}

      {groups && hideHeadingOnEmptyRoutes(groups, infohubs) && <List
        subheader={
          <ListSubheader component="div" className={classes.listHeader}>
            Info Hubs
          </ListSubheader>
        }
      >
        {infohubs.map(app => {
          return checkRouteForRole(groups, app.role) && <ListItem
            button
            key={app.name}
            onClick={redirectTo(app.link)}
            selected={pathname.indexOf(app.link) === 0}
          >
            <ListItemIcon>
              {
                app.icon
                ? <img src={app.icon}  className={classes.imageContainerWrapper} alt="infohub-icon"  />
                : <SvgIcon iconName={iconNames.Book} className={classes.listItem} />
              }
            </ListItemIcon>
            <ListItemText primary={app.name} className={classes.listItem} />
          </ListItem>
        })}
      </List>}
    </div>
  );

  return (
    <Drawer variant="persistent" anchor="left" open={isOpen}>
      {list()}
    </Drawer>
  )
}

export default SideBar;