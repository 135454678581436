import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  height: {
    height: '100vh'
  },
  loading: {
    fontWeight: 600,
    fontSize: '16px',
    color: theme.palette.primaryButton[900]
  }
}));

function AppLoader() {
  const classes = useStyles();

  return (
    <Grid container className={classes.height} justifyContent="center" alignContent="center">
      <Grid item container justifyContent="center" xs={12}>
        <Typography variant="button" className={classes.loading}>
          Loading...
        </Typography>
      </Grid>
      <Grid item container justifyContent="center" xs={12}>
        <CircularProgress color="primary"></CircularProgress>
      </Grid>
    </Grid>
  )
}

export default AppLoader;