import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

import SvgIcon from '../SvgIcons';
import iconNames from '../../constants/iconNames';
import { styling } from './styling';
import uuidGenerator from '../../utils/uuidGenerator';
import DependentTypeDialogBox from './DependentTypeDialogBox';
import TypeHeader from './TypeHeader';
import TypeActions from './TypeActions';
import { MESSAGE_TYPES } from '../../constants/messageTypes';
import DependentOnContainer from './DependentOnContainer';

const useStyles = makeStyles(theme => ({
  ...styling(theme),
  container: {
    width: '100%',
  },
  inputContainer: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 0
  },
  smsTypeInputLabel: {
    width: '100%',
    marginRight: '5px'
  },
  addOption: {
    fontWeight: 600,
    padding: '6px 0px'
  },
  optionNumberWidth: {
    marginRight: '5px',
    width: '25px',
  },
  columnPadding: {
    [theme.breakpoints.up('sm')]: {
      padding: '0px 10px 0px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 0px 0px'
    }
  },
  deleteButtonWidth: {
    width: '13px',
    marginLeft: '8px'
  }
}))

function SMSTypeDropdown({
  id,
  placeholder,
  label,
  onLabelChange,
  onPlaceholderChange,
  reference,
  onReferenceChange,
  options,
  onOptionsChange,
  handleDelete,
  updateError,
  types,
  setTags,
  addSubReason,
  parent,
  expandAll,
  updateSubReason
}) {
  const [dropdownOptions, updateDropdownOptions] = useState(options?.length ? options : [{ label: '', value: '', id: uuidGenerator() }]);
  const [referenceError, updateReferenceError] = useState('');
  const [optionsError, updateOptionsError] = useState('');
  const [labelError, updateLabelError] = useState('');
  const [openSelectDependent, setOpenSelectDependent] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const areOptionsValid = useMemo(() => dropdownOptions?.reduce((valid, option) => valid && !!option.label && !!option.value, true), [dropdownOptions])

  const validate = () => {
    updateReferenceError(!!reference ? '' : 'Reference is required');
    updateLabelError(!!label ? '' : 'Label is required');

    const isExisting = types?.filter(type => reference && type?.reference === reference).length > 1
    if (isExisting) {
      updateReferenceError('Same reference already exists');
    }
    if (!areOptionsValid) {
      updateOptionsError('All labels and values are required');
    } else {
      updateOptionsError('');
    }
    if (!!reference && !isExisting && !!setTags) {
      const tags = types.filter(type => !!type.reference)
      setTags(tags)
    }
  };
  const classes = useStyles();
  const updateValues = (value, field, index) => {
    const newValues = [...dropdownOptions];
    newValues[index][field] = value;
    updateDropdownOptions(newValues);
  };

  const addDropdownItem = () => {
    updateDropdownOptions([...dropdownOptions, { label: '', value: '', id: uuidGenerator() }]);
  };

  const handleOptionDelete = (index) => {
    if (dropdownOptions.length <= 1) {
      return;
    }
    updateDropdownOptions([...dropdownOptions.filter((_, i) => i !== index)])
  };

  const handleOpenDependentTypeDialogBox = () => setOpenSelectDependent(true);

  const addSubReasonHandler = (type, valueIds) => {
    addSubReason(type, valueIds);
  };

  const toggleExpanded = () => setExpanded(prev => !prev);

  const parentTypeData = parent && types?.find(item => item.id === parent)

  const parentOptionList = parentTypeData?.options;

  const optionsSelected = parentTypeData?.dependant?.find(itm => itm.child === id)?.criteria;

  const updateParentCriteria = (optionId, checked) => {
    updateSubReason(parent, id, optionId, checked)
  }

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(dropdownOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOptions]);

  useEffect(() => {
    if (updateError) {
      updateError(!reference || !label || !areOptionsValid);
    }
  }, [areOptionsValid, label, reference, updateError]);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  return <Accordion expanded={expanded} onChange={toggleExpanded} square className={classes.accordion}>
    <AccordionSummary expandIcon={<SvgIcon iconName={iconNames.ChevronDown} />}>
      <TypeHeader
        title={reference}
        type={MESSAGE_TYPES.DROPDOWN}
        parent={parent}
        types={types}
      />
    </AccordionSummary>
    <AccordionDetails>
      <Grid container>
        <Grid item container className={classes.inputContainer}>
          <Grid item xs={12} md={4} className={classes.columnPadding}>
            <TextField
              label="Reference*"
              variant="standard"
              size="small"
              value={reference}
              onChange={onReferenceChange}
              onBlur={validate}
              error={!!referenceError}
              helperText={referenceError}
              className={classes.smsTypeInput}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.columnPadding}>
            <TextField
              variant="standard"
              size="small"
              label="Label*"
              value={label}
              onChange={onLabelChange}
              onBlur={validate}
              error={!!labelError}
              helperText={labelError}
              className={classes.smsTypeInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              size="small"
              label="Placeholder"
              value={placeholder}
              onChange={onPlaceholderChange}
              className={classes.smsTypeInput}
            />
          </Grid>
        </Grid>
        <Grid item container className={classes.optionsHeadingContainer}>
          <Button variant="text" color="primary" className={classes.addOption} onClick={addDropdownItem}>
            Add Option&nbsp;&nbsp;<SvgIcon iconName={iconNames.PlusCircle} />
          </Button>
        </Grid>
        <Grid item container>
          {!!optionsError && <Typography variant="caption" color="error">{optionsError}</Typography>}
          {!!dropdownOptions.length && dropdownOptions.map((item, index) => {
            return <Grid item container key={item.id || index} >
              <Grid item xs={6} className={classes.inputContainer}>
                <Typography variant="body1" className={classes.optionNumberWidth} >{`${index + 1}.`}</Typography>
                <TextField
                  label="Label*"
                  variant="outlined"
                  size="small"
                  value={dropdownOptions[index]?.label}
                  onChange={(value) => updateValues(value.target.value, 'label', index)}
                  className={classes.smsTypeInputLabel}
                  onBlur={validate}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputContainer}>
                <TextField
                  label="Value*"
                  variant="outlined"
                  size="small"
                  value={dropdownOptions[index]?.value}
                  onChange={(value) => updateValues(value.target.value, 'value', index)}
                  className={classes.smsTypeInputLabel}
                  onBlur={validate}
                />
                <Tooltip title="Delete Option">
                  <IconButton size='small' onClick={() => handleOptionDelete(index)} className={classes.deleteButtonWidth} >
                    <SvgIcon iconName={iconNames.DeleteForever} className={classes.deleteIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          })}
        </Grid>
        {parent && <DependentOnContainer parentOptionList={parentOptionList} currentDependencyList={optionsSelected} handleOptionCheck={updateParentCriteria}></DependentOnContainer>}
        <TypeActions handleDelete={handleDelete} handleAddDependency={handleOpenDependentTypeDialogBox} />
      </Grid>
      <DependentTypeDialogBox
        open={openSelectDependent}
        handleClose={() => setOpenSelectDependent(false)}
        options={options}
        handleSelectDependant={addSubReasonHandler}
        id={id}
      />
    </AccordionDetails>
  </Accordion>
}

export default SMSTypeDropdown;
