import request from "../utils/request";
const SMSServiceEndPoint = `${process.env.REACT_APP_BASE_URL}/send-sms`;

const sendSMSService = async (payload) => {
  try {
    const response = await request({
      url: SMSServiceEndPoint,
      baseURL: '/',
      data: { ...payload },
      method: 'POST'
    });
    return {
      status: true,
      statusCode: response.status,
      message: response.data.message
    };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status,
      message: error?.response?.data?.message || error.message
    };
  }
}

export default sendSMSService;