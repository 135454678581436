import React from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styling from './styling';

const useStyles = makeStyles(theme => styling(theme));

function InfoHubEmptyState({ img, heading, subHeading }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container alignContent="center" className={classes.emptyStateCardContent}>
          <Grid item container justifyContent="center" className={classes.spacing} xs={12}>
            <img src={img} alt="icon" />
          </Grid>
          <Grid item container justifyContent="center" className={classes.spacing} xs={12}>
            <Typography variant="h6" className={classes.message}>
              {heading}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" className={classes.spacing} xs={12}>
            <Typography variant="body2">
              {subHeading}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  )
}

export default InfoHubEmptyState;
