import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core'
import ausStates from '../../constants/ausStates';

const AddressSMSType = forwardRef(function ({ onChange, type }, ref) {
  const [address, setAddress] = useState({ addressLine1: '', city: '', state: '' });
  const [addressError, setAddressError] = useState({ addressLine1: '', city: '', state: '' });

  useImperativeHandle(ref, () => {
    return {
      clearAddress: () => setAddress({ addressLine1: '', city: '', state: '' })
    }
  })

  const setFieldValue = ({ target: { name, value } }) => {
    const updatedAddress = { ...address, [name]: value };
    setAddress(updatedAddress);
    const { addressLine1, city, state } = updatedAddress;
    const formattedAddress = addressLine1 && city && state ? [addressLine1, city, state].join(type ==='SMS' ? ',\n': ',<br>') : '';
    if (formattedAddress) {
      onChange(formattedAddress);
    }
  }

  const validateAndTriggerChange = (e) => {
    const updatedAddressError = { ...addressError };
    updatedAddressError.addressLine1 = !address.addressLine1 ? 'Address is required' : '';
    updatedAddressError.city = !address.city ? 'City is required' : '';
    updatedAddressError.state = !address.state ? 'State is required' : '';
    setAddressError(updatedAddressError);
  }

  return <Grid container spacing={2}>
    <Grid item container>
      <TextField
        label="Address"
        title="Address"
        variant="filled"
        size="small"
        name="addressLine1"
        fullWidth
        value={address.addressLine1}
        onChange={setFieldValue}
        onBlur={validateAndTriggerChange}
        error={!!addressError.addressLine1}
        helperText={addressError.addressLine1}
      />
    </Grid>
    <Grid item container>
      <TextField
        label="City"
        title="City"
        variant="filled"
        size="small"
        name="city"
        fullWidth
        value={address.city}
        onChange={setFieldValue}
        onBlur={validateAndTriggerChange}
        error={!!addressError.city}
        helperText={addressError.city}
      />
    </Grid>
    <Grid item container>
      <TextField
        label="State"
        title="State"
        variant="filled"
        size="small"
        name="state"
        fullWidth
        select
        value={address.state}
        onChange={setFieldValue}
        onBlur={validateAndTriggerChange}
        error={!!addressError.state}
        helperText={addressError.state}
      >
        {
          ausStates.map((ausState, index) => <MenuItem key={index} value={ausState}>{ausState}</MenuItem>)
        }
      </TextField>
    </Grid>
  </Grid>
})

export default AddressSMSType;
