import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import TypeText from './TypeText';
import TypeReferral from './TypeReferral';
import TypeDropdown from './TypeDropdown';
import { MESSAGE_TYPES } from '../../constants/messageTypes';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';

const useStyles = makeStyles(theme => ({
  itemContainer: {
    margin: '10px 0px',
    width: '100%',
  },
  formContainer: {
    width: '100%'
  }
}));

function MessageTemplateItems({ updateTypes, types, updateValues, handleDelete, updateError, setTags, expandAll }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const updateTypesHandler = (selectedType, valueIds = []) => {
    const updatedTypes = types?.map(type => {
      return type.id === selectedType?.parent ? { ...type, dependant: [...(type?.dependant || []), { child: selectedType.id, criteria: valueIds }] } : type;
    });
    updateTypes([...updatedTypes, selectedType])
  }

  const updateSubReason = (parent, child, optionId, checked) => {
    if (!checked && types?.find(type => type.id === parent)?.dependant?.find(item => item.child === child)?.criteria?.length <= 1) {
      return dispatch(openSnackbar({ message: 'At least one dependent option is required', type: SNACKBAR_TYPES.ERROR }));
    }
    const updatedTypes = types?.map(type => {
      return type.id === parent ? { ...type, dependant: [ ...type.dependant.map(item => {
          return item.child === child ? { ...item, criteria: checked ? [ ...item.criteria, optionId] : item.criteria.filter(itm => itm !== optionId) } : item
        })
      ]} : type
    })
    updateTypes([ ...updatedTypes ]);
  }

  return <Grid item className={classes.formContainer}>
    {
      !!types.length && types.map((smsType, index) => {
        const onReferenceChange = e => updateValues(e.target.value, 'reference', index)
        const onLabelChange = e => updateValues(e.target.value, 'label', index)
        const onPlaceholderChange = e => updateValues(e.target.value, 'placeholder', index)
        if (smsType?.type === MESSAGE_TYPES.TEXT) {
          return (
            <Grid item container key={index} className={classes.itemContainer}>
              <TypeText
                heading={MESSAGE_TYPES.TEXT}
                reference={types[index]?.reference}
                onReferenceChange={onReferenceChange}
                label={types[index]?.label}
                onLabelChange={onLabelChange}
                placeholder={types[index]?.placeholder}
                onPlaceholderChange={onPlaceholderChange}
                handleDelete={() => handleDelete(index)}
                updateError={updateError}
                types={types}
                setTags={setTags}
                parent={smsType?.parent}
                expandAll={expandAll}
                id={smsType.id}
                updateSubReason={updateSubReason}
              />
            </Grid>
          )
        } else if (smsType?.type === MESSAGE_TYPES.TEXT_AREA) {
          return (
            <Grid item container key={index} className={classes.itemContainer}>
              <TypeText
                heading={MESSAGE_TYPES.TEXT_AREA}
                reference={types[index]?.reference}
                onReferenceChange={onReferenceChange}
                label={types[index]?.label}
                onLabelChange={onLabelChange}
                placeholder={types[index]?.placeholder}
                onPlaceholderChange={onPlaceholderChange}
                handleDelete={() => handleDelete(index)}
                updateError={updateError}
                types={types}
                setTags={setTags}
                parent={smsType?.parent}
                expandAll={expandAll}
                id={smsType.id}
                updateSubReason={updateSubReason}
              />
            </Grid>
          )
        } else if (smsType?.type === MESSAGE_TYPES.NUMBER) {
          return (
            <Grid item container key={index} className={classes.itemContainer}>
              <TypeText
                heading={MESSAGE_TYPES.NUMBER}
                reference={types[index]?.reference}
                onReferenceChange={onReferenceChange}
                label={types[index]?.label}
                onLabelChange={onLabelChange}
                placeholder={types[index]?.placeholder}
                onPlaceholderChange={onPlaceholderChange}
                handleDelete={() => handleDelete(index)}
                updateError={updateError}
                types={types}
                setTags={setTags}
                parent={smsType?.parent}
                expandAll={expandAll}
                id={smsType.id}
                updateSubReason={updateSubReason}
              />
            </Grid>
          )
        } else if (smsType?.type === MESSAGE_TYPES.REFERRAL) {
          return (
            <Grid item container key={index} className={classes.itemContainer}>
              <TypeReferral
                reference={types[index]?.reference}
                onReferenceChange={onReferenceChange}
                label={types[index]?.label}
                onLabelChange={onLabelChange}
                placeholder={types[index]?.placeholder}
                onPlaceholderChange={onPlaceholderChange}
                prefix={types[index]?.prefix}
                onPrefixChange={e => updateValues(e.target.value, 'prefix', index)}
                handleDelete={() => handleDelete(index)}
                updateError={updateError}
                types={types}
                setTags={setTags}
                parent={smsType?.parent}
                expandAll={expandAll}
                id={smsType.id}
                updateSubReason={updateSubReason}
              />
            </Grid>
          )
        } else if (smsType?.type === MESSAGE_TYPES.DROPDOWN) {
          return (
            <Grid item container key={index} className={classes.itemContainer}>
              <TypeDropdown
                reference={types[index]?.reference}
                onReferenceChange={onReferenceChange}
                label={types[index]?.label}
                onLabelChange={onLabelChange}
                placeholder={types[index]?.placeholder}
                onPlaceholderChange={onPlaceholderChange}
                options={types[index]?.options}
                onOptionsChange={options => updateValues(options, 'options', index)}
                handleDelete={() => handleDelete(index)}
                updateError={updateError}
                types={types}
                setTags={setTags}
                addSubReason={updateTypesHandler}
                id={smsType.id}
                parent={smsType?.parent}
                expandAll={expandAll}
                updateSubReason={updateSubReason}
              />
            </Grid>
          )
        } else if (smsType?.type === MESSAGE_TYPES.ADDRESS) {
          return (
            <Grid item container key={index} className={classes.itemContainer}>
              <TypeText
                heading={MESSAGE_TYPES.ADDRESS}
                reference={types[index]?.reference}
                onReferenceChange={onReferenceChange}
                label={types[index]?.label}
                onLabelChange={onLabelChange}
                placeholder={types[index]?.placeholder}
                onPlaceholderChange={onPlaceholderChange}
                handleDelete={() => handleDelete(index)}
                updateError={updateError}
                types={types}
                setTags={setTags}
                parent={smsType?.parent}
                expandAll={expandAll}
                id={smsType.id}
                updateSubReason={updateSubReason}
              />
            </Grid>
          )
        }
        return null;
      })
    }
  </Grid>
}

export default MessageTemplateItems;
