import request from "../utils/request";
const UsersServiceEndPoint = `${process.env.REACT_APP_BASE_URL}/upload-users`;

function readFile(file) {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onloadend = () => {
      resolve(fr.result)
    };
    fr.onerror = reject;
    fr.readAsArrayBuffer(file);
  });
}

const uploadUsersService = async (payload) => {
  try {
    const data = await readFile(payload);
    const response = await request({
      url: UsersServiceEndPoint,
      method: 'post',
      headers: {
        'Content-Type': payload.type
      },
      data
    });
    return { status: true, data: response.data };
  } catch (error) {
    return {
      status: false,
      statusCode: error?.response?.status,
      message: error?.response?.data || error.message
    };
  }
}

export default uploadUsersService;
