import React from "react";
import { Button, makeStyles } from "@material-ui/core";

import SvgIcon from "../SvgIcons";
import iconNames from "../../constants/iconNames";

const useStyles = makeStyles({
  expandButton: {
    fontWeight: 600,
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.5s'
  },
  expandIconRotated: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.5s'
  }
})

function ExpandAllButton({ expandAll, toggleExpandAll }) {
  const classes = useStyles();

  return <Button variant="text" className={classes.expandButton} onClick={toggleExpandAll}>
    {expandAll ? 'Collapse All' : 'Expand All'}
    <SvgIcon iconName={iconNames.ChevronDown} className={expandAll ? classes.expandIconRotated : classes.expandIcon} />
  </Button>
}

export default ExpandAllButton;
