import { initialState } from './';

const searchInfo = (state, action) => {
  state.searchData = action.payload || [];
}

const setArticle = (state, action) => {
  state.article = action.payload;
}

const setSearchValue = (state, action) => {
  state.searchValue = action.payload;
}

const setFAQ = (state, action) => {
  state[action.payload?.infoHub].faqs[action.payload.index].isLoading = false;
  state[action.payload?.infoHub].faqs[action.payload.index].body = action.payload?.response;
}

const setInfoHubData = (state, action) => {
  state[action.payload.infoHub] = action.payload.data;
}

const resetInfoHub = (state, action) => {
  return initialState;
}

const reducers = { searchInfo, setArticle, setSearchValue, setFAQ, setInfoHubData, resetInfoHub };

export default reducers;