import React from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Card, CardHeader, Divider, CardContent, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import checkRouteForRole from '../utils/checkRouteForRole';
import SvgIcon from './SvgIcons';
import iconNames from '../constants/iconNames';
import AppsCardItemsStyling from './AppsCardItemsStyling';

const useStyles = makeStyles((theme) => ({
  ...AppsCardItemsStyling(theme),
}));

function AppsCardItems({ apps }) {
  const history = useHistory();
  const groups = useSelector((state) => state.auth?.user?.groups);
  const classes = useStyles();

  function routeTo(path) {
    history.push(path);
  }

  return (apps.map((app) => (
    (checkRouteForRole(groups, app.role) && <Grid item md={4} sm={6} xs={12}  key={app.name} className={classes.cardBox}>
      <Card className={classes.card}>
        <CardActionArea onClick={() => routeTo(app.path)}>
          <CardHeader

            title={app.name}
            avatar={
              app.iconName?.startsWith('data:image/')
                ? <img src={app.icon } alt="infohub-icon" className={classes.svgIconWidth} />
                : <SvgIcon iconName={app.iconName || iconNames.Book} className={classes.svgIconWidth} />
            }
            className={classes.cardHeaderPadding}
          />
          <Divider />
          <CardContent  className={classes.cardHeaderPadding}>
            <span className={classes.descriptionTitle}>{app.description}</span>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>)
  )))
}

export default AppsCardItems;
