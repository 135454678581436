const styling = {
  container: {
    width: '100%',
    height: '100%',
    padding: '3%',
  },
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    height: '100%',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 20px',
    flex: 1
  },
  smsForm: {
    flex: 1,
    overflow: 'auto'
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}

export default styling;
