import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  TextField,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import generateImageDataUrl from '../../utils/generateImageDataUrl';
import iconNames from "../../constants/iconNames";
import SvgIcon from "../../components/SvgIcons";

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  dialogAction: {
    padding: '16px',
    '& > :not(:first-child)': {
      marginLeft: '24px'
    }
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectedIconView: {
    margin: '0 0 0 auto',
  },
  infoIcon: {
    color: theme.palette.primary.main
  }
}));

const validationSchema = yup.object({
  description: yup.string()
    .required('Description is required')
    .max(256, 'Description should not exceed 256 characters'),
  link: yup.string()
    .required('Friendly URL is required')
    .max(128, 'Friendly URL can not exceed 128 characters'),
  icon: yup.mixed()
    .nullable()
    .notRequired()
    .test("FILE_SIZE", "Icon file size too large, icon file size should be less than 512kb",
      value => !value || (value && value.size <= 512000)),
  iconDataUrl: yup.string()
})

function UpdateInfohubDialog({ open, handleClose, handleUpdateInfohub, infohub }) {
  const classes = useStyles();
  const [isUpdateInfohubLoading, setIsUpdateInfohubLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      description: '',
      link: '',
      icon: '',
      iconDataUrl: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsUpdateInfohubLoading(true);
      const res = await handleUpdateInfohub({
        role: infohub.role,
        description: values.description,
        link: values.link,
        icon: values.iconDataUrl
      });
      if (res) {
        handleDialogClose();
      }
      setIsUpdateInfohubLoading(false);
    }
  });

  useEffect(() => {
    if (open) {
      formik.setValues({
        description: infohub.description || '',
        link: infohub.link || '',
        icon: '',
        iconDataUrl: infohub.icon,
      })
    } else {
      formik.setValues({
        description: '',
        link: '',
        icon: '',
        iconDataUrl: ''
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, infohub]);

  const handleDialogClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    formik.handleReset(e);
    handleClose();
  }

  const handleFileUpload = async (event) => {
    if (event.target.files?.length > 1) {
      return formik.setFieldError('icon', 'Only one icon can be selected')
    }

    const file = event.target.files[0];

    if (!file) return;

    formik.setFieldValue('icon', file);
    formik.setFieldValue('iconDataUrl', await generateImageDataUrl(file));
  }

  return <Dialog
    open={open}
    onClose={handleDialogClose}
    fullWidth
    maxWidth="sm"
  >
    < DialogTitle disableTypography >
      <Grid container alignItems="center">
        <Typography variant="h6" className={classes.heading}>
          {isUpdateInfohubLoading ? 'Updating Info Hub' : 'Update Info Hub'}
        </Typography>
      </Grid>
    </DialogTitle >
    <DialogContent>
      {isUpdateInfohubLoading && <Grid container justifyContent="center" style={{ height: 48 }}>
        <CircularProgress size={36} />
      </Grid>}
      {!isUpdateInfohubLoading && <Grid container spacing={1}>
        <Grid item container>
          <TextField
            name="name"
            value={infohub?.name?.toUpperCase()}
            fullWidth
            autoComplete="off"
            size="small"
            label="Name"
            variant="filled"
            disabled
          />
        </Grid>
        <Grid item container>
          <TextField
            name="link"
            value={formik.values?.link?.toLowerCase()}
            onChange={formik.handleChange}
            fullWidth
            autoComplete="off"
            size="small"
            label="Friendly URL"
            variant="filled"
            onBlur={formik.handleBlur}
            error={formik.touched.link && !!formik.errors.link}
            helperText={(formik.touched.link && formik.errors.link) || ' '}
          />
        </Grid>
        <Grid item container>
          <TextField
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            fullWidth
            autoComplete="off"
            size="small"
            label="Description"
            variant="filled"
            multiline
            minRows={5}
            maxRows={5}
            onBlur={formik.handleBlur}
            error={formik.touched.description && !!formik.errors.description}
            helperText={(formik.touched.description && formik.errors.description) || ' '}
          />
        </Grid>
        <Grid item container alignItems="center" spacing={1}>
          <Grid item>
            <input
              name="icon"
              accept=".png, .svg"
              style={{ display: 'none' }}
              id="infohub-icon-upload"
              multiple
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="infohub-icon-upload">
              <Button variant="contained" color="primary" component="span">
                Select Info Hub Icon
              </Button>
            </label>
          </Grid>
          <Grid item>
            <Tooltip title="Only SVG and PNG icons are allowed and the size of icon file should be less than 512KB">
              <span><SvgIcon iconName={iconNames.Info} className={classes.infoIcon} /></span>
            </Tooltip>
          </Grid>
          <Grid item className={classes.selectedIconView}>
            {formik.values.iconDataUrl && <img
              src={formik.values.iconDataUrl}
              width={48}
              alt="infohub_icon"
              draggable="false"
            />}
          </Grid>
          <Typography variant="subtitle2" color="error">
            {formik.errors.icon}
          </Typography>
        </Grid>
      </Grid>}
    </DialogContent>
    {
      !isUpdateInfohubLoading && <DialogActions className={classes.dialogAction}>
        <Button onClick={handleDialogClose} size="medium" variant="outlined" color="primary">
          Close
        </Button>
        {
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={formik.handleSubmit}
            disabled={isUpdateInfohubLoading}
            startIcon={isUpdateInfohubLoading && <CircularProgress size={16} color="inherit" />}
          >
            Update Info Hub
          </Button>
        }
      </DialogActions>
    }
  </Dialog >
}

export default UpdateInfohubDialog;
