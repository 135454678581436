import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch, useLocation, matchPath } from 'react-router-dom';
import routes from '../constants/routes';
import AppLoader from './AppLoader';
import Layout from '../layout';
import getComponentForRoutes from '../utils/getComponentForRoutes';

const Routes = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const groups = useSelector((state) => state.auth?.user?.groups);
  const isBrowserRefreshing = useSelector((state) => state.auth.isBrowserRefreshing);
  const { pathname } = useLocation();
  const path = routes.map(el => matchPath(pathname, { path: el.path, exact: true })).find(el => el);

  if (isBrowserRefreshing) return <AppLoader />
  else return <Layout params={path?.params}>
    <Switch>
      {routes.map((route) => (getComponentForRoutes({ isLoggedIn, groups, params: path?.params }, route)))}
      <Route path="*"><Redirect to="/" /></Route>
    </Switch>
  </Layout>
}

export default Routes;
