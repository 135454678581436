import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, Grid, Typography, makeStyles, DialogContent, DialogActions, Button, MenuItem, Checkbox, ListItemText, CircularProgress, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import downloadSmsReport from '../../services/downloadSmsReport';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  dialogAction: {
    padding: '16px',
    '& > :not(:first-child)': {
      marginLeft: '24px'
    }
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

function SMSReportsFilterDialog({ open, handleClose, smsTypes, setDownloadSmsReportTaskId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedSmsType, updateSelectedSmsType] = useState([]);
  const [fromDate, updateFromDate] = useState(new Date().setMonth(new Date().getMonth() - 1));
  const [toDate, updateToDate] = useState(new Date());
  const [isReportDownloading, setIsReportDownloading] = useState(false);
  const [smsTypeValidationError, setSmsTypeValidationError] = useState('');

  const validateFilter = () => {
    if (selectedSmsType.length === 0) {
      setSmsTypeValidationError('Please select a sms type');
      return false;
    } else {
      setSmsTypeValidationError('');
    }
    return true;
  }

  const handleReportDownload = async () => {
    setIsReportDownloading(true);
    if (!validateFilter()) {
      setIsReportDownloading(false);
      return;
    }
    const smsTypesFilter = selectedSmsType.includes(0)
      ? 'ALL'
      : selectedSmsType.map(smsType => smsTypes[smsType]._id)
    const res = await downloadSmsReport({
      smsTypes: smsTypesFilter,
      from: new Date(new Date(fromDate).setHours(0, 0, 0, 0)),
      to: new Date(new Date(toDate).setHours(23, 59, 59, 999))
    });
    dispatch(openSnackbar({ message: res.message, type: res.status ? SNACKBAR_TYPES.SUCCESS : SNACKBAR_TYPES.ERROR }));
    if (res.status) {
      setDownloadSmsReportTaskId?.(res.data);
    }
    setIsReportDownloading(false);
    handleClose();
  }

  return <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle disableTypography>
      <Grid container alignItems="center">
        <Typography variant="h6" className={classes.heading}>Reports Filters</Typography>
      </Grid>
    </DialogTitle>
    <DialogContent>
      <Grid container>
        <Grid container item>
          <TextField
            id="sms-types-select"
            select
            value={selectedSmsType}
            onChange={(e) => updateSelectedSmsType(e.target.value)}
            fullWidth
            size="small"
            label="Select SMS Types"
            variant="filled"
            SelectProps={{
              MenuProps: { PaperProps: { style: { maxHeight: 300 } } },
              multiple: true,
              renderValue: (data) => <div className={classes.ellipsis}>{data.map(item => smsTypes[item].name).join(', ')}</div>
            }}
            onBlur={validateFilter}
            error={!!smsTypeValidationError}
            helperText={smsTypeValidationError}
          >
            {smsTypes.map((option, index) => (
              <MenuItem key={index} value={index}>
                <Checkbox checked={selectedSmsType.indexOf(index) > -1} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container item spacing={1} >
            <Grid item style={{ width: '50%' }}>
              <DatePicker
              disableToolbar
              variant="inline"
              inputVariant="filled"
              size="small"
              format="dd/MM/yyyy"
              margin="normal"
              fullWidth
              id="from-date-picker"
              label="From"
              disableFuture
              value={fromDate}
              onChange={updateFromDate}
              KeyboardButtonProps={{
                'aria-label': 'from date',
              }}
            />
            </Grid>
            <Grid item style={{ width: '50%' }}>
              <DatePicker
              disableToolbar
              variant="inline"
              inputVariant="filled"
              size="small"
              format="dd/MM/yyyy"
              margin="normal"
              id="to-date-picker"
              label="To"
              fullWidth
              disableFuture
              value={toDate}
              onChange={updateToDate}
              KeyboardButtonProps={{
                'aria-label': 'to date',
              }}
            />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    </DialogContent>
    <DialogActions className={classes.dialogAction}>
      <Button onClick={handleClose} size="medium" variant="outlined" color="primary">
        Close
      </Button>
      {
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleReportDownload}
          disabled={isReportDownloading}
          startIcon={isReportDownloading && <CircularProgress size={16} color="inherit" />}
        >
          {isReportDownloading ? 'Generating Report' : 'Download Report'}
        </Button>
      }
    </DialogActions>
  </Dialog>
}

export default SMSReportsFilterDialog;
