import React, { useEffect, useState, useCallback } from 'react';
import { Card, CardContent, Grid, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getArticle, setArticle } from '../../store/info-hub';
import copyToClipboard from '../../utils/copyToClipboard';
import CopyButton from '../../components/CopyButton';
import BackToTopButton from '../../components/BackToTopButton';
import articleStyling from './articleStyling';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import handleInternalLinks from '../../utils/handleInternalLinks';
import trackEvents from '../../utils/trackEvents';
import { getInfoHubTitle } from '../../utils/infoHubRoutesProperties';

const useStyles = makeStyles(theme => ({
  articleContent: articleStyling(theme).articleContent,
  heading: {
    color: theme.palette.header[900],
    fontSize: '19px',
    fontWeight: '600',
    margin: '1% 0%',
    lineHeight: '1.43'
  }
}));

function InfoHubArticle() {
  const { params } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const article = useSelector(state => state.infoHub.article);
  const classes = useStyles();
  const { articleType, articleId, infoHub } = params;
  const friendlyUrl = `${infoHub}/${articleType}/${articleId}`;

  const redirectToHome = useCallback(() => {
    if (!infoHub) history.push('/');
    history.push(`/${infoHub}`);
  }, [history, infoHub]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(setArticle(null));
      const res = await getArticle(friendlyUrl)(dispatch);
      setIsLoading(false);
      if (!res.status) {
        dispatch(openSnackbar({
          message: 'Article not found',
          type: SNACKBAR_TYPES.ERROR
        }));
        return redirectToHome()
      };
    })();
  }, [articleId, dispatch, redirectToHome, friendlyUrl]);

  useEffect(() => {
    if (article?.body) handleInternalLinks(classes.articleContent, history);
  }, [article, isLoading, classes.articleContent, history]);

  const copyArticle = async () => {
    await copyToClipboard(article?.title || '');
    dispatch(openSnackbar({
      message: 'Copied to clipboard',
      type: SNACKBAR_TYPES.SUCCESS
    }));
    trackEvents("faq_copy", {
      title: article?.title,
      link_url: article?.friendly_url,
      info_hub_title: getInfoHubTitle(params),
      info_hub_page: 'FAQs - Details'
    });
  }

  return (
    <Grid container alignContent="flex-start">
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.articleContent}>
            <Grid container justifyContent="center" alignContent="center">
              {isLoading && <Grid item container justifyContent="center" xs={12}>
                <CircularProgress color="primary" />
              </Grid>}
              {!isLoading && <Grid item container xs={12}>
                <Grid item xs={12}>
                  <CopyButton copy={copyArticle} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.heading}>{article?.title}</Typography>
                  <div className={classes.articleContent} dangerouslySetInnerHTML={{ __html: article?.body }}></div>
                </Grid>
              </Grid>}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <BackToTopButton />
      </Grid>
    </Grid>
  );
}

export default InfoHubArticle;