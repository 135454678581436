import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '../SvgIcons';
import iconNames from '../../constants/iconNames';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "55px",
    width: "55px",
    color: theme.palette.primary.main,
    background: '#e6e9f2',
    borderRadius: '50%',
    padding: '15px'
  },
  [theme.breakpoints.down('md')]: {
    icon: {
      height: '40px',
      width: '40px'
    }
  }
}))

function TrayUpload() {
  const classes = useStyles()

  return <SvgIcon className={classes.icon} iconName={iconNames.TrayUpload}></SvgIcon>
}

export default TrayUpload;
