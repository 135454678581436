import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import BreadCrumpsTitle from "./BreadCrumpsTitle";
import { styling } from './styling';
import SvgIcon from "../SvgIcons";
import { TYPE_ICON_MAPPER } from "../../constants/messageTypes";

const useStyles = makeStyles(theme => styling(theme));

function TypeHeader({ title = '', type, parent, types }) {
  const classes = useStyles();

  return <Grid item container className={classes.headingContainer}>
    {
      parent
        ? <BreadCrumpsTitle types={types} type={type} parent={parent} title={title} />
        : <Typography className={classes.heading} variant="subtitle1">
          {title} <SvgIcon iconName={TYPE_ICON_MAPPER[type]} />
        </Typography>
    }
  </Grid>
}

export default TypeHeader;
