import React from "react";
import { Grid, makeStyles, Checkbox, Typography } from "@material-ui/core";

const LINES_TO_SHOW = 1;
const useStyles = makeStyles({
  dependentContainer: {
    maxHeight: "auto",
    marginTop: "20px",
  },
  label: {
    fontSize: "13px",
    paddingBottom: "10px",
  },
  optionLabel: {
    fontSize: "13px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical"
  },
  dependentListBox: {
    display: "flex",
    alignItems: "center",
  },
  customPadding:{
    padding: '9px 9px 9px 0px'
  }
});

function DependentOnContainer({
  parentOptionList = [],
  currentDependencyList = [],
  handleOptionCheck,
}) {
  const classes = useStyles();

  const handleOptionClick = (id, checked) => {
    handleOptionCheck(id, checked);
  };

  return (
    <Grid
      item
      container
      direction="column"
      className={classes.dependentContainer}
    >
      <Grid>
        <Typography className={classes.label}>Dependent On</Typography>
      </Grid>
      <Grid item container spacing={2} className={classes.dependentListContainer}>
        {parentOptionList.map((option, index) => (
          <Grid item key={index} xs={12} md={4} sm={6} lg={3}  className={classes.dependentListBox}>
            <Checkbox
              className={classes.customPadding}
              checked={currentDependencyList.includes(option?.id)}
              onChange={(_, checked) => handleOptionClick(option?.id, checked)}
            />
            <Typography
              className={classes.optionLabel}
              title={option?.label}
            >
              {option?.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default DependentOnContainer;
