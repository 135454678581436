import React, { useState } from 'react';
import { Grid, Card, CardHeader, CardContent, TextField, Divider, Button, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { signIn } from '../../store/auth';
import { primaryColorLink } from '../../constants/linkClasses';
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import './LoginPage.scss';

function LoginPage() {
  const [details, setDetails] = useState({
    email: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!details.email || !details.password) return;

    setIsLoading(true);

    const response = await signIn({
      email: details.email,
      password: details.password,
    })(dispatch);

    if (response.status) return history.push('/dashboard');

    dispatch(openSnackbar({ message: response.message, type: SNACKBAR_TYPES.ERROR }));
    setIsLoading(false);
    if (response.redirect) {
      dispatch(openSnackbar({ message: response.message, type: SNACKBAR_TYPES.WARNING }));
      history.push(response.redirect);
    }
  }

  return (
    <Grid container justifyContent="center" alignContent="center" className="login-container">
      <Card className="login-card">
        <CardHeader title="Login" />
        <Divider />
        <CardContent>
          <form onSubmit={submitHandler}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  value={details.email}
                  onChange={e => setDetails({ ...details, email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  value={details.password}
                  onChange={e => setDetails({ ...details, password: e.target.value })}
                />
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={isLoading} fullWidth>
                  {!isLoading && 'Login'}
                  {isLoading && <CircularProgress size={24} color="inherit" />}
                </Button>
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Link color="primary" to="/send-code" className={primaryColorLink}>Forgotten your password?</Link>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default LoginPage
