import iconNames from "./iconNames"

export const MESSAGE_TYPES = {
  TEXT: 'Text',
  TEXT_AREA: 'TextArea',
  NUMBER: 'Number',
  REFERRAL: 'Referral',
  DROPDOWN: 'Dropdown',
  ADDRESS: 'Address'
}

export const MESSAGE_LABELS = {
  TEXT: 'Text',
  TEXT_AREA: 'Text Area',
  NUMBER: 'Number',
  REFERRAL: 'Referral',
  DROPDOWN: 'Dropdown',
  ADDRESS: 'Address'
}

export const TYPE_ICON_MAPPER = {
  [MESSAGE_TYPES.TEXT]: iconNames.Alphabets,
  [MESSAGE_TYPES.TEXT_AREA]: iconNames.TextArea,
  [MESSAGE_TYPES.NUMBER]: iconNames.Numbers,
  [MESSAGE_TYPES.REFERRAL]: iconNames.Assignment,
  [MESSAGE_TYPES.DROPDOWN]: iconNames.ArrowDown,
  [MESSAGE_TYPES.ADDRESS]: iconNames.Location,
}

export const messageButtons = [
  {
    label: MESSAGE_LABELS.TEXT,
    icon: iconNames.Alphabets,
    data: { reference: '', label: '', placeholder: '', type: MESSAGE_TYPES.TEXT },
  },
  {
    label: MESSAGE_LABELS.TEXT_AREA,
    icon: iconNames.TextArea,
    data: { reference: '', label: '', placeholder: '', type: MESSAGE_TYPES.TEXT_AREA },
  },
  {
    label: MESSAGE_LABELS.NUMBER,
    icon: iconNames.Numbers,
    data: { reference: '', label: '', placeholder: '', type: MESSAGE_TYPES.NUMBER },
  },
  {
    label: MESSAGE_LABELS.REFERRAL,
    icon: iconNames.Assignment,
    data: { reference: '', label: '', placeholder: '', prefix: '', type: MESSAGE_TYPES.REFERRAL },
  },
  {
    label: MESSAGE_LABELS.DROPDOWN,
    icon: iconNames.ArrowDown,
    data: { reference: '', label: '', placeholder: '', options: [], dependant: [], type: MESSAGE_TYPES.DROPDOWN, parent: '' },
  },
  {
    label: MESSAGE_LABELS.ADDRESS,
    icon: iconNames.Location,
    data: { reference: '', label: '', placeholder: '', type: MESSAGE_TYPES.ADDRESS },
  },
]