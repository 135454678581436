import './App.scss';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { setStateOnMount } from './store/auth';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import Routes from './components/Routes';
import themeProperties from './theme';

const theme = createTheme(themeProperties);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    setStateOnMount()(dispatch);
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
