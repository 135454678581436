import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Button,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
  Switch,
} from "@material-ui/core";
import createPersistedState from "use-persisted-state";

import SvgIcon from "../../components/SvgIcons";
import iconNames from '../../constants/iconNames';
import { useViewStyles } from "../../styles/view.styles";
import { getSmsTypes, deleteSmsType, updateSmsType } from "../../store/smsTypes";
import { openSnackbar } from '../../store/snackbar';
import SNACKBAR_TYPES from '../../constants/snackBarTypes';
import SMSReportsFilterDialog from "./SMSReportsFiltersDialog";
import DialogBox from "../../components/ConfirmationDialogBox";
import getTaskByIdService from '../../services/getTaskById';
import downloadDocument from '../../utils/autoDownloadFiles';

const useDownloadSmsReportTaskId = createPersistedState('downloadSmsReportTaskId');

const useStyles = makeStyles(theme => ({
  card: {
    height: "100%",
    width: "100%",
  },
  headerAction: {
    "& > .MuiCardHeader-action": {
      margin: "0px",
      alignSelf: "center",
    },
  },
  tableHeight: {
    maxHeight: '100%'
  },
  cardContent: {
    padding: '0px',
    height: 'calc(100% - 65px)',
    '& .MuiGrid-container': {
      height: '100%'
    }
  },
  notFound: {
    color: theme.palette.textColor[600],
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
  editIcon: {
    color: theme.palette.warning.main,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  }
}));

const DialogBoxType = {
  DELETE: "DELETE",
  STATUS: "STATUS"
}
const initialDialogMessage = { title: "", message: "", cancelButtonLabel: "", confirmButtonLabel: "" }

function SMSType() {
  const dispatch = useDispatch();
  const smsTypes = useSelector((state) => state.smsTypes.types);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloadStatusLoading, setIsDownloadStatusLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [dialogContent, setDialogContent] = useState(initialDialogMessage);
  const [dialogBoxType, setDialogBoxType] = useState('');
  const [downloadSmsReportTaskId, setDownloadSmsReportTaskId] = useDownloadSmsReportTaskId();
  const history = useHistory();
  const classes = useStyles();
  const viewStyles = useViewStyles();
  const handleDelete = async () => {
    setIsLoading(true);
    setIsDialogOpen(false);
    await deleteSmsType(selectedItem._id)
    handleRefresh();
    dispatch(openSnackbar({ message: 'SMS type deleted', type: SNACKBAR_TYPES.SUCCESS }));
  }

  const handleFilterDialogClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    setIsFilterDialogOpen(false)
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    await getSmsTypes(dispatch);
    setIsLoading(false);
  }

  const handleStatusChange = async () => {
    setIsLoading(true);
    setIsDialogOpen(false);
    await updateSmsType({ ...selectedItem, status: selectedItem.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' }, dispatch)
    handleRefresh();
    dispatch(openSnackbar({ message: 'SMS type status updated', type: SNACKBAR_TYPES.SUCCESS }));
  }

  const dialogOnDoneActionMapper = {
    [DialogBoxType.DELETE]: handleDelete,
    [DialogBoxType.STATUS]: handleStatusChange
  }

  const handleDialogDoneClick = () => {
    dialogOnDoneActionMapper[dialogBoxType]()
  }

  const handleDialogCancelClick = () => {
    setIsDialogOpen(false);
    setSelectedItem({})
    setDialogContent(initialDialogMessage);
  }

  const handleDialogBoxOpen = ({ item, type }) => {
    const content = type === DialogBoxType.DELETE ? {
      title: 'Delete',
      message: <span>Are you sure you want to delete <em><strong>{item.name}</strong></em> ?</span>,
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Delete'
    } : {
      title: 'Update status',
      message: <span>Are you sure you want to set <em><strong>{item.name}</strong></em> to {item.status === 'ACTIVE' ? 'Inactive' : 'Active'} ?</span>,
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Update'
    }
    setDialogContent(content)
    setIsDialogOpen(true)
  }

  const getDownloadStatus = async () => {
    setIsDownloadStatusLoading(true);
    const res = await getTaskByIdService(downloadSmsReportTaskId);
    if (res.status && res.data?.task?.status === 'SUCCEEDED' && res.data?.task?.type === 'DOWNLOAD') {
      if (res.data.task.response) {
        downloadDocument(res.data.task.response);
      }
    } else if (res.status && res.data?.task?.status === 'FAILED') {
      dispatch(openSnackbar({ message: 'Unable to process sms report.', type: SNACKBAR_TYPES.ERROR }));
    } else {
      dispatch(openSnackbar({ message: 'SMS report still under processing.', type: SNACKBAR_TYPES.INFO }));
      setIsDownloadStatusLoading(false);
      return;
    }
    setDownloadSmsReportTaskId(null);
    setIsDownloadStatusLoading(false);
  }

  const clearDownloadStatus = () => setDownloadSmsReportTaskId(null);

  useEffect(() => {
    if (!smsTypes.length) {
      (async () => {
        await getSmsTypes(dispatch);
        setIsLoading(false);
      })()
    } else {
      setIsLoading(false);
    }
  }, [dispatch, smsTypes?.length]);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={viewStyles.container}
    >
      <Card className={classes.card}>
        <CardHeader
          title="SMS Type"
          disableTypography
          className={classes.headerAction}
          action={
            <div className={classes.flex}>
              <Tooltip title="Refresh">
                <span>
                  <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: 10 }}
                    disabled={isLoading}
                    onClick={handleRefresh}
                  >
                    <SvgIcon iconName={iconNames.Refresh} />
                  </IconButton>
                </span>
              </Tooltip>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{ marginRight: 10 }}
                onClick={() => setIsFilterDialogOpen(true)}
                disabled={isLoading || downloadSmsReportTaskId}
              >
                Download Report
              </Button>
              {downloadSmsReportTaskId && <div style={{ marginRight: 10 }}>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={getDownloadStatus}
                  disabled={isDownloadStatusLoading}
                >
                  {isDownloadStatusLoading ? <CircularProgress size={24} /> : 'Get Status'}
                </Button>
                |
                <Button onClick={clearDownloadStatus} color="primary" size="small" >
                  Cancel
                </Button>
              </div>}
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => history.push("/sms-type/build")}
              >
                Create SMS Type
              </Button>
            </div>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          {
            isLoading && <Grid container alignItems="center" justifyContent="center" className={classes.loading}>
              <CircularProgress size={48} color="primary" />
            </Grid>
          }
          {
            !isLoading && !smsTypes.length && <Grid container alignItems="center" justifyContent="center" className={classes.loading}>
              <Typography variant="h5" className={classes.notFound}>
                No Types Found
              </Typography>
            </Grid>
          }
          {
            !isLoading && !!smsTypes.length && <Grid container>
              <TableContainer className={classes.tableHeight}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '30%' }}>Name</TableCell>
                      <TableCell style={{ width: '30%' }}>Description</TableCell>
                      <TableCell style={{ width: '15%' }}>Created By</TableCell>
                      <TableCell style={{ width: '25%' }} align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {smsTypes.map((row, index) => {
                      const handleStatusToggleClicked = () => {
                        setSelectedItem(row)
                        setDialogBoxType(DialogBoxType.STATUS)
                        handleDialogBoxOpen({ item: row, type: DialogBoxType.STATUS })
                      }
                      const handleDeleteClicked = () => {
                        setSelectedItem(row)
                        setDialogBoxType(DialogBoxType.DELETE)
                        handleDialogBoxOpen({ item: row, type: DialogBoxType.DELETE })
                      }
                      return (
                        <TableRow key={row?._id}>
                          <TableCell>{row?.name || ""}</TableCell>
                          <TableCell>{row?.description || ""}</TableCell>
                          <TableCell>{row?.createdBy?.name || ""}</TableCell>
                          <TableCell>
                            <Grid item className={classes.actionContainer}>
                              <Grid item style={{ width: '60%' }}>
                                <Switch
                                  checked={row?.status === 'ACTIVE'}
                                  color='primary'
                                  onChange={handleStatusToggleClicked}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <span>{row.status === 'ACTIVE' ? 'Active' : 'Inactive'}</span>
                              </Grid>
                              <IconButton style={{ width: '20%' }} onClick={() => history.push({ pathname: '/sms-type/build', state: row })}>
                                <SvgIcon iconName={iconNames.Edit} className={classes.editIcon} />
                              </IconButton>
                              <IconButton style={{ width: '20%' }} onClick={handleDeleteClicked}>
                                <SvgIcon iconName={iconNames.DeleteForever} className={classes.deleteIcon} />
                              </IconButton>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          }
        </CardContent>
      </Card>
      <SMSReportsFilterDialog
        open={isFilterDialogOpen}
        handleClose={handleFilterDialogClose}
        smsTypes={[{ _id: 'ALL', name: 'All' }, ...smsTypes]}
        setDownloadSmsReportTaskId={setDownloadSmsReportTaskId}
      />
      <DialogBox
        open={isDialogOpen}
        onDone={handleDialogDoneClick}
        onCancel={handleDialogCancelClick}
        {...dialogContent}
      />
    </Grid>
  );
}

export default SMSType;
